import { NgModule } from '@angular/core';
import { KzLabelComponent } from './kz-label.component';

@NgModule({
  imports: [],
  declarations: [
    KzLabelComponent,
  ],
  exports: [
    KzLabelComponent,
  ],
})
export class KzLabelModule {

}
