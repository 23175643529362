import { AuthenticationService, KOZIKAZA_STORAGE_KEY, TokenStorage } from '@adeo/ngx-kozikaza-api';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthService {

  constructor(
    private cookieService: CookieService,
    private readonly authenticationService: AuthenticationService,
    private readonly tokenStorage: TokenStorage,
    @Inject(KOZIKAZA_STORAGE_KEY) private readonly storageKey: string
  ) {
  }

  public getUserId(): string | null {
    return this.cookieService.get(`${this.storageKey}_${environment.COOKIE_USER_ID_NAME}`);
  }

  public setKazaId(kazaId: string) {
    if (kazaId !== undefined) {
      this.tokenStorage.storeKazaId(kazaId);
    }
  }

  public getKazaId(): string | null {
    return this.cookieService.get(`${this.storageKey}_${environment.COOKIE_KAZA_ID_NAME}`);
  }

  public logout() {
    try {
      // check if user isAuthenticated to prevent multiple authenticationService.signOut() calls from AuthInterceptor
      if (this.authenticationService.isAuthenticated) {
        this.authenticationService.signOut();
      }
    } catch (e) {
      console.error(e);
    }
  }
}
