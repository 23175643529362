export interface CommunityDisplayOptions {
  forceServerSide?: boolean;

  forceBrowserSide?: boolean;
}

export const defaultCommunityDisplayOptions: CommunityDisplayOptions = {
  forceServerSide: false,

  forceBrowserSide: false,
};
