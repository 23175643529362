import { Inject, PLATFORM_ID, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthService } from '../../modules/auth/auth.service';
import { AuthenticationService } from '@adeo/ngx-kozikaza-api';

@Injectable()
export class OAuthGuard  {

    constructor(
      private router: Router,
      @Inject(PLATFORM_ID) private platformId: object,
      private readonly localizeRouterService: LocalizeRouterService,
      private readonly authService: AuthService,
      private readonly authenticationService: AuthenticationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isConnected(state);
    }

    canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): boolean {
        return this.isConnected(state);
    }

    private isConnected(state: RouterStateSnapshot): boolean {
        if (!this.authenticationService.isAuthenticated) {
            this.authService.redirectToLoginStep({ queryParams : { bannedAction: true, callbackurl: state.url}});
            return false;
        }
        return this.authenticationService.isAuthenticated;
    }
}
