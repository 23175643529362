import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { KzIconsModule } from "../../../kz-ui/ui/icon/kz-icons.module";
import { KzFormModule } from "../../../kz-ui/ui/form/kz-form.module";
import { KzModalModule } from "../../../kz-ui/ui/modal/kz-modal.module";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import {
  catchError,
  delay,
  filter,
  finalize,
  map,
  scan,
  share,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap
} from "rxjs/operators";
import { BehaviorSubject, Observable, Subscription, throwError, timer } from "rxjs";
import { SecondsToMinutesPipe } from "../../../utils/pipes/seconds-to-minutes.pipe";
import { UtilsModule } from "../../../utils/utils.module";
import { AuthPopinService } from "../auth-popin.service";
import { AuthenticationService, HydraError, UserService } from "@adeo/ngx-kozikaza-api";
import { ToastrService } from "../../../shared/services/utilities/toastr.service";
import { UserStoreService } from "../../../shared/services/state-management/user-store.service";
import { KzIconsRegistryService } from "../../../kz-ui/ui/icon/kz-icons-registry.service";
import { kzIconsMail } from "../../../kz-ui/ui/icon/generated-icons/kzIcons-mail.icon";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { RoutesService } from "../../../shared/services/routes/routes.service";
import { emailValidators, phoneNumberValidators } from "../../../utils/const/profile";
import { uniqueField } from "../../../utils/validators/unique-field.validator";
import { kzIconsArrowleft } from "../../../kz-ui/ui/icon/generated-icons/kzIcons-arrowleft.icon";
import { UserService as LegacyUserService } from "../../../shared/services/features";
import { AuthService } from "../auth.service";
import { PhoneCode } from 'src/app/shared/models/auth/phone-codes';
import { PhoneCodeModule } from 'src/app/shared/components/phone-code/phone-code.module';

@Component({
  selector: 'app-email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'kz-email-check'
  },
  imports: [
    CommonModule,
    KzIconsModule,
    KzFormModule,
    KzModalModule,
    ReactiveFormsModule,
    UtilsModule,
    PhoneCodeModule
  ],
  providers: [
    SecondsToMinutesPipe,
  ]
})
export class EmailCheckComponent implements OnDestroy {

  hasError = false;
  submitted = false;
  buttonState = false;

  mode: 'email' | 'phone' = 'email';
  emailVerificationForm: FormGroup;
  phoneForm: UntypedFormGroup;
  modifyEmailForm: FormGroup;
  private subscriptions: Array<Subscription> = [];

  public codeSelected: PhoneCode;
  public tmpPhoneCode = 33;

  modifyEmailMode = false;
  step: 'code' | 'choose' | 'send' = 'code';

  isInKazaplan$: Observable<boolean> = this.routeService.routeDatas.pipe(
    map((data) => !!data.isKazaplanRoute),
    share(),
  );

  public _timer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public timer$: Observable<number> = this._timer$.asObservable().pipe(
    filter((val) => !!val),
    switchMap(() => timer(0, 1000).pipe(
      scan(acc => --acc, 120),
      takeWhile(x => x >= 0),
      takeUntil(this._timer$.asObservable().pipe(filter((val) => !val))),
    ))
  );

  constructor(
    private authPopinService: AuthPopinService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastrService: ToastrService,
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly legacyUserService: LegacyUserService,
    private readonly userService: UserService,
    public readonly userStoreService: UserStoreService,
    private readonly kzIconsRegistryService: KzIconsRegistryService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routeService: RoutesService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsMail, kzIconsArrowleft]);

    /* Form 1 : Email verification */
    this.emailVerificationForm = this.fb.group({
      code: new FormControl(null, {
        validators: [Validators.required, Validators.pattern("^[0-9]{6}$")],
        // updateOn: 'blur'
      })
    });

    this.phoneForm = this.fb.group({
      phoneNumber: new FormControl(null, [...phoneNumberValidators])
    });

    /* Form 1 : Update form status on paste */
    this.subscriptions.push(
      this.emailVerificationForm.valueChanges.pipe(
        tap((val) => this.emailVerificationForm.markAsDirty()),
      ).subscribe()
    );

    /* Form 2 : Email edit */
    const emailControl = new FormControl('',[...emailValidators], [ uniqueField.bind(this, 'email', null) ]);
    this.modifyEmailForm = this.fb.group({
      email: emailControl,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  initEmailVerificationFormStatus() {
    this.emailVerificationForm.get('code').markAsUntouched();
    this.emailVerificationForm.get('code').markAsPristine();
    this.emailVerificationForm.get('code').setValue('');
    this.cdr.markForCheck();
  }

  initModifyEmailFormStatus() {
    this.modifyEmailForm.get('email').markAsUntouched();
    this.modifyEmailForm.get('email').markAsPristine();
    this.modifyEmailForm.get('email').setValue('');
    this.cdr.markForCheck();
  }

  handleApiError(control: AbstractControl, apiError: string) {
    control.setErrors({apiError});
    control.valueChanges.pipe(
      take(1),
      tap(() => {
        control.setErrors(null);
        control.markAsUntouched();
        control.markAsPristine();
        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  submitAction() {
    this.submitted = true;
    const code = parseInt(this.emailVerificationForm.get('code').value);
    const subscription = this.authenticationService.verifyEmail(code).pipe(
      finalize(() => this.submitted = false),
      catchError((err: HttpErrorResponse) => {
        this.handleApiError(this.emailVerificationForm.get('code'), err.error);
        this.submitted = false;
        this.cdr.markForCheck();
        return throwError(err);
      }),
      tap(() => this.authenticationService.updateMe()),
    ).subscribe()
    this.subscriptions.push(subscription);
  }

  resendCode(type = 'email') {
    this._timer$.next(false);

    let body = null;
    if (type == 'sms') {
      body = {
        transport: 'sms',
        phoneNumber: `${this.codeSelected.dial_code}${this.phoneForm.get('phoneNumber').value}`
      }
    }
    this.submitted = true;
    const subscription = this.authenticationService.newVerificationCode(body).pipe(
      finalize(() => this.submitted = false),
      catchError((err: HttpErrorResponse) => {
        this._timer$.next(true);
        if (type === 'email') {
          this.handleApiError(this.emailVerificationForm.get('code'), (err.error as HydraError)['hydra:description']);
        } else if (type === 'sms') {
          this.handleApiError(this.phoneForm.get('phoneNumber'), (err.error as HydraError)['hydra:description']);
        }
        this.submitted = false;
        this.cdr.markForCheck();
        return throwError(err);
      }),
      tap(() => this._timer$.next(true)),
      tap(() => this.initEmailVerificationFormStatus()),
      tap(() => {
        this.mode = (body) ? 'phone' : 'email';
        this.step = 'code';
      }),
    ).subscribe();
    this.subscriptions.push(subscription);
  }



  submitModifyAction() {
    this.submitted = true;
    const email: string = this.modifyEmailForm.get('email').value as string;
    const subscription = this.legacyUserService.update(this.userStoreService.user['@id'], {email}).pipe(
      take(1),
      finalize(() => {
        this.submitted = false;
        this.cdr.markForCheck();
      }),
      catchError((err: HttpErrorResponse) => {
        this.handleApiError(this.modifyEmailForm.get('email'), err.error);
        this.submitted = false;
        this.cdr.markForCheck();
        return throwError(err);
      }),
      tap(() => {
        this.toastrService.info($localize`:@@emailVerification.emailModified:Email has been modified`);
        this.modifyEmailMode = false;
      }),
    ).subscribe();

    this.subscriptions.push(subscription);
  }

  linkToLogin(prefilledEmail?: string) {
    if (this.authenticationService.isAuthenticated) {
      const queryParams = prefilledEmail ? {email: prefilledEmail} : undefined;
      this.authenticationService.signOut();
      const sub = this.userStoreService.userStore.pipe(
        filter((user) => !user),
        take(1),
        delay(250),
        tap(() => this.authService.redirectToLoginStep({queryParams})),
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

}
