import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { KzIconsRegistryService } from './kz-icons-registry.service';
import { kzIcon } from './generated-icons/kz-icon.model';
import { IconSize } from './icons-options';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'kz-icons',
  template: `<div [innerHTML]="safeHTML"></div>`,
  host: {'[class]': '"kz-icons" + (classes ? " " + classes : "")'},
  styleUrls: ['./kz-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KzIconsComponent implements OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private svgIcon: SVGElement;
  public safeHTML: SafeHtml;

  private _size: IconSize;

  get size(): IconSize {
    return this._size;
  }

  @Input()
  set size(value: IconSize) {
    this._size = value;
  }

  private _name: string;

  @Input({required: true})
  set name(iconName: kzIcon | string) {
    this._name = iconName.replace(/-/g, '_');
    if (this.svgIcon) {
      this.renderer.removeChild(this.element.nativeElement, this.svgIcon);
    }
    const svgData = this.kzIconRegistry.getIcon(this._name);
    this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(svgData);
  }

  @HostBinding('attr.aria-hidden') ariaHidden = true;

  @Input({transform: booleanAttribute}) @HostBinding('class.kz-icons-inline') inline = false;

  @Input({transform: booleanAttribute}) @HostBinding('class.kz-icons-width-auto') widthAuto = false;

  constructor(
    private element: ElementRef,
    private kzIconRegistry: KzIconsRegistryService,
    private _changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.size && this.size !== 'default') {
      this._classes += ` kz-icons-size--${ this.size }`;
    }
    if (this._name) {
      this._classes += ` kz-icons--${ this._name }`;
    }
  }
}
