import {
  Kaza,
  KazaSubResources,
  ProjectType,
  Tag,
  User,
  KazaService as SDKKazaService,
  ProjectTypeService as SDKProjectTypeService,
  ProjectUtilityService as SDKProjectUtilityService,
  TagService as SDKTagService,
  WebServiceOptions,
  HydraCollection,
} from '@adeo/ngx-kozikaza-api';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../oauth/auth.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { activeKazaSubResources, KAZA_CONST, navigationKazaSubResources } from '../../../../utils/const/kaza';
import { UserStoreService } from '../../state-management/user-store.service';

@Injectable()
export class KazaService {
  kazas: any = [];
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private authService: AuthService,
    private readonly sdkKazaService: SDKKazaService,
    private readonly sdkProjectTypeService: SDKProjectTypeService,
    private readonly sdkProjectUtilityService: SDKProjectUtilityService,
    private readonly sdkTagService: SDKTagService,
    private readonly userStoreService: UserStoreService,
  ) {
  }

  /* *****************************************************************************
   *        ACTIONS THAT UPDATES USER STORE
   ******************************************************************************/
  public create(kaza: Kaza): Observable<Kaza> {
    return this.sdkKazaService.postKazas(kaza).pipe(
      switchMap((updatedKaza) => this.sdkKazaService.getKaza(updatedKaza, activeKazaSubResources, {refresh: false})),
      tap((data) => {
        const currentUser = this.userStoreService.user;
        // Update KazaActive + Add to userStoreService.userKazas, if currentUser is kaza.author
        if (currentUser && !!currentUser['@id']) {
          const isAuthor = data.authors.some((author) => author['@id'] === currentUser['@id']);
          if (isAuthor) {
            this.userStoreService.userKazas.push(data);
            this.userStoreService.userActiveKaza = data;
            this.userStoreService.userNbKazas += 1;
          }
        }
      })
    );
  }

  public update(kazaId: string, kaza: Partial<Kaza>, customSubResources?: KazaSubResources): Observable<Kaza> {
    const kazaUpdate = {...kaza, ...(!kaza['@id'] ? {'@id': kazaId} : {})};
    return this.sdkKazaService.putKaza(kazaUpdate).pipe(
      switchMap((updatedKaza) => this.sdkKazaService.getKaza(updatedKaza, customSubResources || activeKazaSubResources,
        {refreshOnlyFirstLevel: true, refresh: false})),
      tap((updatedKaza) => this.refreshUserKazaState(updatedKaza))
    );
  }

  public deleteKaza(kazaId) {
    return this.sdkKazaService.deleteKaza(kazaId).pipe(
      tap(() => {
        if (this.userStoreService.userKazas && this.userStoreService.userKazas.length) {
          this.userStoreService.userKazas = this.userStoreService.userKazas.filter((k) => k['@id'] !== kazaId);
          this.userStoreService.userNbKazas -= 1;
        }
      })
    );
  }

  public get(resourceId: string, subResources?: KazaSubResources, refresh = false): Observable<Kaza> {
    const webServiceOptions: WebServiceOptions = {refreshOnlyFirstLevel: true, refresh};
    return this.sdkKazaService.getKaza(resourceId, subResources, webServiceOptions);
  }

  public getByUser(user: string | User, forceReload: boolean = false): Observable<Array<Kaza>> {
    return (this.userStoreService.userKazas && this.userStoreService.userKazas.length &&
      user === this.authService.getUserId() && !forceReload) ?
      this.userStoreService.userStoreKazas :
      this.sdkKazaService.getUserKazas(user)
        .pipe(
          map((data) => {
            if (user === this.authService.getUserId()) {
              this.userStoreService.userKazas = data['hydra:member'];
            }
            return data['hydra:member'];
          })
        );
  }


  /* *****************************************************************************
   *        SUB RESOURCES METHODS
   ******************************************************************************/
  public getTypes(): Observable<Array<ProjectType>> {
    const params = new HttpParams({ fromObject: { mediaThumbsFilter: 'medium'}} );
    const webServiceOptions: WebServiceOptions = { query: params };
    return this.sdkProjectTypeService.getProjectTypes(null, webServiceOptions).pipe(
      map((data) => data['hydra:member'])
    );
  }

  public getTags(step?: number): Observable<Array<Tag>> {
    let params = new HttpParams({ fromObject: { mediaThumbsFilter: 'medium'}} );
    if (step) {
      params = params.append('registrationStep', `${step}`);
    }
    const webServiceOptions: WebServiceOptions = { query: params };
    return this.sdkTagService.getTags(null, webServiceOptions).pipe(
      map((data) => data['hydra:member'])
    );
  }

  public addTags(resourceId: string, tags: { tags: Array<string | Tag> }): Observable<Kaza> {
    tags.tags = tags.tags.map((tag) => typeof tag === 'string' ? tag : tag['@id']);
    const kazaUpdated: Partial<Kaza> = { '@id' : resourceId, ...tags};
    return this.update(resourceId, kazaUpdated, [...activeKazaSubResources, 'tags']);
  }

  /* *****************************************************************************
   *        KAZA LIST METHODS
   ******************************************************************************/

  public searchKazas(filters: string, args: any): Observable<HydraCollection<Kaza>> {
    const params = this.setParams(args);
    const webServiceOptions: WebServiceOptions = {query: params};
    return this.sdkKazaService.getSearchKazas(navigationKazaSubResources, filters, webServiceOptions);
  }


  public findKazasByBuilder(builder: string, authorId: string): Observable<HydraCollection<Kaza>> {
    return this.searchKazas('?builder=' + builder + '&authors[exclude]=' + authorId +
      '&order[definedAsActive]&order[nbFollowers]&order[createdAt]', {});
  }

  public findKazasByGeoloc(lat: string, lon: string, authorId: string): Observable<HydraCollection<Kaza>> {
    return this.searchKazas('?coordinates[lat]=' + lat + '&coordinates[lon]=' + lon + '&coordinates[distance]=' +
      KAZA_CONST.GEOLOC_DISTANCE +
      '&authors[exclude]=' + authorId + '&order[definedAsActive]&order[nbFollowers]&order[createdAt]', {});
  }


  private refreshUserKazaState(updatedKaza: Kaza) {
    if (this.userStoreService.userActiveKaza && this.userStoreService.userActiveKaza['@id'] === updatedKaza['@id']) {
      this.userStoreService.userActiveKaza = updatedKaza;
    }
    if (this.userStoreService.userKazas && this.userStoreService.userKazas.length) {
      this.userStoreService.userKazas = this.userStoreService.userKazas.map((k) => (k['@id'] === updatedKaza['@id']) ? updatedKaza : k);
    }
  }

  private setParams( args: any ): HttpParams {
    let privacy;
    let order;
    let argsTemp  = {
      privacy: null,
      order: null
    };
    argsTemp = Object.assign(argsTemp, args);
    if (argsTemp.privacy) {
      privacy = argsTemp.privacy;
      delete argsTemp.privacy;
    }
    if (argsTemp.order) {
      order = argsTemp.order;
      delete argsTemp.order;
    }
    let params = new HttpParams({ fromObject: argsTemp });
    if (privacy) {
      privacy.map((p) => params = params.append('privacy[]', p));
    }
    params = (order && order.createdAt) ? params.append('order[createdAt]', order.createdAt) : params;
    return params;
  }
}
