import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { KzIconsRegistryService } from '../../icon/kz-icons-registry.service';
import { kzIconsAlertTriangle } from '../../icon/generated-icons/kzIcons-alert-triangle.icon';

@Component({
  selector: '[kzError]',
  exportAs: 'kzError',
  host: {
    '[class]': '"kz-error"',
  },
  template: `<kz-icons size="sm" name="alert_triangle"></kz-icons><ng-content></ng-content>`,
  styleUrls: ['./kz-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzErrorComponent {
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private kzIconsRegistryService: KzIconsRegistryService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsAlertTriangle]);
  }
}
