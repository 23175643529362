import {Component, EventEmitter, Inject, Input, LOCALE_ID, Output, Renderer2, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from 'environments/environment';
import { UserStoreService } from '../../../../../shared/services/state-management/user-store.service';
import { KzIconsRegistryService } from '../../../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsSearch } from '../../../../../kz-ui/ui/icon/generated-icons/kzIcons-search.icon';
import { kzIconsArrowright } from '../../../../../kz-ui/ui/icon/generated-icons/kzIcons-arrowright.icon';
import { kzIconsCross } from '../../../../../kz-ui/ui/icon/generated-icons/kzIcons-cross.icon';
import { navigationIcons } from '../../../../../kz-ui/ui/icon/groups/kz-icons-group-navigation';
import {kzIconsLoanGranted} from "../../../../../kz-ui/ui/icon/generated-icons/kzIcons-loan-granted.icon";

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html'
})
export class VerticalMenuComponent {
  q: string;
  open: number;
  public isAllowed = true;
  @ViewChild('searchInput', {static: false}) searchInput;
  @Input() user = null;
  @Output() closeLayer: EventEmitter<boolean> = new EventEmitter<boolean>();
  public blogUrl = environment.BLOG_URL;
  public bathroomSlug = 'salle-de-bain';
  public kitchenSlug = 'cuisine';
  public dressingSlug = 'dressing-et-rangements';
  public outdoorDesignSlug = 'amenagement-exterieur';
  public openInspiSubNav: 0 | 1 | 2 | 3 = 0;
  public proDirectoryJob = 'interior-decorator';
  public proDirectoryLink = ['pro'];

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private readonly localizeRouterService: LocalizeRouterService,
    public readonly userStoreService: UserStoreService,
    private kzIconsRegistryService: KzIconsRegistryService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.kzIconsRegistryService.registerIcons([...navigationIcons, kzIconsSearch, kzIconsCross, kzIconsArrowright, kzIconsLoanGranted]);
    this.proDirectoryLink = this.localizeRouterService.translateRoute(['/pro'/*, this.proDirectoryJob*/]) as string[];
  }

  hideResponsiveMenu() {
    this.closeLayer.emit(true);
  }

  onSubmit(): void {
    if (this.q !== '') {
      const param = this.q;
      this.q = '';
      this.router.navigate(this.localizeRouterService.translateRoute(['/kazacafe', 'explore']) as string[], { queryParams: {q: param}});
      this.searchInput.nativeElement.blur();
      this.hideResponsiveMenu();
    }
  }

  showMenu(menu: number): void {
    this.open = ((this.open === menu) ? null : menu);
  }


  handleKeyupEnter(): void {
      this.onSubmit();
  }
}
