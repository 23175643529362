import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { KzModal } from '../../../kz-ui/ui/modal/kz-modal';
import { ConfirmationModalComponent } from './confirmation-modal.component';

@Component({
  selector: 'app-confirmation-modal-fullscreen',
  templateUrl: './confirmation-modal-fullscreen.component.html',
  styleUrls: ['./confirmation-modal-fullscreen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationModalFullscreenComponent extends ConfirmationModalComponent {


  @HostBinding('class') get typeClass(): string {
    return `modal-type--${this.type} confirmation-modal-fullscreen`;
  }

  constructor(
    protected _kzModal: KzModal<any>
  ) {
    super(_kzModal);
  }
}
