import { NgModule } from '@angular/core';
import { KzTooltipComponent } from './kz-tooltip/kz-tooltip.component';
import { KzTooltipDirective } from './kz-tooltip.directive';
import { CommonModule } from '@angular/common';
import { KzAnimationsModule } from '../../animations/kz-animations.module';
import { KzClickOutsideModule } from '../click-outside/kz-click-outside.module';
import { KzPopupModule } from '../popup/kz-popup.module';

@NgModule({
  imports: [
    CommonModule,
    KzPopupModule,
    KzAnimationsModule,
    KzClickOutsideModule,
  ],
  declarations: [
    KzTooltipDirective,
    KzTooltipComponent,
  ],
  exports: [
    KzTooltipDirective,
    KzTooltipComponent,
  ]
})
export class KzTooltipModule {
}
