import { Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';
import { KzModal } from '../../../kz-ui/ui/modal/kz-modal';
import { ConfirmationModalType } from './confirmation-modal.options';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() title: string;
  @Input() content: string | TemplateRef<any>;

  @Input() yesAction = true;
  @Input() noAction = true;
  @Input() cancelAction = true;
  @Input() yesActionLabel: string;
  @Input() noActionLabel: string;
  @Input() cancelActionLabel: string;

  @Input() type: ConfirmationModalType = 'default';

  public contentType: 'string' | 'template' = 'string';
  public contentTemplate: TemplateRef<any>;
  public contentString: string;

  @HostBinding('class') get typeClass(): string {
    return `modal-type--${this.type}`;
  }

  constructor(
    protected _kzModal: KzModal<any>
  ) { }

  ngOnInit() {
    this.contentType = (this.content instanceof TemplateRef) ? 'template' : 'string';
    if (this.contentType === 'template') {
      this.contentTemplate = this.content as TemplateRef<any>;
    } else {
      this.contentString = this.content as string;
    }
  }

  yes() {
    this._kzModal.close(true);
  }
  no() {
    this._kzModal.close(false);
  }
  cancel() {
    this._kzModal.close(null);
  }

}
