import { Component } from '@angular/core';

@Component({
  selector: 'app-lazy',
  template: '<router-outlet></router-outlet>'
})
export class LazyComponent {

  constructor() { }
}
