import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KzFieldInfoComponent } from './kz-field-info.component';
import { KzIconsModule } from '../../icon/kz-icons.module';

@NgModule({
  imports: [
    CommonModule,
    KzIconsModule,
  ],
  declarations: [
    KzFieldInfoComponent,
  ],
  exports: [
    KzFieldInfoComponent,
  ],
})
export class KzFieldInfoModule {

}
