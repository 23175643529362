import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Metas, SeoService } from '../../shared/services/utilities/seo.service';
import { environment } from 'environments/environment';
import { Kaza } from '@adeo/ngx-kozikaza-api';
import { getTranslation } from '../../utils/const/translation';
import { ModalService } from '../../utils/modals/modals.service';

@Injectable()
export class KazaNavigationService {

  private _currentKaza: ReplaySubject<Kaza> = new ReplaySubject<Kaza>(1);

  currentUpdatedKaza: BehaviorSubject<Kaza> = new BehaviorSubject(null);

  constructor(
    private seoService: SeoService,
    private router: Router,
    private readonly _modalService: ModalService,
  ) { }

  public getCurrentKaza(): Observable<Kaza> {
    return this._currentKaza.asObservable();
  }

  public setCurrentKaza(k: Kaza, setMetas = true) {
    this._currentKaza.next(k);
    if (setMetas) {
      this.initMetas(k);
    }
  }

  public initMetas(kaza: Kaza, activatedRoute?: ActivatedRouteSnapshot, initMetasFromRoute = false): Metas {
    const route = (activatedRoute) ? activatedRoute : this.activatedRoute;
    const metas = this.getMetas(kaza, route);
    if (!initMetasFromRoute) {
      this.seoService.setMetas({ ...(route && route.data && route.data.metasOverride ? route.data.metasOverride : []) , ...metas});
    }
    return metas;
  }

  getMetas(kaza: Kaza, activatedRoute?: ActivatedRouteSnapshot): Metas {
    const route = (activatedRoute) ? activatedRoute : this.activatedRoute;
    const titleKey = (kaza && route.data && route.data.metas && route.data.metas.titleKey) ?
      route.data.metas.titleKey : 'meta.kaza.posts-flux.title';
    const title = kaza ? getTranslation(titleKey, {kazaName : kaza.name} ) : null;
    const image = (kaza && kaza.cover && typeof kaza.cover !== 'string' && kaza.cover.thumbs && kaza.cover.thumbs.medium) ?
      kaza.cover.thumbs.medium : `${environment.REDIRECT_URL}/assets/images/header/logo.png`;
    const descriptionKey = (kaza && route.data && route.data.metas && route.data.metas.descriptionKey) ?
      route.data.metas.descriptionKey : null;
    const desc = (descriptionKey) ? getTranslation(descriptionKey) : '' ;
    return {title, description : desc, image};
  }

  getMetasForPopin(kaza: Kaza, titleKey = 'meta.kaza.view.title', descriptionKey?: string): Metas {
    const title = kaza ? getTranslation(titleKey, {kazaName : kaza.name} ) : null;
    const image = (kaza && kaza.cover && typeof kaza.cover !== 'string' && kaza.cover.thumbs && kaza.cover.thumbs.medium) ?
      kaza.cover.thumbs.medium : `${environment.REDIRECT_URL}/assets/images/header/logo.png`;
    const desc = (descriptionKey) ? getTranslation(descriptionKey) : '' ;
    return {title, description : desc, image};
  }

  private get activatedRoute(): ActivatedRouteSnapshot {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }
}
