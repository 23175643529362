import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { CookieService } from "ngx-cookie";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class AffiliateService {
    private _locale: Locale;
    apiUrl = environment.API_BACKEND_ORIGIN
   
    constructor(private http:HttpClient,private cookiesService:CookieService){

    }
    getTrackingLinksInfo(trackingLink:string):Observable<any> {
       return  this.http.get(this.apiUrl+'/affiliate'+trackingLink,{ headers: this.getHeaders() });
    }

    public getHeaders(): HttpHeaders {
        const bearerToken = this.cookiesService.get(environment.ACCES_TOKEN);
         return new HttpHeaders({
          Authorization: `Bearer ${bearerToken}`,
        });
      }
  }