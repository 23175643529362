import { AuthenticationService, convertIRItoID, distinctHydraMember } from '@adeo/ngx-kozikaza-api';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CommunityService } from '../services/community.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RoutesService } from '../services/routes/routes.service';

@Injectable()
export class HomeGuard  {

    constructor(
        private router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly communityService: CommunityService,
        private readonly localizeRouterService: LocalizeRouterService,
        private readonly routesService: RoutesService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const hasAuthOutlet = this.routesService.routeHasOutlet('auth');
        if (route.queryParams.noredirect || hasAuthOutlet) {
            return true;
        }

        return combineLatest([
            this.communityService.isCommunityChanges,
            this.authenticationService.authenticatedUser.pipe(distinctHydraMember())
        ]).pipe(
            take(1),
            switchMap(([isCommunity, user]) => {
                // Check for Community Redirections
                if (user) {
                    if (isCommunity.isCommunity === true) {
                        this.router.navigate(this.localizeRouterService.translateRoute(['kazacafe']) as string[]);
                        return of(false);
                    }
                    this.router.navigate(this.localizeRouterService.translateRoute(
                        ['kaza', convertIRItoID(user.activeKaza['@id']), '3Dplan']) as string[]);
                    return of(false);
                }
                return of(true);
            })
        );
    }
}
