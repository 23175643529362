import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  private readonly language: string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
  ) {
    this.language = locale.replace('-', '_').split('_').pop();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.API_BACKEND_ORIGIN)) {
      return next.handle(request);
    }

    if (!request.headers.has('X-Locale')) {
      request = request.clone({ headers: request.headers.set('X-Locale', `${this.language}`) });
    }
 
    if (!request.headers.has('content-language')) {
      request = request.clone({ headers: request.headers.set('content-language', `${this.language}`) });
    }

    return next.handle(request);
  }
}
