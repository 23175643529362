import { Injectable } from '@angular/core';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
// import { Logger as TsLogger, loggerService } from '@kirakishin/ts-logger';
// import { SkzLoggerContextService } from '@adeo/ngx-kozikaza-api';

@Injectable()
export class LocalizedRouterService {
  // private tslogger: TsLogger;

  constructor(
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly translateStore: TranslateStore,
    private readonly translateService: TranslateService,
    // private skzLoggerContextService: SkzLoggerContextService
  ) {
    // this.tslogger = loggerService.getLogger('LocalizedRouterService', loggersOptions.i18n, skzLoggerContextService.getLogContext());
    // this.tslogger.debug('initializing');
  }

  translatePath(intialPath: string, language: string): Observable<string> {
    const currentLang = this.localizeRouterService.parser.currentLang;
    const srcRoutesTrans = this.translateStore.translations[currentLang].routes;
    const destRoutesTransStore = (this.translateStore.translations[language]) ? this.translateStore.translations[language] : undefined;
    // this.tslogger.debug({language, intialPath, translations: this.translateStore.translations, destRoutesTransStore});
    return ((destRoutesTransStore) ? of(destRoutesTransStore) : this.translateService.getTranslation(language)).pipe(
      map((json) => json.routes),
      filter((destRoutesTrans) => !!destRoutesTrans),
      map((destRoutesTrans) => {
        const returnedPath = intialPath
          .split('/')
          // Remove the first "part" if it's a language (en, fr, ...)
          .filter((path, index) => {
            return index !== 1 || environment.ALLOWED_LANGUAGES.indexOf(path) === -1;
          })
          // Replace the path with the "original" path, the translation key
          .map((path) => {
            for (const pathKey in srcRoutesTrans) {
              if (srcRoutesTrans.hasOwnProperty(pathKey) && srcRoutesTrans[pathKey] === decodeURI(path)) {
                return pathKey;
              }
            }
            return path;
          })
          // Replace the original path/translation key by the translation of the wanted language
          .map((path) => destRoutesTrans.hasOwnProperty(path) ? destRoutesTrans[path] : path)
          .join('/');
        return returnedPath;
      })
    );
  }

  translateCurrentRoute(language: string): Observable<string> {
    return this.translatePath(window.location.pathname, language);
  }

}
