import { UserSubResources } from '@adeo/ngx-kozikaza-api';
import { Validators } from '@angular/forms';
import { MISC_CONST } from './misc';
import { WebNotificationSubResources } from '@adeo/ngx-kozikaza-api/notifications';

export const PROFILE_CONST = {
    USER_TOBETREATED_MIN: 1,
    USER_TOBETREATED_MAX: 19,
    USER_WATCHED_MIN: 20,
    USER_WATCHED_MAX: 39,
    USER_TREATED_MIN: 40,
};

export const regexUUID = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
export const regexR5ID =  /^[a-zA-Z0-9_-]*$/;

export const userSubResources: UserSubResources = ['activeKaza'];
export const currentUserSubResources: UserSubResources = ['activeKaza'];
export const currentUserNotificationsSubResources: WebNotificationSubResources = ['type'];

export const firstNameValidators = [
  Validators.required,
  Validators.pattern(/^[a-zA-ZÀ-ÖØ-öø-ÿ -]{1,100}$/)
];
export const lastNameValidators = [
  Validators.required,
  Validators.pattern(/^[a-zA-ZÀ-ÖØ-öø-ÿ -]{1,100}$/)
];
export const usernameValidators = [
  Validators.required,
  Validators.minLength(MISC_CONST.USER_VALIDATOR_MINLENGTH),
  Validators.maxLength(MISC_CONST.USER_VALIDATOR_MAXLENGTH),
  Validators.pattern(MISC_CONST.USER_VALIDATOR_REGEX)
];
export const emailValidators = [
  Validators.required,
  Validators.maxLength(200),
  Validators.pattern(/^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/)
];
export const phoneNumberValidators = [
    Validators.pattern(/^[0-9]{7,10}$/),
    Validators.maxLength(15)
];
export const urlValidators = [
    Validators.pattern(/^(https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?$/),
    Validators.maxLength(200)
];
