import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutes'
})
export class SecondsToMinutesPipe implements PipeTransform {

  transform(value: number): string {
    const min = Math.floor(value / 60);
    const sec = value % 60;
    return `${min}:${sec >= 10 ? sec : '0' + sec}`;
  }

}
