import { booleanAttribute, Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderSize, LoaderType } from './loader-options';

@Component({
  selector: 'kz-loader',
  template: `<ng-container *ngIf="type == 'default'"><div></div><div></div></ng-container>
  <svg *ngIf="type == 'logo'" width="100%" height="100%" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
    <defs>
      <path id="line" d="M1.651,4.518C1.651,4.518 2.085,1.564 4.605,1.564C7.124,1.564 28.279,1.564 30.146,1.607C32.014,1.651 34.013,5.039 31.187,7.256C30.003,8.185 25.628,11.077 25.628,11.077L32.622,11.033C32.622,11.033 32.709,27.627 32.666,29.93C32.622,32.232 30.233,32.666 30.233,32.666L4.083,32.753C4.083,32.753 1.607,32.145 1.651,30.06C1.694,27.975 1.651,4.518 1.651,4.518"/>
    </defs>
    <use xlink:href="#line" id="v1" class="line"/>
    <use xlink:href="#line" id="v2" class="line" [ngClass]="{'current-color': currentColor}"/>
  </svg>`,
  styleUrls: ['./kz-loader.component.scss'],
  host: {
    '[class.kz-loader]': 'type == "default"',
    '[class.kz-logo-loader]': 'type == "logo"',
    '[class.kz-loader--small]': 'size == "small"',
    '[class.kz-loader--big]': 'size == "big"',
  },
  encapsulation: ViewEncapsulation.None,
})
export class KzLoaderComponent {
  @Input()
  size: LoaderSize = 'medium';

  @Input()
  type: LoaderType = 'default';

  @Input({transform: booleanAttribute})
  currentColor = false;
}
