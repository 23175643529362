<div class="kz-select--country" [ngClass]="{'active': showCodes, 'padding-bottom': (paddingVersion == 1), 'padding-top-bottom': (paddingVersion == 2)}" id="kz-register--mobilephone-country-selection">
  <kz-icons name="arrowdown"></kz-icons>
  <div class="kz-select--country-selected kz-select--country-flag" (click)="showCodes = !showCodes" #actionCTA>
    <span class="iti__flag iti__{{ phoneCode.code | lowercase }}"></span>
    {{ phoneCode.dial_code }}
  </div>
  <ng-container *ngIf="exception">
    <ul class="kz-select--country-list" (kzClickOutside)="showCodes = false" [exceptions]="[exception]">
      <li *ngFor="let code of phoneCodes" class="kz-select--country-flag" (click)="phoneCode = code;showCodes = false; selectedCode.emit({phoneCode: code, firstTime: false}); ">
        <span class="iti__flag iti__{{ code.code | lowercase }}"></span>
        {{code.name}} ({{ code.dial_code }})
      </li>
    </ul>
  </ng-container>
</div>
