import { BaseService } from '../../base.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TransferState } from '@angular/platform-browser';
import { debounceTime, map, tap } from 'rxjs/operators';
import {
  ErrorHandlerStrategy,
  HydraCollection,
  WebServiceOptions,
  WebServiceSubResourceFetchMode,
} from '@adeo/ngx-kozikaza-api';
import {
  WebNotification,
  WebNotificationService,
} from '@adeo/ngx-kozikaza-api/notifications';
import { currentUserNotificationsSubResources } from '../../../../utils/const/profile';
import { UserStoreService } from '../../state-management/user-store.service';

@Injectable()
export class NotificationService extends BaseService {
  onMarkAllAsRead$: Subject<boolean> = new Subject();
  nbWebNotifications$: BehaviorSubject<number> = new BehaviorSubject(null);
  updateSubject$: Subject<any> = new Subject();
  forceUpdateNotifListSubject$: Subject<boolean> = new Subject();

    constructor(
        protected http: HttpClient,
        @Inject(TransferState) transferState: TransferState,
        @Inject(PLATFORM_ID) platformId: any,
        private readonly userStoreService: UserStoreService,
        private readonly webNotificationService: WebNotificationService,
    ) {
        super(http, transferState, platformId);
    }

    get nbNotifications(): Observable<number> {
        return merge(
            this.nbWebNotifications$.asObservable(),
            this.userStoreService.userStoreNbNotifications
        );
    }

    public getNotifications(parameters?: any, subResourceFetchMode = WebServiceSubResourceFetchMode.SYNC):
        Observable<HydraCollection<WebNotification>> {
        const webServiceOptions: WebServiceOptions = {
            subResourceFetchMode,
            errorHandlerStrategy: ErrorHandlerStrategy.SetNull,
            refresh: true,
            refreshOnlyFirstLevel: true,
            query: this.setParamaters(parameters)
        };
        const debounceDuration = webServiceOptions.subResourceFetchMode === WebServiceSubResourceFetchMode.ASYNC ? 30 : 0;
        return this.webNotificationService.getWebNotifications(
            currentUserNotificationsSubResources,
            webServiceOptions
        ).pipe(
            debounceTime(debounceDuration),
            tap((data) => {
                if (data && parameters.read === false) {
                    this.nbWebNotifications$.next( data['hydra:totalItems']);
                }
            })
        );
    }

    public updateNotificationsList(): void {
        this.forceUpdateNotifListSubject$.next(true);
    }

    public update(notification: Partial<WebNotification>): Observable<WebNotification> {
        const notificationUpdated: Partial<WebNotification> = { '@id' : notification['@id'], read: !notification.read, openingType: notification.openingType};

      return this.webNotificationService.putWebNotification(notificationUpdated).pipe(
          map((data: WebNotification) => {
            if (data && notification && notification.read !== null) {
              this.updateSubject$.next({...notification, read: data.read});
              this.nbWebNotifications$.next(
                notification.read ? (this.nbWebNotifications$.getValue() - 1) : (this.nbWebNotifications$.getValue() + 1));
            }
            return data;
          })
        );
    }

    public markAllAsRead(): Observable<any> {
        return this.webNotificationService.markAllNotificationAsRead()
            .pipe(
                tap((data) => {
                    this.onMarkAllAsRead$.next(true);
                    this.nbWebNotifications$.next(0);
                })
            );
    }
}
