import { Component, Inject } from '@angular/core';
import { KzPopup } from '../popup/kz-popup';
import { KzModalComponent } from './kz-modal.component';
import { DOCUMENT } from '@angular/common';
import { KzIconsRegistryService } from '../icon/kz-icons-registry.service';

@Component({
  selector: 'kz-modal-popup',
  templateUrl: './kz-modal.component.html',
  styleUrls: ['./kz-modal.component.scss'],
})
export class KzModalPopupComponent extends KzModalComponent {

  constructor(
    @Inject(DOCUMENT) protected _document: Document,
    protected kzIconsRegistryService: KzIconsRegistryService,
    private _popUp: KzPopup<KzModalPopupComponent>,
  ) {
    super(_document, kzIconsRegistryService);
  }

  close() {
    this._popUp.close();
  }
}
