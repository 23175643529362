import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { makeStateKey, TransferState} from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { tap } from 'rxjs/operators';

@Injectable()
export class BaseService {

  protected apiEndpoint: string = environment.API_BACKEND_ORIGIN;

  constructor(
    protected http: HttpClient,
    @Inject(TransferState) protected transferState: TransferState,
    @Inject(PLATFORM_ID) protected platformId: any
  ) {}

  protected doGet<T>(endpoint: string, params?: HttpParams, headers?: HttpHeaders, serializedSubRessourcesMasterKey = null):
      Observable<any> {
    const KEY_TMP = ((params) ? endpoint + params.toString() : endpoint);

    const KEY = makeStateKey<any>(KEY_TMP);

    if (this.transferState.hasKey(KEY)) {
      const data = this.transferState.get<any>(KEY, null);
      if (!serializedSubRessourcesMasterKey && isPlatformBrowser(this.platformId)) {
        this.transferState.remove(KEY);
      }
      return of(data);
    } else {
      return this.http.get<T>(`${this.apiEndpoint}${endpoint}`, {
        params: this.getDefaultParams(params),
        headers: this.getDefaultHeaders(headers)
      }).pipe(tap((data) =>  {
          if (isPlatformServer(this.platformId)) {
            const tmpData = JSON.parse(JSON.stringify(data));
            this.transferState.set(KEY, tmpData);
          }
        }));
    }
  }

  protected cleanTransfertState(masterKey: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // Clean all transfertState
    Object.keys(JSON.parse(this.transferState.toJson())).map((k) => this.transferState.remove(makeStateKey<any>(k)));
  }

  protected doPost<T>(endpoint: string, body: any, params?: HttpParams, headers?: HttpHeaders): Observable<any> {
    return this.http.post<T>(`${this.apiEndpoint}${endpoint}`, body, {
      params: this.getDefaultParams(params),
      headers: this.getDefaultHeaders(headers)
    });
  }

  protected doPut<T>(endpoint: string, body: any, params?: HttpParams, headers?: HttpHeaders): Observable<any> {
    return this.http.put<T>(`${this.apiEndpoint}${endpoint}`, body, {
      params: this.getDefaultParams(params),
      headers: this.getDefaultHeaders(headers)
    });
  }

  protected doDelete<T>(endpoint: string, params?: HttpParams, headers?: HttpHeaders): Observable<any> {
    return this.http.delete<T>(`${this.apiEndpoint}${endpoint}`, {
      params: this.getDefaultParams(params),
      headers: this.getDefaultHeaders(headers)
    });
  }

  private getDefaultParams(params?: HttpParams) {
    if (params == null) {
      return new HttpParams();
    }
    return params;
  }

  protected getDefaultHeaders(headers?: HttpHeaders) {
    if (headers == null) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'X-KoziKaza-API-Call': 'true'
      });
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  }

  setParamaters(args: any): HttpParams {
    let params = new HttpParams();

    Object.keys(args).forEach((key) => {
      if (typeof args[key] === 'object') {
        args[key].map((p) => params = params.append(key + '[]', p));
      } else {
        params = params.append(key, args[key]);
      }
    });

    return params;
  }
}
