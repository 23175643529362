import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class I18nService {
  constructor(
    @Inject(LOCALE_ID) private readonly language: string,
    @Optional() @Inject('DEFAULT_COUNTRY') private readonly country
  ) {
  }

  getLanguages(): Observable<Array<string>> {
    return of(environment.ALLOWED_LANGUAGES);
  }

  getLanguageData(languageDataMap: {[language: string]: any}): any {
    if (languageDataMap.hasOwnProperty(this.language)) {
      return languageDataMap[this.language];
    }

    if (languageDataMap.hasOwnProperty('default')) {
      return languageDataMap.default;
    }

    return null;
  }

  getCountryData(countryDataMap: {[country: string]: any}): any {
    if (countryDataMap.hasOwnProperty(this.country)) {
      return countryDataMap[this.country];
    }

    if (countryDataMap.hasOwnProperty('default')) {
      return countryDataMap.default;
    }

    return null;
  }
}
