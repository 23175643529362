import { Pipe, PipeTransform } from '@angular/core';
import {
  ImageResizePresets, PresetBgLanding,
  PresetGalleryMedia,
  PresetGenericWidth,
  PresetHpBanner,
  PresetHpVisu,
  PresetInspiIdeas,
  PresetInspiPlans,
  PresetKazabookCover, PresetLandingBathroomExample, PresetLandingExample,
  PresetLandingInspi, PresetLandingStep,
  PresetProBanner,
  PresetProBannerLanding,
  PresetProBgLanding,
  PresetProCarousel, PresetProItem,
  PresetProService,
  PresetProServicePw,
  PresetProStyleFilter,
  PresetUserAvatar
} from './image-resize.service';

@Pipe({
  name: 'imgPreset'
})
export class ImgPresetPipe implements PipeTransform {

  transform(preset: ImageResizePresets, size?: any): unknown {
    switch (preset) {
      case 'hp-banner':
        return new PresetHpBanner(size);
        break;
      case 'hp-visu':
        return new PresetHpVisu(size);
        break;
      case 'user-avatar':
        return new PresetUserAvatar(size);
        break;
      case 'pro-service':
        return new PresetProService(size);
        break;
      case 'pro-service-pw':
        return new PresetProServicePw(size);
        break;
      case 'pro-carousel':
        return new PresetProCarousel(size);
        break;
      case 'pro-banner':
        return new PresetProBanner(size);
        break;
      case 'pro-item':
        return new PresetProItem(size);
        break;
      case 'pro-banner-landing':
        return new PresetProBannerLanding(size);
        break;
      case 'pro-bg-landing':
        return new PresetProBgLanding(size);
        break;
      case 'pro-style-filter':
        return new PresetProStyleFilter();
        break;
      case 'gallery-media':
        return new PresetGalleryMedia(size);
        break;
      case 'kazabook-cover':
        return new PresetKazabookCover(size);
        break;
      case 'inspi-plans':
        return new PresetInspiPlans(size);
        break;
      case 'inspi-ideas':
        return new PresetInspiIdeas(size);
        break;
      case 'landing-inspi':
        return new PresetLandingInspi(size);
        break;
      case 'bg-landing':
        return new PresetBgLanding(size);
        break;
      case 'landing-step':
        return new PresetLandingStep(size);
        break;
      case 'landing-example':
        return new PresetLandingExample(size);
        break;
      case 'landing-bathroom-example':
        return new PresetLandingBathroomExample(size);
        break;
      default:
        return new PresetGenericWidth(preset);
        break;
    }
    return null;
  }

}
