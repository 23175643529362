import { NgModule } from '@angular/core';
import { KzCheckboxComponent } from './kz-checkbox.component';

@NgModule({
  imports: [],
  declarations: [
    KzCheckboxComponent,
  ],
  exports: [
    KzCheckboxComponent,
  ],
})
export class KzCheckBoxModule {

}
