import { animate, AnimationTriggerMetadata, group, query, state, style, transition, trigger } from '@angular/animations';

export const modalEntranceAnimation: AnimationTriggerMetadata =
  trigger('modalEntranceAnimation', [
    transition(':enter', [
      group([
        query(':self', [
          style({opacity: 0}),
          animate('{{overlayTime}} {{overlayDelay}}', style({ opacity: 1 }))
        ]),
        query('.child-animate', [
          style({opacity: 0, transform: 'scale(0)'}),
          animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'scale(1)' }))
        ])
      ])
    ], {params: {overlayTime: '150ms', overlayDelay : '0ms', time: '200ms', delay: '50ms'}}),
    transition(':leave', [
      group([
        query(':self', [
          animate('{{overlayTimeLeave}} {{overlayDelayLeave}}', style({ opacity: 0 }))
        ]),
        query('.child-animate', [
          animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'scale(0)' }))
        ])
      ])
    ], {params: {overlayTimeLeave: '500ms', overlayDelayLeave : '150ms', timeLeave: '500ms', delayLeave: '0ms'}})
  ]);
