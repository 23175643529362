import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'planLink'
})
export class PlanLinkPipe implements PipeTransform {

  transform(value: string, args?: any): Array<string> | string {
    if (!value) {
      return value;
    }
    const planPath = value.replace('/plans/', '');
    return (args != null) ? ['/kazaplan/view/', planPath, args] : ['/kazaplan/view/', planPath];
  }

}
