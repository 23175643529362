import { KazaSubResources } from '@adeo/ngx-kozikaza-api';

export const KAZA_CONST = {
    GEOLOC_DISTANCE: '50km',
    KAZATYPE_MAPPING: {
        '/project_types/b38b6c94-c113-4fcc-8d35-0c6d161648df': {
            id: 1,
            priority: 0
        },
        '/project_types/8e2b3355-69d6-416d-821b-6cf4c67fb090': {
            id: 2,
            priority: 1
        },
        '/project_types/31d17cf6-9f16-40d1-8e7b-05d9974cfb5c': {
            id: 3,
            priority: 1
        },
        '/project_types/77b69491-6696-42d6-a068-f688d68923c3': {
            id: 4,
            priority: 2
        },
        '/project_types/cde7b03f-ddd1-4cb1-a45c-9d9f6c0870e3': {
            id: 5,
            priority: 1
        },
        '/project_types/6bac1cb7-a797-40ab-9f42-1e80f82164e6': {
            id: 6,
            priority: 0
        }
    },
    KAZA_COVER: {
        MIN_WIDTH: 700,
        MIN_HEIGHT: 370
    },
    KAZATYPE_NO_WORK: '/project_types/6bac1cb7-a797-40ab-9f42-1e80f82164e6',
    OLGA_KAZA: '/kazas/1d703323-b5df-4d77-b12d-9064ca062bdd',
    OLGA_USER: '/users/571770e0-cba2-470d-b19c-9623fca51a0c',
};

export const activeKazaSubResources: KazaSubResources = ['authors', 'builder', 'types', 'tags'];
export const simpleMiniCoverKazaSubResources: KazaSubResources = ['authors', 'types'];
export const navigationKazaSubResources: KazaSubResources =
    ['authors', 'kitchenInstaller', 'styleTags', 'builder', 'types', 'tags', 'utilities'];
