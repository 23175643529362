import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { StickerSize, StickerContext } from '../sticker-options';

@Component({
  selector: 'kz-sticker',
  host: {
    '[class]': '"kz-sticker" + (classes ? " " + classes : "")',
  },
  template: `<div class="kz-sticker-container"><ng-content select="[firstLine]"></ng-content><ng-content></ng-content></div>`,
  styleUrls: ['./kz-sticker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzStickerComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _stickerContext: StickerContext = 'default';

  get stickerContext(): StickerContext {
    return this._stickerContext;
  }

  @Input()
  set stickerContext(value: StickerContext) {
    this._stickerContext = value;
  }

  private _stickerSize: StickerSize = 'default';

  get stickerSize(): StickerSize {
    return this._stickerSize;
  }

  @Input()
  set stickerSize(value: StickerSize) {
    this._stickerSize = value;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.stickerContext && this.stickerContext !== 'default') {
      this._classes += ` kz-sticker-context--${ this.stickerContext }`;
    }

    if (this.stickerSize && this.stickerSize !== 'default') {
      this._classes += ` kz-sticker-size--${ this.stickerSize }`;
    }
  }
}
