import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaQueriesService {
  private mobileMediaQuery = '(max-width: 1199px)';
  private desktopMediaQuery = '(min-width: 1200px)';

  constructor(
    public breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  public isMobile(): Observable<boolean> {
    return this.breakpointObserver
      .observe([this.mobileMediaQuery])
      .pipe(
        map((state) => state.matches)
      );
  }

  public isDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe([this.desktopMediaQuery])
      .pipe(
        map((state) => state.matches)
      );
  }


  public isLesserThan(breakPoint: number): Observable<boolean> {
    return this.breakpointObserver
      .observe([`(max-width: ${breakPoint}px)`])
      .pipe(
        map((state) => state.matches)
      );
  }

  public state(mediaQuery: string): Observable<BreakpointState> {
    return this.breakpointObserver
      .observe([(mediaQuery) ? mediaQuery : this.mobileMediaQuery]);
  }

  public detectSafari(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const ua = window.navigator.userAgent;
      return ua.toLowerCase().indexOf('safari/') > -1;
    }
    return false;
  }

  public detectIE(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const ua = window.navigator.userAgent;

      // Test values; Uncomment to check result …

      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
      // Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return !!parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return !!parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      // const edge = ua.indexOf('Edge/');
      // if (edge > 0) {
      //   // Edge (IE 12+) => return version number
      //   return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      // }
    }
    return false;
  }

}
