import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneCodeComponent } from './phone-code.component';
import { UtilsModule } from '../../../utils/utils.module';
import { KzClickOutsideModule } from '../../../kz-ui/ui/click-outside/kz-click-outside.module';
import { KzIconsModule } from '../../../kz-ui/ui/icon/kz-icons.module';



@NgModule({
  declarations: [
    PhoneCodeComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    KzClickOutsideModule,
    KzIconsModule,
  ],
  exports: [
    PhoneCodeComponent
  ]
})
export class PhoneCodeModule { }
