import {
  AuthenticationService,
  Kaza,
  KazaService,
  TokenStorage,
  User,
  UserService,
  AccountType,
} from '@adeo/ngx-kozikaza-api';
import { Inject, Injectable, Optional } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { debug } from '../../utils/misc/app-rxjs.helpers';
import { UserStoreService } from './state-management/user-store.service';
import { CountryStaticService } from './utilities/country-static.service';
import { Country } from '@adeo/ngx-kozikaza-api/misc/country';

@Injectable()
export class CommunityService {

  private countries$: BehaviorSubject<Array<Country>> = new BehaviorSubject(undefined);
  private isCommunityChanges$: ReplaySubject<CommunityState> = new ReplaySubject(1);

  constructor(
    private readonly countryService: CountryStaticService,
    private readonly authenticationService: AuthenticationService,
    private readonly kazaService: KazaService,
    private readonly userService: UserService,
    private readonly userStoreService: UserStoreService,
    private readonly tokenStorage: TokenStorage,
    @Optional() @Inject('DEFAULT_COUNTRY') private readonly _defaultCountry,
    private readonly transferState: TransferState
  ) {
    this.transferState.set(makeStateKey('DEFAULT_COUNTRY'), this._defaultCountry);
    if (!environment.ENABLE_I18N) {
      this.isCommunityChanges$.next({isCommunity: true, communityStateOrigin: CommunityStateOrigin.BY_CONFIG} as CommunityState);
    } else {
      combineLatest(
        [
          this.userStoreService.userStoreActiveKaza.pipe(
            filterNoActiveKazaOrKazaWithoutCountry<Kaza>(),
            distinctUntilChanged((prev, curr) =>
              (prev && curr) && (prev['@id'] === curr['@id']) && (prev.country === curr.country)),
          ),
          this.countryService.getCountries().pipe(
            map((hydraCollection) => hydraCollection['hydra:member']),
            tap((countries) => this.countries$.next(countries))
          )
        ]
      ).pipe(
        map(([activeKaza, countries]) => {
          if (!activeKaza) {
            return !this.getDefaultCountry() ?
              {isCommunity: true, communityStateOrigin: CommunityStateOrigin.BY_CONNECTION_COUNTRY} :
              {isCommunity: countries.findIndex((country) => country.iso === this.getDefaultCountry()) >= 0,
                communityStateOrigin: CommunityStateOrigin.BY_CONNECTION_COUNTRY} as CommunityState;
          } else if (this.userStoreService.isSchool) {
            return {isCommunity: false, communityStateOrigin: CommunityStateOrigin.BY_SCHOOL} as CommunityState;
          }
          return {isCommunity: !!countries.filter((country) => country.iso === activeKaza.country).pop(),
            communityStateOrigin: CommunityStateOrigin.BY_KAZA_COUNTRY} as CommunityState;
        }),
        tap((cummunityChange) => this.isCommunityChanges$.next(cummunityChange)),
        debug('isCommunityChanges')
      ).subscribe();
    }
  }

  get countries(): Observable<Array<Country>> {
    return this.countries$.asObservable();
  }

  get defaultCountry(): string {
    return this._defaultCountry;
  }

  get isCommunityChanges(): Observable<CommunityState> {
    return this.isCommunityChanges$.asObservable();
  }

  public currentUserIsCommunity(user: User, kaza: Kaza): boolean {
    const isCommunity = !!this.countries$.getValue().filter((country) => country.iso === kaza.country).pop()
      && user.accountType !== AccountType.School;
    return isCommunity;
  }

  private getDefaultCountry(): string {
    if (this._defaultCountry) {
      this.transferState.set(makeStateKey('DEFAULT_COUNTRY'), this._defaultCountry);
      return this._defaultCountry;
    } else if (this.transferState.hasKey(makeStateKey('DEFAULT_COUNTRY'))) {
      return this.transferState.get(makeStateKey('DEFAULT_COUNTRY'), undefined);
    }
    return undefined;
  }
}
export function filterNoActiveKazaOrKazaWithoutCountry<T>() {
  return filter((kaza: Kaza) => kaza !== undefined && (!kaza || !!kaza.country));
}

export interface CommunityState {
  isCommunity: boolean;
  communityStateOrigin: CommunityStateOrigin;
  country?: string;
}

export enum CommunityStateOrigin {
  BY_KAZA_COUNTRY = 'byKazaCountry',
  BY_CONNECTION_COUNTRY = 'byConnectionCountry',
  BY_CONFIG = 'byConfig',
  BY_PRO = 'byPro',
  BY_SCHOOL = 'bySchool',
}
