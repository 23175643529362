import { NgModule } from '@angular/core';
import { KzBravoComponent } from './kz-bravo.component';
import { KzIconsModule } from '../icon/kz-icons.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    KzIconsModule,
  ],
  declarations: [
    KzBravoComponent,
  ],
  exports: [
    KzBravoComponent,
  ],
})
export class KzBravoModule {

}
