import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: '[kzLabel]',
  exportAs: 'kzLabel',
  host: {
    '[class]': '"kz-label" + (classes ? " " + classes : "")',
  },
  template: `<ng-content></ng-content>`,
  styleUrls: ['./kz-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KzLabelComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _isRequired: boolean;

  get isRequired(): boolean {
    return this._isRequired;
  }

  @Input({transform: booleanAttribute})
  set isRequired(value: boolean) {
    this._isRequired = value;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.isRequired) {
      this._classes += ` kz-label--required`;
    }
  }
}
