import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { KzIconsRegistryService } from '../icon/kz-icons-registry.service';
import { kzIconsValidCheck } from '../icon/generated-icons/kzIcons-valid-check.icon';
import { kzIconsStarOutline } from '../icon/generated-icons/kzIcons-star-outline.icon';
import { BravoContext, BravoType } from './bravo-options';

@Component({
  selector: 'kz-bravo',
  host: {
    '[class]': '"kz-bravo" + (classes ? " " + classes : "")',
  },
  templateUrl: './kz-bravo.component.html',
  styleUrls: ['./kz-bravo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KzBravoComponent implements OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  @Input()
  context: BravoContext = 'default';

  @Input()
  type: BravoType = 'kudo';

  @Input()
  count: number = 0;

  @Input()
  active: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  submitted: boolean = false;

  constructor(
    private kzIconsRegistryService: KzIconsRegistryService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsValidCheck, kzIconsStarOutline]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
  }

  private _updateClasses() {
    this._classes = '';
    if (this.context && this.context !== 'default') {
      this._classes += ` kz-bravo--${ this.context }`;
    }

    if (this.type == 'follow') {
      this._classes += ` kz-bravo--follow`;
    }
    if (this.active) {
      this._classes += ` kz-bravo--active`;
    }
    if (this.disabled) {
      this._classes += ` kz-bravo--disabled`;
    }
    if (this.submitted) {
      this._classes += ` kz-bravo--loading`;
    }
  }
}
