import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureSourceComponent } from './picture-source/picture-source.component';
import { ImageResizeService } from './image-resize.service';
import { KzLoaderModule } from '../../../kz-ui/ui/loader/kz-loader.module';
import { ImgPresetPipe } from './img-preset.pipe';

@NgModule({
  declarations: [
    PictureSourceComponent,
    ImgPresetPipe,
  ],
  imports: [
    CommonModule,
    KzLoaderModule,
  ],
  providers: [
    ImageResizeService
  ],
  exports: [
    PictureSourceComponent,
    ImgPresetPipe,
  ]
})
export class ImgResizeModule { }
