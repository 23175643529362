export const GoodDealsArea = [
  '/areas/f01119ae-2e58-4e52-ae75-47567059fbb2',
  '/areas/c714fbd5-37a2-4632-ba57-974637c30e8c',
  '/areas/ed5700a3-d30b-447a-bed1-b3eba679f57b',
  '/areas/c860637c-b421-45d3-b305-e1bed118a4fe',
];

export const GoodDealsMenuArea = [
  '/areas/124a5c61-a812-4fd9-aaab-2046f30ef499',
  '/areas/cf9a92dc-aa52-4bb8-a8a0-58577f8c64c6',
  '/areas/0dda741e-46dc-4159-84e0-6af747ab7edf',
  '/areas/3592c500-dc96-4eca-886b-35aaf440b238',
];

export const ServicesMenuArea = [
  '/areas/bb214a10-693a-4b6b-8fe4-5f6f7f8eff6f',
  '/areas/6c1fe1be-66bd-49c9-ba5d-b51a05d191b6',
  '/areas/cbf62f31-2ea8-4892-a24c-297df8644654',
];

export const ModalePlanArea = [
  '/areas/bc697d43-3d4e-481d-838f-3303ad13851c',
];

export const KazaPlan3DArea = [
  '/areas/98f94355-baa5-4507-9bac-aa41c3a4cfc2'
];
