<div class="kz-good-deal-lite" *ngIf="deal && dealArea">
    <a [href]="dealArea?.tinyUrl" rel="nofollow{{!!deal?.sponsored ? ' sponsored' : ''}}"
       [appGtmTrack]="parentMenu + '_card' " [trackAdId]="deal.trackingSlugName" [trackPartnerId]="brand.trackingSlugName" target="_blank">
        <div class="kz-good-deal-lite-partner">
            <app-picture-source [imgSrc]="brand.logo.contentUrl"
                                bucket="kozikaza-affiliate-files"
                                [presets]="'w40' | imgPreset"
                                alt=""
                                class="kz-good-deal-lite-partner--logo"
                                width="40" height="40"
                                imgFitHeight="false"
                                preloadImages="false" handleX2="true"
            ></app-picture-source>
            <div class="kz-good-deal-lite-partner--label">{{brand.name}}</div>
        </div>
        <div class="kz-good-deal-lite-offer">
            <div>{{deal.name}}</div>
            <app-date-detail [startDate]="deal.startDate" [endDate]="deal.endDate" ></app-date-detail>
        </div>
    </a>
</div>
