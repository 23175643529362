import { NgModule } from '@angular/core';
import { KzCheckboxToggleComponent } from './kz-checkbox-toggle/kz-checkbox-toggle.component';
import { KzAnimationsModule } from '../../../animations/kz-animations.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    KzAnimationsModule,
  ],
  declarations: [
    KzCheckboxToggleComponent,
  ],
  exports: [
    KzCheckboxToggleComponent,
  ],
})
export class KzCheckboxToggleModule {

}
