import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../../shared/services';

@Pipe({name: 'languagedata'})
export class LanguageDataPipe implements PipeTransform {
    constructor(
        private readonly i18nService: I18nService
    ) {
    }

    transform(value: any, ...args: any[]): any {
        if (typeof value !== 'object') {
            return value;
        }
        return this.i18nService.getLanguageData(value);
    }
}
