import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { KzCheckboxComponent } from '../checkbox/kz-checkbox.component';
import { KzLabelComponent } from '../label/kz-label.component';
import { KzRadioComponent } from '../radio/kz-radio.component';
import { KzTextboxComponent } from '../textbox/kz-textbox.component';
import { KzCheckboxToggleComponent } from '../checkbox-toggle/kz-checkbox-toggle/kz-checkbox-toggle.component';
import { KzSelectComponent } from '../select/kz-select.component';

@Component({
  selector: 'kz-field',
  template: `
    <div class="kz-field-flex--row">
      <ng-content select="[kzCheckBox]"></ng-content>
      <ng-content select="[kzRadio]"></ng-content>
      <ng-content select="[kzTag]"></ng-content>
      <ng-content select="kz-checkbox-toggle"></ng-content>
      <ng-content select="[kzLabel]"></ng-content>
    </div>
    <ng-content></ng-content>
    <ng-content select="ng-select"></ng-content>
    <div *ngIf="container" [ngClass]="{'kz-field-container': container }">
      <ng-content select=".kz-field-prefix"></ng-content>
      <ng-content select="app-phone-code"></ng-content>
      <div class="kz-field-wrapper"><ng-content select="[kzTextBox]"></ng-content><ng-content select="[kzSelect]"></ng-content></div>
      <ng-content select=".kz-field-suffix"></ng-content>
    </div>
    <ng-content select=".kz-field-info"></ng-content>
    <div class="kz-infos-msg-container"><ng-content select="[kzFieldInfo]"></ng-content></div>
    <div class="kz-error-msg-container"><ng-content select="[kzError]"></ng-content></div>
    <ng-content select="[kzSelectList]"></ng-content>`,
  styleUrls: ['./kz-field.component.scss', './kz-select.scss'],
  host: {
    '[class]': '"kz-field" + (classes ? " " + classes : "")',
  },
  encapsulation: ViewEncapsulation.None,
})
export class KzFieldComponent implements OnInit, OnChanges, AfterViewInit {

  public container = true;

  /*
  * Textbox attributes
  * */

  private _classes: string;

  @ContentChild(KzLabelComponent)
  private _label: KzLabelComponent;

  @ContentChild(KzRadioComponent)
  private _radio: KzRadioComponent;

  @ContentChild(KzCheckboxComponent)
  private _checkbox: KzCheckboxComponent;

  @ContentChild(KzCheckboxToggleComponent)
  private _checkboxToggle: KzCheckboxToggleComponent;

  // @ContentChild(NgSelectComponent)
  // private _selectbox: NgSelectComponent;

  @ContentChild(KzTextboxComponent)
  private _input: KzTextboxComponent;

  @ContentChild(KzSelectComponent)
  private _select: KzSelectComponent;


  get classes(): string {
    return this._classes;
  }

  private _inline = false;

  get inline(): boolean {
    return this._inline;
  }

  @Input({transform: booleanAttribute})
  set inline(value: boolean) {
    this._inline = value;
  }

  private _hasError = false;

  get hasError(): boolean {
    return this._hasError;
  }

  @HostBinding('class.kz-field-error')
  @Input({transform: booleanAttribute})
  set hasError(value: boolean) {
    this._hasError = value;
  }

  private _absErrorMsg = false;

  get absErrorMsg(): boolean {
    return this._absErrorMsg;
  }

  @HostBinding('class.kz-field-abs-error-msg')
  @Input({transform: booleanAttribute})
  set absErrorMsg(value: boolean) {
    this._absErrorMsg = value;
  }

  private _isDisabled = false;

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  @HostBinding('class.kz-field-disabled')
  @Input({transform: booleanAttribute})
  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.container = !!this._input || !!this._select;
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.inline) {
      this._classes += ' kz-field-inline';
    }
    if (this._isDisabled) {
      this._classes += ' kz-field-disabled';
    }

  }
}
