<div class="kz-header-search" [formGroup]="searchForm" #f="ngForm">
  <input type="search" i18n-placeholder="@@navbar.search" placeholder="Search" autocomplete="off" class="kz-header-search-input" (keyup.enter)="search()" formControlName="keywordSuggest" #inputField />
  <button type="button"
          class="kz-header-search-button"
          (click)="search()"
          [disabled]="submitted"
          i18n-aria-label="@@features.header.search.inputPlaceholder"
          aria-label="Search"><span *ngIf="submitted" class="kz-button--loading disabled"></span><kz-icons *ngIf="!submitted" [name]="'search'"></kz-icons></button>
  <div class="kz-header-search-suggestions" [style.display]="hasSuggestion && displaySuggestion ? 'block' : 'none'" (kzClickOutside)="displaySuggestion = false">
    <div class="kz-header-search-suggestions-box">
      <p class="kz-header-search-suggestions-title" i18n="@@features.header.search.memberSuggest">Member suggestions</p>
      <ul>
        <li *ngFor="let userSuggested of suggestions; let i = index; trackBy: trackById;">
          <span class="kz-header-search-suggestions-link" (click)="close(userSuggested.kaza)">{{ userSuggested.username }}</span>
        </li>
      </ul>
      <!--<p class="kz-header-search-suggestions-title" i18n="@@features.header.search.featureSuggest">Suggestion de fonctionnalités</p>
      <ul>
        <li><a href="###"><span class="kz-header-search-suggestions-category" i18n="@@features.header.search.3dPlan">plan 3D</span> <ng-container i18n="@@features.header.search.createPlan">Créer un plan</ng-container></a></li>
        <li><a href="###"><span class="kz-header-search-suggestions-category" i18n="@@features.header.search.3dPlan">plan 3D</span> <ng-container i18n="@@features.header.search.recoverPlan">Retrouvez mes plans</ng-container></a></li>
        <li><a href="###"><span class="kz-header-search-suggestions-category" i18n="@@features.header.search.3dPlan">plan 3D</span> <ng-container i18n="@@features.header.search.helpPlan">Aide sur le plan</ng-container></a></li>
      </ul>-->
    </div>
  </div>
</div>
