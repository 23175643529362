import { animate, AnimationTriggerMetadata, group, query, style, transition, trigger } from '@angular/animations';

export const modalEntranceAnimation: AnimationTriggerMetadata =
  trigger('modalEntranceAnimation', [
    transition(':enter', [
      group([
        query(':self', [
          style({opacity: 0}),
          animate('{{overlayTime}} {{overlayDelay}}', style({ opacity: 1 }))
        ]),
        query('.child-animate', [
          style({opacity: 0, transform: 'scale(0)'}),
          animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'scale(1)' }))
        ])
      ])
    ], {params: {overlayTime: '300ms', overlayDelay : '0ms', time: '500ms', delay: '150ms'}}),
    transition(':leave', [
      group([
        query(':self', [
          animate('{{overlayTimeLeave}} {{overlayDelayLeave}}', style({ opacity: 0 }))
        ]),
        query('.child-animate', [
          animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'scale(0)' }))
        ])
      ])
    ], {params: {overlayTimeLeave: '500ms', overlayDelayLeave : '150ms', timeLeave: '500ms', delayLeave: '0ms'}})
  ]);

export const entranceAnimation: AnimationTriggerMetadata =
  trigger('entranceAnimation', [
    transition('* => active', [
      style({opacity: 0, transform: 'scale(0.5)'}),
      animate('{{time}} {{delay}} ease-in-out', style({ opacity: 1, transform: 'scale(1)' }))
    ], {params: {time: '300ms', delay: '0ms'}}),
    transition('active => *', [
      animate('{{timeLeave}} {{delayLeave}} ease-in-out', style({ opacity: 0, transform: 'scale(0.5)' }))
    ], {params: {timeLeave: '300ms', delayLeave: '0ms'}})
  ]);

export const bounceEntranceAnimation: AnimationTriggerMetadata =
    trigger('bounceEntranceAnimation', [
        transition('* => active', [
            style({opacity: 0, transform: 'scale(0.5)'}),
            animate('{{time}} {{delay}} cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ opacity: 1, transform: 'scale(1)' }))
        ], {params: {time: '300ms', delay: '0ms'}}),
        transition('active => *', [
            animate('{{timeLeave}} {{delayLeave}} cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ opacity: 0, transform: 'scale(0.5)' }))
        ], {params: {timeLeave: '300ms', delayLeave: '0ms'}})
    ]);
