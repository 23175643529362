import { Component, HostBinding, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { KzModal } from '../../../kz-ui/ui/modal/kz-modal';
import { InfoModalContext } from './info-modal.options';
import { Observable, Subject, timer } from 'rxjs';
import { finalize, map, take, takeUntil } from 'rxjs/operators';
import { KzIconsRegistryService } from '../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsValidCheck } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-valid-check.icon';
import { kzIconsCross } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-cross.icon';
import { ButtonContext } from '../../../kz-ui/ui/form/button/button-options';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoModalComponent implements OnInit, OnDestroy {

  @HostBinding('class.info-modal') classes = true;

  @Input() title: string;
  @Input() content: string | TemplateRef<any>;
  @Input() displayPicto: 'none' | 'valid' | 'invalid' = 'valid';
  @Input() closingTimer = 0;
  @Input() context: InfoModalContext = 'default';
  buttonContext: ButtonContext = 'default';
  public contentType: 'string' | 'template' = 'string';
  public timerObs: Observable<number>;
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    public kzModal: KzModal<any>,
    private kzIconsRegistryService: KzIconsRegistryService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsValidCheck, kzIconsCross]);
  }

  ngOnInit() {
    this.buttonContext = this.context as ButtonContext;
    this.contentType = (this.content instanceof TemplateRef) ? 'template' : 'string';
    if (this.closingTimer) {
      this.timerObs = timer(0, 1000).pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => this.close(true)),
        map(i => this.closingTimer - i),
        take(this.closingTimer + 1),
      );
    }
  }

  close(result: boolean){
    this.componentDestroyed$.next();
    this.kzModal.close(result);
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

}
