import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { timer } from 'rxjs/internal/observable/timer';
import { map, switchMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { HttpParams } from '@angular/common/http';
import { HttpUrlEncoder } from '@adeo/ngx-kozikaza-api';

export function uniqueField(fieldName = null, currentValue = null, /*cdr: ChangeDetectorRef, */control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(500).pipe(switchMap(() => {
        if (control.value === currentValue) {
            return of(null);
        }
        if (control.value === null || control.value.length < 3) {
            return EMPTY.pipe(map(() => {
                return null;
            }));
        } else {
            return this.userService.userExists(
                {query: new HttpParams({encoder: new HttpUrlEncoder()}).set(fieldName, control.value)})
                .pipe(
                    map((res: any) => (res.exists === true) ? {duplicated: true} : null),
                    tap(() => {
                      if (this.cdr) {
                        this.cdr.markForCheck();
                      }
                    })
                );
        }
    }));
}

export function uniqueFieldPro(fieldName = null, currentValue = null, control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(500).pipe(switchMap(() => {
        if (control.value === currentValue) {
            return of(null);
        }
        if ( control.value === null || control.value.length < 3) {
            return EMPTY.pipe(map(() => {
                return null;
            }));
        } else {
            return this.professionalService.professionalExists(control.value)
                .pipe(
                    map((res: any) => (res.exists === true) ? {duplicated: true} : null),
                    tap(() => {
                      if (this.cdr) {
                        this.cdr.markForCheck();
                      }
                    })
                );
        }
    }));
}

export function uniqueFieldUpdate(fieldName = null, control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(500).pipe(switchMap(() => {
        if (control.value === null || control.value.length < 3) {
            return EMPTY.pipe(map(() => {
                return null;
            }));
        } else {
            return this.userService.userExists({query: new HttpParams({encoder: new HttpUrlEncoder()}).set(fieldName, control.value)})
                .pipe(
                    map((res: any) => {
                        if (res.exists === true && ((fieldName === 'username' && control.value !== this.user.username) ||
                            (fieldName === 'email' && control.value !== this.user.email))) {
                            return {duplicated: true};
                        } else {
                            return null;
                        }
                    }),
                    tap(() => {
                      if (this.cdr) {
                        this.cdr.markForCheck();
                      }
                    })
                );
        }
    }));
}
