import { Log, LogInterceptor } from '@adeo/ngx-kozikaza-api';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, LOCALE_ID, Optional, PLATFORM_ID } from '@angular/core';
import { nanoid } from 'nanoid';
import { UserStoreService } from './state-management/user-store.service';

@Injectable()
export class DataLogInterceptor implements LogInterceptor {
  private static sessionId: string;

  private static logOrder: number = 1;

  private get userStoreService(): UserStoreService {
    return this.injector.get(UserStoreService);
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(LOCALE_ID) private readonly localeId: string,
    @Optional() @Inject('DEFAULT_COUNTRY') private readonly country: string,
    private injector: Injector,
  ) {
    if (!DataLogInterceptor.sessionId) {
      DataLogInterceptor.sessionId = nanoid();
    }
  }

  interceptLog(log: Log): Log {
    log.data = log.data || [];

    log.data.push({
      id: DataLogInterceptor.sessionId,
      side: (isPlatformBrowser(this.platformId) ? 'browser' : (isPlatformServer(this.platformId) ? 'server' : undefined)),
      locale: this.localeId,
      country: this.country,
      // TODO : fix userStoreService injection JIRA KZZ-1896
      // user: this.userStoreService.user ? {
      //   id: this.userStoreService.user['@id'],
      //   userName: this.userStoreService.user.username,
      // } : undefined,
      order: DataLogInterceptor.logOrder,
    });

    DataLogInterceptor.logOrder++;

    return log;
  }
}
