import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { KzUiGuard } from '../../shared/guards/kz-ui.guard';
import { RoutesService } from '../../shared/services/routes/routes.service';
import { AuthService } from '../../shared/services/features/oauth/auth.service';
import { ToastrService } from '../../shared/services/utilities/toastr.service';
import { LocalStorageService } from '../../shared/services/utilities/localstorage.service';
import { LocaleInterceptor } from '../../shared/interceptors/locale.interceptor';
import { UserService } from '../../shared/services/features/user';
import { OAuthGuard } from '../../shared/guards/OAuthGuard';
import { MediaQueriesService } from '../../shared/services/utilities/media-queries.service';
import { NotificationService } from '../../shared/services/features/notification/notification.service';
import { IsCommunityGuard, IsCommunityKazaGuard } from '../../shared/guards/is-community.guard';
import { CommunityService } from '../../shared/services/community.service';
import { KazaNavigationService } from '../kaza/kaza-navigation.service';
import { DataLayerService } from '../../shared/services/utilities/data-layer.service';
import { KazaService } from '../../shared/services/features/kaza/kaza.service';
import { HomeGuard } from '../../shared/guards/home.guard';
import { BlogInterceptor } from '../../shared/interceptors/blog.interceptor';
import { I18nService } from '../../shared/services';

// HttpClientModule is declared inside CoreModule because it has to be imported only once, to allow HTTP_INTERCEPTORS to work properly
@NgModule({
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  declarations: [],
  exports: [
    HttpClientModule,
    HttpClientJsonpModule,
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
          'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        RoutesService,
        UserService,
        LocalStorageService,
        OAuthGuard,
        HomeGuard,
        AuthService,
        KazaService,
        ToastrService,
        MediaQueriesService,
        KazaNavigationService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LocaleInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BlogInterceptor,
          multi: true
        },
        NotificationService,
        CommunityService,
        IsCommunityGuard,
        IsCommunityKazaGuard,
        DataLayerService,
        I18nService,
        KzUiGuard,
      ]
    };
  }
}
