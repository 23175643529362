import { Injectable, OnDestroy, TemplateRef, Type } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes/routes.service';
import { Observable, Subscription } from 'rxjs';
import { KzModal } from '../../kz-ui/ui/modal/kz-modal';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { KzModalService } from '../../kz-ui/ui/modal/kz-modal.service';
import { ConfirmationModalOptions } from './confirmation-modal/confirmation-modal.options';
import { ModalOptions } from '../../kz-ui/ui/modal/modal-options';
import { InfoModalOptions } from './info-modal/info-modal.options';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { filter, map, take, tap } from 'rxjs/operators';
import { ConfirmationModalFullscreenComponent } from './confirmation-modal/confirmation-modal-fullscreen.component';

@Injectable()
export class ModalService implements OnDestroy {

  public modals: Array<KzModal<any>> = [];
  private closeAllSubscription: Subscription;

  constructor(
    protected readonly _kzModalService: KzModalService,
    protected readonly router: Router,
    protected readonly routesService: RoutesService,
  ) {
    this.routesService.eventNavigationEnd.subscribe(() => {
      for (const currentDialogRefs of this.modals) {
        currentDialogRefs.close();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.closeAllSubscription && !this.closeAllSubscription.closed) {
      this.closeAllSubscription.unsubscribe();
    }
  }
  //
  // public open(insideComponent: Type<any>, config: MatDialogConfig = {}, showCloseButton: boolean = this.defaultShowCloseButton,
  //             popinSize: ModalSizes = this.defaultModalSize, autoCloseAfterLink = true,
  //             insideCloseButton = false): MatDialogRef<ModalComponent> {
  //
  //   /* Add ModalSize default css classe */
  //   if (config.panelClass instanceof Array) {
  //     config.panelClass = [...config.panelClass, '' + popinSize];
  //   } else if (!!config.panelClass) {
  //     config.panelClass = [config.panelClass, '' + popinSize];
  //   } else {
  //     config.panelClass = ['' + popinSize];
  //   }
  //
  //   /* Add close button default css class */
  //   if (showCloseButton) {
  //       config.panelClass = [...config.panelClass, 'hasCloseButton'];
  //   }
  //
  //   /* default position set to 3.5rem */
  //   if (!config.position) { // || (config.position && !config.position.top)
  //     config.position = { top : this.defaultModalPositionFromTop };
  //   }
  //
  //   let dialogRef: MatDialogRef<ModalComponent>;
  //
  //   dialogRef = this.dialog.open(ModalComponent, config);
  //   this.instance = dialogRef.componentInstance.addComp(insideComponent);
  //   /* show close button*/
  //   if (!showCloseButton) {
  //     dialogRef.componentInstance.hideCloseButton();
  //   }
  //   dialogRef.componentInstance.insideCloseButton = insideCloseButton;
  //
  //   if (autoCloseAfterLink) {
  //     this.modals.push(dialogRef);
  //   }
  //
  //   return dialogRef;
  // }
  //
  // public openFullScreen(insideComponent: Type<any>, config: MatDialogConfig = {}): MatDialogRef<FullscreenModalComponent> {
  //   let dialogRef: MatDialogRef<FullscreenModalComponent>;
  //   dialogRef = this.dialog.open(FullscreenModalComponent, config);
  //   this.instance = dialogRef.componentInstance.addComp(insideComponent);
  //   this.modals.push(dialogRef);
  //   return dialogRef;
  // }
  public open<S>(
    insideComponent: string | TemplateRef<any> | Type<any>,
    configModal?: ModalOptions
  ): KzModal<S> {
    const modal: KzModal<S> = this._kzModalService.create<S>(
      insideComponent,
      {...{ size: 'xs', closeButton: false}, ...configModal},
    );
    modal.open();
    this.modals.push(modal);
    return modal;
  }

  public openInfoDialog(configInfo: InfoModalOptions, configModal?: ModalOptions): KzModal<InfoModalComponent> {
    const modal: KzModal<InfoModalComponent> = this._kzModalService.create(
      InfoModalComponent,
      {...{ size: 'fullscreen', closeButton: false}, ...configModal},
    );
    modal.open();
    for(const prop in configInfo) {
      switch (prop) {
        case 'title':
          modal.componentRef.instance.title = configInfo[prop];
          break;
        case 'content':
          modal.componentRef.instance.content = configInfo[prop];
          break;
        case 'displayPicto':
          modal.componentRef.instance.displayPicto = configInfo[prop];
          break;
        case 'closingTimer':
          modal.componentRef.instance.closingTimer = configInfo[prop];
          break;
        case 'context':
          modal.componentRef.instance.context = configInfo[prop];
          break;
      }
    }
    this.modals.push(modal);
    return modal;
  }

  public openInfoDialogOnClosed(configInfo: InfoModalOptions, configModal?: ModalOptions): Observable<boolean> {
    return this.openInfoDialog(configInfo, configModal).isClosedChanges.pipe(
      filter((isClosed) => isClosed),
      take(1),
    );
  }

  public  openYesNoDialog(configConfirmation: ConfirmationModalOptions, configModal?: ModalOptions, fullscreen = false): KzModal<ConfirmationModalFullscreenComponent | ConfirmationModalComponent> {
    let modal: KzModal<ConfirmationModalFullscreenComponent | ConfirmationModalComponent>;
    if (fullscreen) {
      modal = this._kzModalService.create(
        ConfirmationModalFullscreenComponent,
        {...{ size: 'fullscreen', closeButton: true}, ...configModal},
      );
    } else {
      modal = this._kzModalService.create(
        ConfirmationModalComponent,
        {...{ size: 'xs', closeButton: false}, ...configModal},
      );
    }
    modal.open();
    modal.componentRef.instance.cancelAction = false;
    modal.componentRef.instance.noAction = true;
    modal.componentRef.instance.yesAction = true;
    for(const prop in configConfirmation) {
      switch (prop) {
        case 'title':
          modal.componentRef.instance.title = configConfirmation[prop];
          break;
        case 'content':
          modal.componentRef.instance.content = configConfirmation[prop];
          break;
        case 'cancelAction':
          modal.componentRef.instance.cancelAction = configConfirmation[prop];
          break;
        case 'noAction':
          modal.componentRef.instance.noAction = configConfirmation[prop];
          break;
        case 'yesAction':
          modal.componentRef.instance.yesAction = configConfirmation[prop];
          break;
        case 'cancelActionLabel':
          modal.componentRef.instance.cancelActionLabel = configConfirmation[prop];
          break;
        case 'noActionLabel':
          modal.componentRef.instance.noActionLabel = configConfirmation[prop];
          break;
        case 'yesActionLabel':
          modal.componentRef.instance.yesActionLabel = configConfirmation[prop];
          break;
        case 'type':
          modal.componentRef.instance.type = configConfirmation[prop];
          break;
      }
    }
    this.modals.push(modal);
    return modal;
  }

  public openYesNoDialogOnClosed(configConfirmation: ConfirmationModalOptions, getResult = false, configModal?: ModalOptions, fullscreen = false): Observable<boolean> {
    const modal: KzModal<ConfirmationModalComponent> = this.openYesNoDialog(configConfirmation, configModal, fullscreen);
    return modal.isClosedChanges.pipe(
      filter((isClosed) => isClosed),
      take(1),
      filter(() => getResult || !!modal.result),
      map(() => !!modal.result),
    );
  }

}

