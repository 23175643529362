import { NgModule } from '@angular/core';
import { KzBadgeComponent } from './kz-badge/kz-badge.component';
import { KzAnimationsModule } from '../../animations/kz-animations.module';
import { CommonModule } from '@angular/common';
import { KzIconsModule } from '../icon/kz-icons.module';

@NgModule({
  imports: [
    CommonModule,
    KzAnimationsModule,
    KzIconsModule,
  ],
  declarations: [
    KzBadgeComponent,
  ],
  exports: [
    KzBadgeComponent,
  ],
})
export class KzBadgeModule {

}
