import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { BreadcrumbComponent } from './breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';
import { KzIconsModule } from '../../../kz-ui/ui/icon/kz-icons.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LocalizeRouterModule,
    TranslateModule,
    KzIconsModule,
  ],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbModule { }
