import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GtmTrackDirective } from './gtm-track.directive';



@NgModule({
  imports: [CommonModule],
  declarations: [GtmTrackDirective],
  exports: [GtmTrackDirective]
})
export class GtmTrackModule { }
