import { Validators } from '@angular/forms';
import { MISC_CONST } from './misc';
import { ProfessionalSubResources } from '@adeo/ngx-kozikaza-api';

export const currentUserProfessionalSubResources: ProfessionalSubResources = ['mainJob', 'private'];
export const currentUserProfessionalProfileSubResources: ProfessionalSubResources = ['private'];
export const navigationUserProfessionalSubResources: ProfessionalSubResources =
    ['mainJob', 'socialKozikazaUserId', {socialKozikazaKazaId: ['authors']}, 'styles'];

export const BOX_DEFAULT_PRICE = 149;

export const brandValidators = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(20)
];
export const pseudoValidators = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(20),
    Validators.pattern(MISC_CONST.USER_VALIDATOR_REGEX)
];
