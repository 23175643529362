import { Injectable } from '@angular/core';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import getDay from 'date-fns/getDay';
import getISODay from 'date-fns/getISODay';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import startOfMonth from 'date-fns/startOfMonth';
import startOfDay from 'date-fns/startOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import startOfYear from 'date-fns/startOfYear';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import addSeconds from 'date-fns/addSeconds';
import subSeconds from 'date-fns/subSeconds';
import addMinutes from 'date-fns/addMinutes';
import { parse } from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private _locale: Locale;

  constructor(
  ) { }

  set locale(value: Locale) {
    this._locale = value;
  }

  get locale(): Locale {
    return this._locale;
  }
  /*
  * Getters
  * */
  getDate(date: Date | number | string): Date | number {
    return typeof date === 'string' ? Date.parse(date) : date;
  }
  getYear(date: Date | number | string = new Date()): number {
    return getYear(this.getDate(date));
  }
  getMonth(date: Date | number | string = new Date()): number {
    return getMonth(this.getDate(date)) + 1;
  }
  getDay(date: Date | number | string = new Date()): number {
    return getDate(this.getDate(date));
  }
  getDayOfWeek(date: Date | number | string = new Date()): number {
    return getDay(this.getDate(date));
  }
  getISODayOfWeek(date: Date | number | string = new Date()): number {
    return getISODay(this.getDate(date));
  }

  /*
  * Format
  * */
  formatDate(formatStr: string, dateToFormat: Date | number | string = new Date()): string {
    return format(this.getDate(dateToFormat), formatStr, {locale: this.locale});
  }

  /*
  * Opérations
  * */

  addSeconds(amount: number, date: Date | number | string = new Date()): Date {
    return addSeconds(this.getDate(date), amount);
  }
  subSeconds(amount: number, date: Date | number | string = new Date()): Date {
    return subSeconds(this.getDate(date), amount);
  }
  addMinutes(amount: number, date: Date | number | string = new Date()): Date {
    return addMinutes(this.getDate(date), amount);
  }
  subDays(amount: number, date: Date | number | string = new Date()): Date {
    return subDays(this.getDate(date), amount);
  }
  addDays(amount: number, date: Date | number | string = new Date()): Date {
    return addDays(this.getDate(date), amount);
  }
  subMonths(amount: number, date: Date | number | string = new Date()): Date {
    return subMonths(this.getDate(date), amount);
  }
  addMonths(amount: number, date: Date | number | string = new Date()): Date {
    return addMonths(this.getDate(date), amount);
  }
  subYears(amount: number, date: Date | number | string = new Date()): Date {
    return subYears(this.getDate(date), amount);
  }
  addYears(amount: number, date: Date | number | string = new Date()): Date {
    return addYears(this.getDate(date), amount);
  }

  /*
  * Differences
  * */
  differenceInDays(date1: Date | number | string, date2: Date | number | string = new Date()) : number {
    return differenceInDays(this.getDate(date1), this.getDate(date2));
  }
  differenceInMonths(date1: Date | number | string, date2: Date | number | string = new Date()): number {
    return differenceInMonths(this.getDate(date1), this.getDate(date2));
  }
  differenceInYears(date1: Date | number | string, date2: Date | number | string = new Date()): number {
    return differenceInYears(this.getDate(date1), this.getDate(date2));
  }
  differenceInMinutes(date1: Date | number | string, date2: Date | number | string = new Date()): number {
    return differenceInMinutes(this.getDate(date1), this.getDate(date2));
  }
  differenceInSeconds(date1: Date | number | string, date2: Date | number | string = new Date()): number {
    return differenceInSeconds(this.getDate(date1), this.getDate(date2));
  }

  /*
  * Start / End
  * */
  startOfDay(date: Date | number | string = new Date()): Date {
    return startOfDay(this.getDate(date));
  }
  startOfMonth(date: Date | number | string = new Date()): Date {
    return startOfMonth(this.getDate(date));
  }

  startOfYear(date: Date | number | string = new Date()): Date {
    return startOfYear(this.getDate(date));
  }
  endOfMonth(date: Date | number | string = new Date()): Date {
    return endOfMonth(this.getDate(date));
  }

  endOfYear(date: Date | number | string = new Date()): Date {
    return endOfYear(this.getDate(date));
  }
  getDaysInMonth(date: Date | number | string = new Date()): number {
    return getDaysInMonth(this.getDate(date));
  }


  /*
  * Parser
  * */
  parseFromFormat(date: string, format = 'dd/MM/yyyy'): Date {
    return parse(date, format, new Date());
  }
  isValidDate(dateString: string, format?: string): boolean {
    return !isNaN(this.parseFromFormat(dateString, format).valueOf());
  }
}
