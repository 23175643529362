import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CheckboxToggleSize, CheckboxToggleState, CheckboxToggleContext } from '../checkbox-toggle-options';
import { AnimationType } from '../../../../animations/animations-options';

@Component({
  selector: 'kz-checkbox-toggle',
  host: {
    '[class]': '"kz-checkbox-toggle" + (classes ? " " + classes : "")',
  },
  template: `<kz-shimmer *ngIf="!!checkboxTogglePlaceholder"></kz-shimmer>
  <ng-content></ng-content>
  <label [for]="checkbox?.nativeElement.id">
    <ng-content select="[labelLeft]"></ng-content>
    <span class="kz-checkbox-fake-toggle" aria-hidden="true"><s></s></span>
    <ng-content select="[labelRight]"></ng-content>
  </label>
  `,
  styleUrls: ['./kz-checkbox-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzCheckboxToggleComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _checkboxToggleContext: CheckboxToggleContext = 'default';

  get checkboxToggleContext(): CheckboxToggleContext {
    return this._checkboxToggleContext;
  }

  @Input()
  set checkboxToggleContext(value: CheckboxToggleContext) {
    this._checkboxToggleContext = value;
  }

  private _checkboxToggleSize: CheckboxToggleSize = 'default';

  get checkboxToggleSize(): CheckboxToggleSize {
    return this._checkboxToggleSize;
  }

  @Input()
  set checkboxToggleSize(value: CheckboxToggleSize) {
    this._checkboxToggleSize = value;
  }

  private _checkboxTogglePlaceholder: AnimationType;

  get checkboxTogglePlaceholder(): AnimationType {
    return this._checkboxTogglePlaceholder;
  }

  @Input()
  set checkboxTogglePlaceholder(value: AnimationType) {
    this._checkboxTogglePlaceholder = value;
  }

  private _checkboxToggleState: CheckboxToggleState;

  get checkboxToggleState(): CheckboxToggleState {
    return this._checkboxToggleState;
  }

  @Input()
  set checkboxToggleState(value: CheckboxToggleState) {
    this._checkboxToggleState = value;
  }

  @ContentChild('checkbox')
  checkbox: ElementRef;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = !!this.checkboxTogglePlaceholder ? `kz-${this.checkboxTogglePlaceholder} kz-placeholder` : '';

    if (this.checkboxToggleContext && this.checkboxToggleContext !== 'default') {
      this._classes += ` kz-checkbox-toggle--${ this.checkboxToggleContext }`;
    }

    if (this.checkboxToggleSize && this.checkboxToggleSize !== 'default') {
      this._classes += ` kz-checkbox-toggle-size--${ this.checkboxToggleSize }`;
    }

    if (this.checkboxToggleState && this.checkboxToggleState !== 'default') {
      this._classes += ` kz-checkbox-toggle--${ this.checkboxToggleState }`;
    }
  }
}
