import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialShareComponent } from './social-share.component';
import { SocialShareDirective } from './social-share.directive';
import { KzIconsModule } from '../../kz-ui/ui/icon/kz-icons.module';
import { KzTooltipModule } from '../../kz-ui/ui/tooltip/kz-tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    KzIconsModule,
    KzTooltipModule,
  ],
  declarations: [
    SocialShareDirective,
    SocialShareComponent
  ],
  exports: [
    SocialShareDirective,
    SocialShareComponent
  ]
})
export class SocialShareModule { }
