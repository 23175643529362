import { Pipe, PipeTransform } from '@angular/core';
import { Professional } from '@adeo/ngx-kozikaza-api';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Pipe({
  name: 'proLink'
})
export class ProLinkPipe implements PipeTransform {

  constructor(
    private readonly _localizeRouterService: LocalizeRouterService
  ) {
  }

  transform(value: Professional, args?: 'edit' | 'activity' | 'service' | '3Dplan'): Array<string> {
    return (value?.mainJob && typeof value?.mainJob !== 'string') ?
      this._localizeRouterService.translateRoute(
        ['/', 'pro', value.mainJob.nameSlug, value.pseudo, args].filter(v => !!v)) as string[] : null;
  }
}
