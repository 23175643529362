import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Metas, SeoService } from '../../../shared/services/utilities/seo.service';
import { environment } from 'environments/environment';
import {
  KazaService,
  User,
  UserFollowsService,
  UserService,
  ErrorHandlerStrategy,
  WebServiceOptions,
  WebServiceSubResourceFetchMode,
  filterUndefined,
} from '@adeo/ngx-kozikaza-api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { KAZA_CONST } from '../../../utils/const/kaza';
import { getTranslation } from '../../../utils/const/translation';
import { UserStoreService } from '../../../shared/services/state-management/user-store.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class KazacafeFollowService {

    private _userLoggedFollowsOlga$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
    private _followChange$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
    private _totalAbo$: BehaviorSubject<number> = new BehaviorSubject(undefined);
    private olgaUserId = KAZA_CONST.OLGA_USER;
    private _getFollowedFetching: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
    private _getFolloweesFetching: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

    constructor(
        private seoService: SeoService,
        private kazaService: KazaService,
        private router: Router,
        private userService: UserService,
        private userFollowService: UserFollowsService,
        private userStoreService: UserStoreService
    ) { }

    public initMetas(loggedUser: User, activatedRoute?: ActivatedRouteSnapshot, initMetasFromRoute = false): Metas {
        const route = (activatedRoute) ? activatedRoute : this.activatedRoute;
        const titleKey = (route.data && route.data.metas && route.data.metas.titleKey) ?
            route.data.metas.titleKey : 'meta.kaza.posts-flux.title';
        const title = getTranslation(titleKey, {userName : loggedUser.username} );
        const image = (route.data && route.data.metas && route.data.metas.image) ? route.data.metas.image :
            `${environment.REDIRECT_URL}/assets/images/kazacafe/banner-follow.jpg`;
        const descriptionKey = (route.data && route.data.metas && route.data.metas.descriptionKey) ? route.data.metas.descriptionKey : null;
        const desc = (descriptionKey) ? getTranslation(descriptionKey) : '';
        const metas = {title, description : desc, image};
        if (!initMetasFromRoute) {
            this.seoService.setMetas({ ...route.data.metas , ...metas});
        }
        return metas;
    }

    get activatedRoute(): ActivatedRouteSnapshot {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.snapshot;
    }

    /* User Logged total followees */
    get userLoggedTotalFollowees$(): Observable<number> {
        const webServiceOptions: WebServiceOptions = {
            subResourceFetchMode: WebServiceSubResourceFetchMode.SYNC,
            errorHandlerStrategy: ErrorHandlerStrategy.Ignore,
            refresh: true,
            query: new HttpParams({fromObject: {
                    follow: this.userStoreService.user['@id']
                }
            })
        };
        if (this.userLoggedTotalFollowees === undefined && !this.getKazaFolloweesFetching) {
            this.getKazaFolloweesFetching = true;
            this.userFollowService.getUserFollows(null, webServiceOptions).pipe(
                take(1),
                switchMap((followees) => {
                    this.userLoggedTotalFollowees = followees['hydra:totalItems'];
                    this.getKazaFolloweesFetching = false;
                    return this._totalAbo$;
                }),
            ).subscribe();
        }
        return this._totalAbo$.asObservable().pipe(filterUndefined());
    }
    get userLoggedTotalFollowees(): number {
        return this._totalAbo$.getValue();
    }
    set userLoggedTotalFollowees(val: number) {
        this._totalAbo$.next(val);
    }
    get getKazaFolloweesFetching(): boolean {
        return this._getFolloweesFetching.getValue();
    }
    set getKazaFolloweesFetching(val: boolean) {
        this._getFolloweesFetching.next(val);
    }

    /* User Logged follows Olga */
    get userLoggedFollowsOlga$(): Observable<boolean> {
        if (this.userLoggedFollowsOlga === undefined && !this.getKazaFollowedFetching) {
            this.getKazaFollowedFetching = true;
            this.userService.getUser({'@id': this.olgaUserId}).pipe(
                take(1),
                switchMap((isFollowed) => {
                    this.userLoggedFollowsOlga = !!isFollowed.userFollowForCurrentUser;
                    this.getKazaFollowedFetching = false;
                    return this._userLoggedFollowsOlga$;
                })
            ).subscribe();
        }
        return this._userLoggedFollowsOlga$.asObservable().pipe(filterUndefined());
    }
    get userLoggedFollowsOlga(): boolean {
        return this._userLoggedFollowsOlga$.getValue();
    }
    set userLoggedFollowsOlga(val: boolean) {
        this._userLoggedFollowsOlga$.next(val);
    }
    get getKazaFollowedFetching(): boolean {
        return this._getFollowedFetching.getValue();
    }
    set getKazaFollowedFetching(val: boolean) {
        this._getFollowedFetching.next(val);
    }

    /* Followees changes */
    get kazaFollowChange$() {
        return this._followChange$.asObservable();
    }
    set kazaFollowChange(val: boolean) {
        this.userLoggedTotalFollowees = this.userLoggedTotalFollowees + (val ? 1 : -1);
        this._followChange$.next(val);
    }

    /* Util */
    get combineObs(): Observable<[number, boolean]> {
        return combineLatest([
            this.userLoggedTotalFollowees$,
            this.userLoggedFollowsOlga$,
        ]);
    }
    /* Only 1 Followees => OLGA*/
    get userLoggedFollowsOnlyOlga$(): Observable<boolean> {
        return this.combineObs.pipe( map(([total, olga]) => total === 1 && olga));
    }
    /* Many Followees => OLGA*/
    get userLoggedFollowsManyAndOlga$(): Observable<boolean> {
        return this.combineObs.pipe( map(([total, olga]) => total > 1 && olga));
    }
    /* Many Followees => Except OLGA*/
    get userLoggedFollowsManyExceptOlga$(): Observable<boolean> {
        return this.combineObs.pipe( map(([total, olga]) => total > 1 && !olga));
    }
}
