import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { KzButtonModule } from '../../kz-ui/ui/form/button/kz-button.module';
import { KzModalModule } from '../../kz-ui/ui/modal/kz-modal.module';
import { ModalService } from './modals.service';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { KzIconsModule } from '../../kz-ui/ui/icon/kz-icons.module';
import { ConfirmationModalFullscreenComponent } from './confirmation-modal/confirmation-modal-fullscreen.component';
import { KzCardModule } from '../../kz-ui/ui/card/kz-card.module';

@NgModule({
  imports: [
    CommonModule,
    KzButtonModule,
    KzModalModule,
    KzIconsModule,
    KzCardModule,
  ],
  declarations: [
    ConfirmationModalComponent,
    ConfirmationModalFullscreenComponent,
    InfoModalComponent
  ],
  providers: [
    ModalService
  ]
})
export class ModalsModule { }
