import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { layerAnimation, layerAnimationB, layerAnimationD } from '../../../../shared/animations/layer.animation';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { entranceAnimation } from '../../../../shared/animations/entrances.animation';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UserStoreService } from '../../../../shared/services/state-management/user-store.service';
import { KzIconsRegistryService } from '../../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsSearch } from '../../../../kz-ui/ui/icon/generated-icons/kzIcons-search.icon';
import { navigationIcons } from '../../../../kz-ui/ui/icon/groups/kz-icons-group-navigation';
import { TranslateStore } from "@ngx-translate/core";
import {
  ErrorHandlerStrategy,
  HydraMember,
  User,
  WebServiceOptions,
  WebServiceSubResourceFetchMode,
} from '@adeo/ngx-kozikaza-api';
import { HttpParams } from '@angular/common/http';
import { GoodDealsMenuArea } from 'src/app/utils/const/deals';
import { tap } from 'rxjs/operators';
import { DealAreaService, DealArea } from '@adeo/ngx-kozikaza-api/affiliate';

@Component({
  selector: 'app-header-nav',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [layerAnimation, layerAnimationB, layerAnimationD, entranceAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnDestroy, OnInit {

  user: User;
  needTransition = false;

  public isAllowed = true;
  public opena = false;
  public openb = false;
  public openc = false;
  public opend = false;
  public opene = false;
  public openInspiSubNav: 0 | 1 | 2 | 3 | 4 = 1;
  public bathroomSlug = 'salle-de-bain';
  public kitchenSlug = 'cuisine';
  public dressingSlug = 'dressing-et-rangements';
  public outdoorDesignSlug = 'amenagement-exterieur';
  public blogUrl = environment.BLOG_URL;

  public inspirationKitchen = { isHD: true, isMedia: true, isPlan: true};
  public inspirationBathroom = { isHD: true, isMedia: true, isPlan: true};
  public inspirationDressing = { isHD: false, isMedia: true, isPlan: true};
  public inspirationOutdoorDesign = { isHD: false, isMedia: true, isPlan: true};
  public inspirationOutdoorDesignTerrace = { isHD: false, isMedia: true, isPlan: false};
  public inspirationOutdoorDesignGarden = { isHD: false, isMedia: true, isPlan: false};

  public dealsOffers: Array<DealArea> = [];

  private landingSubscription: Subscription;
  private subDeals: Subscription;

  constructor(
      private el: ElementRef,
      private render: Renderer2,
      @Inject(DOCUMENT) private document: any,
      @Inject(PLATFORM_ID) private platformId: object,
      private readonly localizeRouterService: LocalizeRouterService,
      public userStoreService: UserStoreService,
      private kzIconsRegistryService: KzIconsRegistryService,
      private readonly translateStore: TranslateStore,
      @Inject(LOCALE_ID) public locale: string,
      private dealAreaService: DealAreaService,
      private cdr: ChangeDetectorRef,
  ) {
    this.kzIconsRegistryService.registerIcons([...navigationIcons, kzIconsSearch]);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadDeals();
    }

    this.userStoreService.userStore
      .pipe(
        tap((user: User) => {
          this.user = user;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.landingSubscription) {
      this.landingSubscription.unsubscribe();
    }
  }

  loadDeals(): void {
    const webServiceOptions2: WebServiceOptions = {
      subResourceFetchMode: WebServiceSubResourceFetchMode.SYNC,
      errorHandlerStrategy: ErrorHandlerStrategy.Ignore,
      refresh: true,
      query: new HttpParams({
        fromObject: {
          'areas[]': GoodDealsMenuArea,
        }
      })
    };

    this.subDeals = this.dealAreaService.getDealAreasSegmentation([{deal: ['brand']}], webServiceOptions2)
      .pipe(
        tap((dealAreas) => {
          this.dealsOffers = GoodDealsMenuArea.reduce((acc, d) => {
            const matchingDeal = dealAreas['hydra:member']?.find((da) => da.area === d);
            if (matchingDeal) {
              acc.push(matchingDeal);
            }
            return acc;
          }, []);
        })
      )
      .subscribe();
  }

  onMouseLeave() {
    this.removeUnderline();
  }

  underlineLink(event: MouseEvent) {
    if (isPlatformBrowser(this.platformId)) {
      // tslint:disable-next-line:no-string-literal
      const width = event.target['clientWidth'];
      // tslint:disable-next-line:no-string-literal
      const posX  = event.target['offsetLeft'] + (width / 2);
      const bgcol = (event.target as Element).getAttribute('data-underline-color') ;
      const delay = 75;
      const headerElement = this.document.querySelector('.kz-header');
      const nativeElement = this.el.nativeElement.querySelector('.kz-header-nav--underline');

      if (headerElement.classList.contains('kz-header--hovered')) {
        this.render.setStyle(nativeElement, 'transform', 'rotateZ(360deg) translateX(' + posX + 'px) scale3d(1, 1, 1)');
        this.render.setStyle(nativeElement, 'background', 'rgba(255, 255, 255, 0)');
      }

      setTimeout(() => {
        this.render.addClass(nativeElement, 'kz-header-nav-transition');
        this.render.setAttribute(nativeElement, 'data-width', width.toString());
        this.render.setStyle(nativeElement, 'transform', 'rotateZ(360deg) translateX(' + posX + 'px) scale3d(' + width / 2 + ', 1, 1)');
        this.render.setStyle(nativeElement, 'background', bgcol);
      }, delay);
    }
  }

  removeUnderline() {
    if (isPlatformBrowser(this.platformId)) {
      const nativeElement = this.el.nativeElement.querySelector('.kz-header-nav--underline');
      const posX = nativeElement.getAttribute('data-posx');
      const duration = parseFloat(window.getComputedStyle(nativeElement, null).getPropertyValue('transition-duration'));
      this.render.setStyle(nativeElement, 'transform', 'rotateZ(360deg) translateX(' + posX + 'px) scale3d(0, 1, 1)');
      this.render.setStyle(nativeElement, 'background', 'rgba(255, 255, 255, 0)');

      setTimeout(() => {
        this.render.removeClass(nativeElement, 'kz-header-nav-transition');
      }, duration);
    }
  }

  trackById(index, data: HydraMember): string {
    return data ? data['@id'] : undefined;
  }
}
