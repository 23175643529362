import { NgModule } from '@angular/core';
import { KzInputRangeComponent } from './kz-input-range.component';

@NgModule({
  imports: [
  ],
  declarations: [
    KzInputRangeComponent,
  ],
  exports: [
    KzInputRangeComponent,
  ],
})
export class KzInputRangeModule {

}
