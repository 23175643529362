import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ImageResizeService {

  constructor() { }

  static resizePath(imgPath: string, width: number, height: number) {
    return `${environment.URL_RESIZE}/test/fill/${width}/${height}/sm/0/plain/${imgPath}`;
  }

  // https://docs.imgproxy.net/#/generating_the_url_basic
  static resizeMedia(args: {
    imgPath: string,
    width: number,
    height: number,
    dpr?: number,
    type?: ImageResizeType,
    format?: ImageResizeFormat,
    gravity?: ImageResizeGravity
  }) {
    args.width = args.width || 0;
    args.height = args.height || 0;
    args.dpr = args.dpr || 1;
    args.gravity = args.gravity || 'ce';
    args.type = args.type || 'fill';
    const settings = `resize:${args.type}:${args.width}:${args.height}:0/gravity:${args.gravity}\
${args.dpr > 1 ? '/dpr:' + args.dpr : ''}`;
    const base64 = btoa(args.imgPath);
    return `${environment.URL_RESIZE}/${environment.URL_RESIZE_KEY}/${settings}/${base64}${args.format ? '.' + args.format : ''}`;
  }

  static resizeMediaPreset(
    args: {
      imgPath: string;
      cleanPath?: boolean;
      presets?: Preset | Array<Preset>;
      customSettings?: {
        width: number;
        height: number;
        dpr?: number;
        type?: ImageResizeType;
        format?: ImageResizeFormat;
        gravity?: ImageResizeGravity;
      };
      bucket?: string;
    }) {
    let settings = '';
    args.cleanPath = (args.cleanPath != null || args.cleanPath !== undefined) ? args.cleanPath : true;
    if (!args.presets) {
      args.customSettings.width = args.customSettings?.width || 0;
      args.customSettings.height = args.customSettings?.height || 0;
      args.customSettings.dpr = args.customSettings?.dpr || 1;
      args.customSettings.gravity = args.customSettings?.gravity || 'ce';
      args.customSettings.type = args.customSettings?.type || 'fill';
      settings = `rs:${args.customSettings.type}:${args.customSettings.width}:${args.customSettings.height}:0\
/g:${args.customSettings.gravity}${args.customSettings.dpr > 1 ? '/dpr:' + args.customSettings.dpr : ''}`;
      if (args.customSettings.format) {
        settings += `/f:${args.customSettings.format}`;
      }
    } else {
      settings = `pr:${Array.isArray(args.presets) ? args.presets.map((p) => p.presetString).join(':') : args.presets.presetString}`;
      if (args.customSettings) {
        if (args.customSettings.width || args.customSettings.height || args.customSettings.type) {
          args.customSettings.width = args.customSettings.width || 0;
          args.customSettings.height = args.customSettings.height || 0;
          args.customSettings.type = args.customSettings.type || 'fill';
          settings += `/rs:${args.customSettings.type}:${args.customSettings.width}:${args.customSettings.height}:0`;
        }
        if (args.customSettings.gravity) {
          settings += `/g:${args.customSettings.gravity}`;
        }
        if (args.customSettings.dpr) {
          settings += `/dprg:${args.customSettings.dpr}`;
        }
      }
    }
    const pathWithSettings = `${environment.URL_RESIZE}/${environment.URL_RESIZE_KEY}/${settings}/plain/`;
    //    cleanName
    let cleanedPath;
    if (!args.imgPath.includes('http')) {
      args.imgPath = `${environment.REDIRECT_URL}${args.imgPath}`;
    }
    if (args.cleanPath) {
      const url = new URL(args.imgPath);
      const hostname = url.hostname;
      const bucket = (args.bucket) ? args.bucket : ((hostname.includes(environment.URL_RESIZE_MEDIA_PRO_HOST))
        ? environment.URL_RESIZE_BUCKET_PRO_KEY
        : (hostname.includes(environment.URL_RESIZE_MEDIA_ASSETS_HOST))
          ? environment.URL_RESIZE_BUCKET_ASSETS_KEY
          : environment.URL_RESIZE_BUCKET_KEY);
      cleanedPath = `${bucket}${url.pathname.replace(`/${bucket}`, '')}`;
    } else {
      cleanedPath = args.imgPath;
    }
    const imgPath = `${cleanedPath}${args.customSettings?.format ? '@' + args.customSettings.format : ''}`;
    return pathWithSettings + imgPath;
  }
}

export type ImageResizeDensity = 1 | 2 | 3;
export type ImageResizeType = 'fit' | 'fill' | 'auto';
export type ImageResizeFormat = 'jpg' | 'webp' | 'png';
export type ImageResizeGravity = 'no' | 'so' | 'ea' | 'we' | 'noea' | 'nowe' | 'soea' | 'sowe' | 'ce' | 'sm' | 'fp:%x:%y';
export type ImageResizeSizes = 'xs' | 'sm' | 'md-sm' | 'md' | 'lg' | 'xl'| 'xxl';
export type ImageResizeFormats = 'webp';
export type ImageResizePresets = 'user-avatar' | 'pro-service' | 'pro-service-pw' | 'pro-carousel' | 'pro-banner' | 'pro-banner-landing' |
  'pro-bg-landing' | 'pro-style-filter' | 'gallery-media' | 'kazabook-cover' | 'inspi-plans' | 'inspi-ideas' | 'landing-inspi' |
  'hp-visu' | 'hp-banner' | 'pro-item' | 'bg-landing' | 'landing-step' | 'landing-example' | 'landing-bathroom-example' | ImageResizeGenericPresets;
export type ImageResizeGenericPresets =
  'w5' | 'w10' | 'w15' | 'w20' | 'w25' | 'w30' | 'w35' | 'w40' | 'w45' | 'w50' |
  'w55' | 'w60' | 'w65' | 'w70' | 'w75' | 'w80' | 'w85' | 'w90' | 'w95' | 'w100' |
  'w105' | 'w110' | 'w115' | 'w120' | 'w125' | 'w130' | 'w135' | 'w140' | 'w145' | 'w150' |
  'w155' | 'w160' | 'w165' | 'w170' | 'w175' | 'w180' | 'w185' | 'w190' | 'w195' | 'w200' |
  'w205' | 'w210' | 'w215' | 'w220' | 'w225' | 'w230' | 'w235' | 'w240' | 'w245' | 'w250' |
  'w255' | 'w260' | 'w265' | 'w270' | 'w275' | 'w280' | 'w285' | 'w290' | 'w295' | 'w300' |
  'w305' | 'w310' | 'w315' | 'w320' | 'w325' | 'w330' | 'w335' | 'w340' | 'w345' | 'w350' |
  'w355' | 'w360' | 'w365' | 'w370' | 'w375' | 'w380' | 'w385' | 'w390' | 'w395' | 'w400' |
  'w405' | 'w410' | 'w415' | 'w420' | 'w425' | 'w430' | 'w435' | 'w440' | 'w445' | 'w450' |
  'w455' | 'w460' | 'w465' | 'w470' | 'w475' | 'w480' | 'w485' | 'w490' | 'w495' | 'w500' |
  'w505' | 'w510' | 'w515' | 'w520' | 'w525' | 'w530' | 'w535' | 'w540' | 'w545' | 'w550' |
  'w555' | 'w560' | 'w565' | 'w570' | 'w575' | 'w580' | 'w585' | 'w590' | 'w595' | 'w600' |
  'w605' | 'w610' | 'w615' | 'w620' | 'w625' | 'w630' | 'w635' | 'w640' | 'w645' | 'w650' |
  'w655' | 'w660' | 'w665' | 'w670' | 'w675' | 'w680' | 'w685' | 'w690' | 'w695' | 'w700' |
  'w705' | 'w710' | 'w715' | 'w720' | 'w725' | 'w730' | 'w735' | 'w740' | 'w745' | 'w750' |
  'w755' | 'w760' | 'w765' | 'w770' | 'w775' | 'w780' | 'w785' | 'w790' | 'w795' | 'w800' |
  'w805' | 'w810' | 'w815' | 'w820' | 'w825' | 'w830' | 'w835' | 'w840' | 'w845' | 'w850' |
  'w855' | 'w860' | 'w865' | 'w870' | 'w875' | 'w880' | 'w885' | 'w890' | 'w895' | 'w900' |
  'w905' | 'w910' | 'w915' | 'w920' | 'w925' | 'w930' | 'w935' | 'w940' | 'w945' | 'w950' |
  'w955' | 'w960' | 'w965' | 'w970' | 'w975' | 'w980' | 'w985' | 'w990' | 'w995' | 'w1000' |
  'w1005' | 'w1010' | 'w1015' | 'w1020' | 'w1025' | 'w1030' | 'w1035' | 'w1040' | 'w1045' | 'w1050' |
  'w1055' | 'w1060' | 'w1065' | 'w1070' | 'w1075' | 'w1080' | 'w1280' | 'w1600' |
  'h140' ;

export interface PresetI {
  readonly preset: ImageResizePresets;
  density?: ImageResizeDensity;
  size?: ImageResizeSizes;
}
export class Preset implements PresetI {
  readonly preset: ImageResizePresets;
  density?: ImageResizeDensity;
  size?: ImageResizeSizes;
  format?: ImageResizeFormats;
  constructor(density?: ImageResizeDensity, size?: ImageResizeSizes, format?: ImageResizeFormats) {
    this.density = density;
    this.size = size;
    this.format = format;
  }
  get presetString(): string {
    return `${this.preset}${this.size ? '-' + this.size : ''}${(this.density && this.density > 1) ? '-' + this.density + 'x' : ''}\
${this.format ? '-' + this.format : ''}`;
  }
}

export class PresetGenericWidth extends Preset {
  preset: ImageResizeGenericPresets;
  density: 1;
  constructor(preset: ImageResizeGenericPresets) {
    super(1, undefined);
    this.preset = preset;
  }
}

export class PresetLogo extends Preset {
  readonly preset = 'logo' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs' | 'xl';
  constructor(size?: 'xs' | 'xl', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetUserAvatar extends Preset {
  readonly preset = 'user-avatar' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs' | 'sm' | 'md-sm' | 'md' | 'lg' | 'xxl';
  constructor(size: 'xs' | 'sm' | 'md-sm' | 'md' | 'lg' | 'xxl' = 'lg', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProService extends Preset {
  readonly preset = 'pro-service' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'sm' | 'md';
  constructor(size?: 'sm' | 'md', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProServicePw extends Preset {
  readonly preset = 'pro-service-pw' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'sm';
  constructor(size?: 'sm', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProCarousel extends Preset {
  readonly preset = 'pro-carousel' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}
export class PresetProItem extends Preset {
  readonly preset = 'pro-item' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProBanner extends Preset {
  readonly preset = 'pro-banner' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs' | 'md';
  constructor(size?: 'xs' | 'md', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProBannerLanding extends Preset {
  readonly preset = 'pro-banner-landing' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProBgLanding extends Preset {
  readonly preset = 'pro-bg-landing' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs' | 'sm' | 'md' | 'lg';
  constructor(size?: 'xs' | 'sm' | 'md' | 'lg', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetBgLanding extends Preset {
  readonly preset = 'landing-bg' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'sm' | 'md' | 'lg';
  constructor(size?: 'sm' | 'md' | 'lg', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetLandingStep extends Preset {
  readonly preset = 'landing-step' as ImageResizePresets;
  density: 1 | 2 | 3;
  constructor(density?: 1 | 2 | 3) {
    super(density, undefined);
  }
}

export class PresetLandingExample extends Preset {
  readonly preset = 'landing-ex' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetLandingBathroomExample extends Preset {
  readonly preset = 'landing-bath-ex' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetProStyleFilter extends Preset {
  readonly preset = 'pro-style-filter' as ImageResizePresets;
  density: 1;
  constructor() {
    super(1, undefined);
  }
}

export class PresetGalleryMedia extends Preset {
  readonly preset = 'gallery-media' as ImageResizePresets;
  density: 1 | 2 | 3;
  constructor(density?: 1 | 2 | 3) {
    super(density, undefined);
  }
}

export class PresetKazabookCover extends Preset {
  readonly preset = 'kazabook-cover' as ImageResizePresets;
  density: 1 | 2 | 3;
  constructor(density?: 1 | 2 | 3) {
    super(density, undefined);
  }
}

export class PresetHpBanner extends Preset {
  readonly preset = 'hp-banner' as ImageResizePresets;
  density: 1 | 2;
  size: 'xs' | 'sm' | 'md' | 'lg';
  constructor(size?: 'xs' | 'sm' | 'md' | 'lg', density?: 1 | 2 | 3) {
    super(density, size);
  }
}
export class PresetHpVisu extends Preset {
  readonly preset = 'hp-visu' as ImageResizePresets;
  density: 1 | 2;
  constructor(density?: 1 | 2 | 3) {
    super(density);
  }
}

export class PresetLandingInspi extends Preset {
  readonly preset = 'landing-inspi' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetInspiIdeas extends Preset {
  readonly preset = 'inspi-ideas' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}

export class PresetInspiPlans extends Preset {
  readonly preset = 'inspi-plans' as ImageResizePresets;
  density: 1 | 2 | 3;
  size: 'xs';
  constructor(size?: 'xs', density?: 1 | 2 | 3) {
    super(density, size);
  }
}
