import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalType } from '../../modal-options';

@Component({
  selector: 'kz-modal-header',
  host: {
    '[class]': '"kz-modal-header" + (classes ? " " + classes : "")',
  },
  styleUrls: ['./kz-modal-header.component.scss'],
  template: `<ng-content></ng-content>`,
  encapsulation: ViewEncapsulation.None
})
export class KzModalHeaderComponent implements OnInit {

  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _type: ModalType = 'default';

  @Input()
  set type(type: ModalType) {
    this._type = type;
  }

  get type(): ModalType {
    return this._type;
  }

  private _border = false;

  @Input()
  set border(border: boolean) {
    this._border = border;
  }

  get border(): boolean {
    return this._border;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.type && this.type !== 'default') {
      this._classes += ` kz-modal-header--${ this.type }`;
    }
    if (this.border) {
      this._classes += ' kz-modal-header--border';
    }
  }

}
