import { distinctHydraMember, filterUndefined, User } from '@adeo/ngx-kozikaza-api';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription, switchMap } from 'rxjs';
import { RoutesService } from '../../../shared/services/routes/routes.service';
import { tap } from 'rxjs/operators';
import { OnDestroyComponent } from '../../../shared/components/on-destroy/on-destroy.component';
import {
  layerAnimationB,
  layerAnimationC,
  layerSlideFromLeftAnimation,
  layerSlideFromRightAnimation,
  layerSlideFromRightAnimationB,
} from '../../../shared/animations/layer.animation';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaQueriesService } from '../../../shared/services/utilities/media-queries.service';
import { UserAgentService } from '../../../shared/services/utilities/user-agent.service';
import { AutoUnsubscribe } from '../../../utils/misc/auto-unsubscribe';
import { UserStoreService } from '../../../shared/services/state-management/user-store.service';
import { CookieService } from 'ngx-cookie';
import { KzIconsRegistryService } from '../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsSearch } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-search.icon';
import { kzIconsBurger } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-burger.icon';
import { kzIconsKozikaza } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-kozikaza.icon';
import { kzIconsArrowdown } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-arrowdown.icon';
import { kzIconsRoom } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-room.icon';
import { ConnectedNavComponent } from "./connected-nav/connected-nav/connected-nav.component";
import {
  FixedBannerMobileStoresComponent
} from "../../../shared/components/banners/banner-mobile-stores/fixed-banner-mobile-stores/fixed-banner-mobile-stores.component";
import { NEVER } from "rxjs/internal/observable/never";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    layerSlideFromRightAnimation,
    layerSlideFromRightAnimationB,
    layerSlideFromLeftAnimation,
    layerAnimationB,
    layerAnimationC],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class HeaderComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  connectedUser: User = null;

  private isConnected$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public isFullHeader = true;
  public hideLoginForm = false;
  public hideHeader = false;
  public forceHideMenus = false;
  public searchOpened = false;
  public width = null;
  public nbKazas  = 2;
  private subscriptionActiveRoute: Subscription;
  private userSubscription: Subscription;
  private mediaQuerySubscription: Subscription;

  public isMobile: boolean;
  public userAgentIsMobile = false;
  public isUniversalRendered = false;
  public openRespMenuLayer = false;
  public isTransparent = false;

  @ViewChild('searchCTA', {read: ElementRef, static: false}) exception: ElementRef;
  @ViewChild('connectedNavVcr', { read: ViewContainerRef, static: false}) connectedNavVcr: ViewContainerRef;
  @ViewChild('fixedBannerMobileStores', { read: ViewContainerRef, static: false}) fixedBannerMobileStores: ViewContainerRef;
  @ViewChild('header', { read: ElementRef, static: true }) header: ElementRef;

  constructor(
    public routesService: RoutesService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private elem: ElementRef,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private mediaQueriesService: MediaQueriesService,
    private userAgentService: UserAgentService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private doc: any,
    public readonly userStoreService: UserStoreService,
    private injector: Injector,
    private cookieService: CookieService,
    private kzIconsRegistryService: KzIconsRegistryService,
  ) {
    super();
    this.kzIconsRegistryService.registerIcons([kzIconsSearch, kzIconsBurger, kzIconsKozikaza, kzIconsArrowdown, kzIconsRoom]);
    this.userSubscription = this.userStoreService.userStore.pipe(
      filterUndefined(),
      distinctHydraMember(),
      tap((user: User) => {
        if (user === null) {
          this.connectedNavVcr?.clear();
          this.isConnected$.next(false);
        } else {
          this.connectedUser = user;
          this.loadConnectedNavComponent();
        }
      })
    ).subscribe();
  }

  loadConnectedNavComponent() {
    import('./connected-nav/connected-nav/connected-nav.component')
      .then(connectedNavComponent => {
        this.connectedNavVcr.clear();
        this.connectedNavVcr.createComponent<ConnectedNavComponent>(connectedNavComponent.ConnectedNavComponent);
        this.isConnected$.next(true);
      });
  }

  loadMobileStoresBanner() {
    import('../../../shared/components/banners/banner-mobile-stores/fixed-banner-mobile-stores/fixed-banner-mobile-stores.component')
      .then(fixedBannerMobileStoresComponent => {
        if (this.fixedBannerMobileStores) {
          this.fixedBannerMobileStores.clear();
          this.fixedBannerMobileStores.createComponent<FixedBannerMobileStoresComponent>(fixedBannerMobileStoresComponent.FixedBannerMobileStoresComponent);
        }
      });
  }

  public get isConnected(): Observable<boolean> {
    return this.isConnected$.asObservable().pipe(filterUndefined());
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.isUniversalRendered = true;
    }
    this.userAgentIsMobile = this.userAgentService.userAgentIsMobile();
    if (this.userAgentIsMobile && !this.cookieService.get('mobile_store_hide')) {
      this.loadMobileStoresBanner();
    }

    this.mediaQuerySubscription = this.mediaQueriesService.isMobile().subscribe((is: boolean) => this.isMobile = is);

    this.subscriptionActiveRoute = this.routesService.routeDatas.pipe(
      switchMap((datas) => {
        this.isFullHeader = (datas.isFullHeader !== null && typeof (datas.isFullHeader) === 'boolean') ? datas.isFullHeader : false;
        this.hideLoginForm = (datas.hideLoginForm !== null && typeof (datas.hideLoginForm) === 'boolean') ? datas.hideLoginForm : false;
        this.hideHeader = (datas.hideHeader !== null && typeof (datas.hideHeader) === 'boolean') ? datas.hideHeader : false;
        this.hideHeader = (datas.hideHeader !== null && typeof (datas.hideHeader) === 'boolean') ? datas.hideHeader : false;
        this.isTransparent = (datas.isTransparentHeader !== null && typeof (datas.isTransparentHeader) === 'boolean') ? datas.isTransparentHeader : false;

        if (this.hideHeader) {
          this.renderer.setStyle(document.body, 'padding-top', '4rem');
          this.toggleNavbarTransparency();
        }
        // Listen to transparent header events
        return isPlatformBrowser(this.platformId) && !!this.isTransparent ?
          merge(
            fromEvent(this.header.nativeElement, 'mouseenter').pipe(
              tap(() => {
                this.forceHideMenus = false;
                this.renderer.addClass(document.body, 'kz-header-remove-transparency');
              }),
            ),
            fromEvent(this.header.nativeElement, 'mouseleave').pipe(
              tap(() => this.toggleNavbarTransparency()),
            ),
            fromEvent(window, 'scroll').pipe(
              tap(() => this.toggleNavbarTransparency()),
            ),
          )
          : NEVER
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(document.getElementsByTagName('html')[0], 'kz-header--show-login-menu');
      this.renderer.removeClass(document.getElementsByTagName('html')[0], 'kz-header--show-publication-menu');
      this.renderer.removeClass(document.getElementsByTagName('html')[0], 'kz-header--show-kaza-menu');
      this.renderer.removeClass(document.getElementsByTagName('html')[0], 'kz-header--show-menu');
    }

    if(this.subscriptionActiveRoute && !this.subscriptionActiveRoute.closed) {
      this.subscriptionActiveRoute.unsubscribe();
    }
    if(this.mediaQuerySubscription && !this.mediaQuerySubscription.closed) {
      this.mediaQuerySubscription.unsubscribe();
    }
    if(this.userSubscription && !this.userSubscription.closed) {
      this.userSubscription.unsubscribe();
    }
  }

  toggleNavbarTransparency() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isTransparent) {
        const scrollPosition = window.pageYOffset;
        if (scrollPosition === 0) {
          this.renderer.removeClass(document.body, 'kz-header-remove-transparency');
        } else {
          this.renderer.addClass(document.body, 'kz-header-remove-transparency');
        }
      }
    }
  }

  toggleRespMenuLayerAction(forcedState: boolean) {
    this.openRespMenuLayer = (forcedState == null) ? !this.openRespMenuLayer : forcedState;
  }

  hideMenus() {
    // todo : update lazyloaded component (via service...?)
    this.openRespMenuLayer = false;
    // this.openRespUserLayer = false;
    // this.openRespPublicationLayer = false;
  }

  login() {
    this.router.navigate(['', {outlets: {auth: ['authentification', 'login']}}] );
  }
}
