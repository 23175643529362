import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DateService } from "../../../shared/services/utilities/date.service";
import { CommonModule } from "@angular/common";
import { KzStickerModule } from "../../../kz-ui/ui/sticker/kz-sticker.module";

@Component({
  selector: 'app-date-detail',
  standalone: true,
  imports: [
    CommonModule,
    KzStickerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <kz-sticker *ngIf="!!beginsIn && beginsIn>0" stickerSize="small">Débute dans {{beginsIn}} {{beginsIn === 1 ? 'jour' : 'jours'}}</kz-sticker>
    <kz-sticker *ngIf="(!beginsIn || beginsIn<=0) && !!expiresIn && expiresIn>0 && expiresIn<3" stickerContext="error" stickerSize="small">Expire dans {{expiresIn}} {{expiresIn === 1 ? 'jour' : 'jours'}}</kz-sticker>
    <kz-sticker *ngIf="(!beginsIn || beginsIn<=0) && !!expiresIn && expiresIn>3 && expiresIn<7" stickerContext="warning" stickerSize="small">Expire dans {{expiresIn}} {{expiresIn === 1 ? 'jour' : 'jours'}}</kz-sticker>`,
})
export class DateDetailComponent implements OnInit {

  @Input() startDate: string | number | Date;
  @Input() endDate: string | number | Date;

  beginsIn: number;
  expiresIn: number;

  constructor(
    public readonly dateService: DateService,
    ) { }

  ngOnInit(): void {
    if (!!this.startDate) {
      this.beginsIn = this.diffInDays(this.startDate);
    }
    if (!!this.endDate) {
      this.expiresIn = this.diffInDays(this.endDate) + 1;
    }
  }

  diffInDays(d: string | number | Date): number {
    return Math.ceil(this.dateService.differenceInMinutes(d)/ 60 / 24);
  }

}
