import { NgModule } from '@angular/core';
import { KzRadioComponent } from './kz-radio.component';

@NgModule({
  imports: [],
  declarations: [
    KzRadioComponent,
  ],
  exports: [
    KzRadioComponent,
  ],
})
export class KzRadioModule {

}
