import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import localeEs from '@angular/common/locales/es';

export const languages = [
  'en',
  'fr',
  'pl',
  'es'
];

// default language for TranslateService
export const defaultLanguage = 'en';
// default language for LocalizeRouterService
export function defaultLanguageFunction() { return 'fr'; }

export const locales = {
  en: 'en_GB',
  fr: 'fr_FR',
  pl: 'pl_PL',
  es: 'es_ES',
};

export const localesData = {
  fr: localeFr,
  en: localeEn,
  pl: localePl,
  es: localeEs,
};

export interface TranslatedString {
  en?: string;
  fr?: string;
  pl?: string;
  es?: string;
}
