import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService as ToastrLibService } from 'ngx-toastr';

@Injectable()
export class ToastrService {

    constructor(@Inject(PLATFORM_ID) private platformId: object, private toastr: ToastrLibService) {
    }

  info(message: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.info(message);
    }
  }

  warning(message: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.warning(message);
    }
  }

  error(message: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.error(message,  'Une erreur est survenue');
    }
  }
}

