import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { CheckboxContext } from './checkbox-options';

@Component({
  selector: '[kzCheckBox]',
  exportAs: 'kzCheckBox',
  host: {
    '[class]': '"kz-checkbox" + (classes ? " " + classes : "")',
  },
  template: '<ng-content></ng-content>',
  styleUrls: ['./kz-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzCheckboxComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _checkboxContext: CheckboxContext = 'default';

  @Input()
  set checkboxContext(type: CheckboxContext) {
    this._checkboxContext = type;
  }

  get checkboxContext(): CheckboxContext {
    return this._checkboxContext;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';


    if (this.checkboxContext && this.checkboxContext !== 'default') {
      this._classes += ` kz-checkbox--${ this.checkboxContext }`;
    }
  }
}
