import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgResizeModule } from 'src/app/shared/components/img-resize/img-resize.module';
import { GtmTrackModule } from 'src/app/utils/directives/gtm-track/gtm-track.module';
import { DateDetailComponent } from "../date-detail/date-detail.component";
import { ConcatUserIdPipe } from "../../../utils/pipes/concat-user-id.pipe";
import { Brand, Deal, DealArea } from '@adeo/ngx-kozikaza-api/affiliate';
import { AffiliateService } from 'src/app/shared/services/utilities/affiliate.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-good-deals-menu',
  standalone: true,
  imports: [
    CommonModule,
    ImgResizeModule,
    GtmTrackModule,
    DateDetailComponent,
    ConcatUserIdPipe,
  ],
  templateUrl: './good-deals-menu.component.html',
  styleUrls: ['./good-deals-menu.component.scss']
})
export class GoodDealsMenuComponent implements OnChanges,OnDestroy{
  @Input() parentMenu = 'menu_good_deals';
  @Input() dealArea: any;
  brand: Brand;
  deal: Deal;

  private unsubscribe$: Subject<boolean> = new Subject<false>();

  constructor(private affiliateService:AffiliateService) { }


  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.hasOwnProperty('dealArea')) {
      this.brand = (typeof this.dealArea.deal !== 'string') ? this.dealArea.deal.brand as Brand : null;
      this.deal = (typeof this.dealArea.deal !== 'string') ? this.dealArea.deal as Deal : null;
      if(this.dealArea.trackingLinks.length){
        this.affiliateService.getTrackingLinksInfo(this.dealArea.trackingLinks[0]).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next:(data)=>{
            this.dealArea.tinyUrl  = data?.tinyUrl
            this.dealArea.pixelIrl = data?.pixelUrl
          }
        })
      }
      
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
