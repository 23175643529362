import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class KzUiGuard  {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.enableKzUIRoute;
  }
}
