import { NgModule } from '@angular/core';
import { KzClickOutsideDirective } from './kz-click-outside.directive';

@NgModule({
  imports: [],
  declarations: [
    KzClickOutsideDirective,
  ],
  exports:  [
    KzClickOutsideDirective,
  ]
})
export class KzClickOutsideModule { }
