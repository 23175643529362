import { NgModule } from '@angular/core';
import { KzStickerComponent } from './kz-sticker/kz-sticker.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    KzStickerComponent,
  ],
  exports: [
    KzStickerComponent,
  ],
})
export class KzStickerModule {

}
