import {
  ConsoleLogTransporter,
  KOZIKAZA_LOG_TRANSPORTER,
  LevelLogTransporter,
  PipelineLogTransporter,
} from '@adeo/ngx-kozikaza-api';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { AppMissingTrans, AppModule } from './app.module';
import { AppComponent } from './app.component';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { QuillModule } from 'ngx-quill';
import QuillAutoDetectUrl from 'quill-auto-detect-url';
import { translateModuleLoader } from "./shared/services/http-loader.service";

const socketConfig: SocketIoConfig = { url: environment.API_SOCKET_ORIGIN, options: {
    path: '/chat/socket.io',
    transports: [
      'websocket'
    ],
    reconnectionAttempts: 2,
  }
};

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: translateModuleLoader,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTrans,
      },
    }),
    SocketIoModule.forRoot(socketConfig),
    QuillModule.forRoot({
      customModules: [{
        implementation: QuillAutoDetectUrl,
        path: 'modules/autoDetectUrl'
      }],
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: 'DEFAULT_COUNTRY',
      useFactory: (transferState: TransferState) => {
        return transferState.get(makeStateKey('DEFAULT_COUNTRY'), undefined);
      },
      deps: [TransferState]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: KOZIKAZA_LOG_TRANSPORTER,
      useFactory: () => new PipelineLogTransporter([
        new LevelLogTransporter(environment.LOG_LEVEL),
        new ConsoleLogTransporter({format: 'text'}),
      ]),
    },
  ],
})
export class AppBrowserModule {

  constructor() {
  }

}
