import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BlockCopyPasteDirective],
  exports: [BlockCopyPasteDirective]
})
export class BlockCopyPasteModule { }
