import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { CommunityService } from '../../../shared/services/community.service';
import { CommunityDisplayOptions, defaultCommunityDisplayOptions } from './community-display-options';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
    selector: '[appNgIfIsCommunity]'
})
export class IsCommunityDirective implements OnInit, OnDestroy {
    private subscription: Subscription;

    private _displayOptions: CommunityDisplayOptions = Object.assign({}, defaultCommunityDisplayOptions);

    private isCommunity: boolean;

    private isCommunityPreviousValue: boolean;

    @Input('appNgIfIsCommunity')
    set displayOptions(displayOptions: CommunityDisplayOptions) {
        this._displayOptions = Object.assign(Object.assign({}, defaultCommunityDisplayOptions), displayOptions);
        this.updateView();
    }

    get displayOptions(): CommunityDisplayOptions {
        return this._displayOptions;
    }

    // todo : implement stateFilter if needed
    // @Input() stateFilter: Array<CommunityStateOrigin>;

    constructor(
        private readonly communityService: CommunityService,
        private readonly elementRef: ElementRef,
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly cdr: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private readonly platformId: any,
    ) {
    }

    ngOnInit(): void {
        this.subscription = this.communityService.isCommunityChanges.pipe(
            distinctUntilChanged((prev, curr) => prev === curr)
        ).subscribe(isCommunity => {
            this.isCommunity = isCommunity.isCommunity;
            if (this.isCommunity !== this.isCommunityPreviousValue) {
                this.isCommunityPreviousValue = this.isCommunity;
                this.updateView();
            }
        });
    }

    private updateView() {
        if (!environment.ENABLE_I18N) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }

        this.viewContainer.clear();
        if ((this.displayOptions.forceServerSide && isPlatformServer(this.platformId)) ||
            (this.displayOptions.forceBrowserSide && isPlatformBrowser(this.platformId)) ||
            this.isCommunity === true) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}
