import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileLink'
})
export class ProfileLinkPipe implements PipeTransform {

  transform(value: string, args?: string): any {
    if (value) {
      const userPath = value.replace('/users/', '');
      return (args != null) ? ['/', 'profile', userPath, ...args.split('/')] : ['/', 'profile', userPath];
    }
    return value;
  }
}
