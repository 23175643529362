import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BlogInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Handle the blog API requests
    if (request.url.startsWith(environment.API_BLOG_ORIGIN)) {
      return next.handle(request.clone({setHeaders: {Authorization: `Basic ${environment.API_BLOG_ACCESS_TOKEN}`}}));
    }

    return next.handle(request);
  }
}
