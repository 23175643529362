import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postLink'
})
export class PostLinkPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const userPath = value.replace('/posts/', '');
    if (args != null) {
      return ['/post', userPath, args];
    } else {
      return ['/post', userPath];
    }
  }
}
