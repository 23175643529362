import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appGtmTrack]',
})
export class GtmTrackDirective implements OnChanges, AfterViewInit {

  @Input() appGtmTrack: string;

  @Input() trackChildren = true;

  @ViewChildren('parent') children: QueryList<ElementRef>;

  @Input('trackPrefix')  prefix = 'GTM_cta_';

  @Input('trackAdId') trackAdId: string;

  @Input('trackPartnerId') trackPartnerId: string;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId)) {
      if (changes.hasOwnProperty('appGtmTrack') && !changes.appGtmTrack.firstChange) {
        this.updateGtmTrackClasses();
      }
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.updateGtmTrackClasses();
    }
  }

  updateGtmTrackClasses() {
    // Remove all previous GTM classes
    this.removeClasses(this.elementRef.nativeElement);
    if (this.trackChildren === true) {
      const children = this.elementRef.nativeElement.querySelectorAll('*');
      children.forEach((child) => this.removeClasses(child));
    }

    // Append the current class
    this.addClass(this.elementRef.nativeElement);
    if (this.trackChildren === true) {
      const children = this.elementRef.nativeElement.querySelectorAll('*');
      // Set 2nd parameter to false to prevent populating adId & partnerId on child elements.
      children.forEach((child) => this.addClass(child, false));
    }
  }

  private removeClasses(nativeElement): void {
    nativeElement.classList.forEach((className) => {
      if (className.startsWith(this.prefix)) {
        this.renderer.removeClass(nativeElement, className);
      }
    });
    this.renderer.removeAttribute(nativeElement, 'adId');
    this.renderer.removeAttribute(nativeElement, 'partnerId');
  }

  private addClass(nativeElement, ischild = false): void {
    const className = `${ this.prefix }${ this.appGtmTrack }`.replace(new RegExp('/', 'g'), '');
    this.renderer.addClass(nativeElement, className);
    if (this.trackAdId && !ischild) {
      this.renderer.setAttribute(nativeElement, 'adId', this.trackAdId);
    }
    if (this.trackPartnerId && !ischild) {
      this.renderer.setAttribute(nativeElement, 'partnerId', this.trackPartnerId);
    }
  }
}
