import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kz-shimmer',
  template: ``,
  styleUrls: ['./kz-shimmer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzShimmerComponent {

  constructor() { }

}
