import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { defaultPhoneCode, PhoneCode, phoneCodes } from '../../models/auth/phone-codes';
import { KzIconsRegistryService } from '../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsArrowdown } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-arrowdown.icon';

@Component({
  selector: 'app-phone-code',
  templateUrl: './phone-code.component.html',
  styleUrls: ['./phone-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhoneCodeComponent implements OnInit {
  phoneCodes = phoneCodes;
  @Input() showCodes = false;
  @Input() style: 'register' | 'profile' = 'register';
  @Input() defaultCode = defaultPhoneCode;
  phoneCode: PhoneCode;
  @Input() countryPhoneCode: number;
  @Input() paddingVersion = 0;
  @Output() selectedCode: EventEmitter<{phoneCode: PhoneCode, firstTime: boolean}> = new EventEmitter<{phoneCode: PhoneCode, firstTime: boolean}>();
  @Input() smallInput = false;

  @ViewChild('actionCTA', {read: ElementRef, static: true}) exception: ElementRef;

  constructor(
    private kzIconsRegistryService: KzIconsRegistryService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsArrowdown]);
  }

  ngOnInit(): void {
    this.phoneCode = (this.countryPhoneCode) ?
        this.phoneCodes.find((code) => (code.dial_code === `+${this.countryPhoneCode}`)) :
        this.defaultCode;
    this.selectedCode.emit({phoneCode: this.phoneCode, firstTime: true});
  }
}
