import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, Output } from '@angular/core';
import { ModalOptions } from './modal-options';
import { DOCUMENT } from '@angular/common';
import { modalEntranceAnimation } from '../../animations/entrances.animation';
import { KzIconsRegistryService } from '../icon/kz-icons-registry.service';
import { kzIconsCross } from '../icon/generated-icons/kzIcons-cross.icon';
import { kzIconsArrowleft } from '../icon/generated-icons/kzIcons-arrowleft.icon';

@Component({
  selector: 'kz-modal',
  templateUrl: './kz-modal.component.html',
  styleUrls: ['./kz-modal.component.scss'],
  host: {
    '[class]': '(options.windowClasses ? options.windowClasses : "")',
    '[class.backdrop]': 'options.backdrop',
    '[class.xs]': 'options.size == "xs"',
    '[class.sm]': 'options.size == "sm"',
    '[class.md]': 'options.size == "md"',
    '[class.lg]': 'options.size == "lg"',
    '[class.xl]': 'options.size == "xl"',
    '[class.xxl]': 'options.size == "xxl"',
    '[class.xxxl]': 'options.size == "xxxl"',
    '[class.xxxxl]': 'options.size == "xxxxl"',
    '[class.fullscreen]': 'options.size == "fullscreen"',
    '[class.register]': 'options.size == "register"',
    '[class.mobile-full-screen]': 'options.mobileFullSize',
  },
  animations: [modalEntranceAnimation]
})
export class KzModalComponent implements OnDestroy {
  @Input()
  options: ModalOptions;

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('@modalEntranceAnimation') get modalEntranceAnimation(): boolean {
    return true;
  }

  constructor(
    @Inject(DOCUMENT) protected _document: Document,
    protected kzIconsRegistryService: KzIconsRegistryService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsCross, kzIconsArrowleft]);
    this._document.body.style.overflow = 'hidden';
  }

  ngOnDestroy() {
    this._document.body.style.overflow = 'auto';
  }

  close() {
    this.onClose.emit(true);
  }
}
