export const routePtTranslations: {[key: string]: string} = {
  '': '',
  'modal': 'modal',
  'not_found': 'not_found',
  'unauthorized': 'unauthorized',
  'error': 'error',
  'legal-notice': 'avisos-legais',
  'terms-of-service': 'termos-de-utilizacao',
  // 'about': 'about',
  'terms-of-sales': 'termos-e-condicoes-gerais-de-venda',
  // 'kazacafe': 'kazacafe',
  // 'follow':'follow',
  // 'feed':'feed',
  // 'followed':'followed',
  // 'followers':'followers',
  // 'suggest':'suggest',
  // 'explore':'explore',
  // 'post': 'post',
  'user': 'user',
  'register': 'register',
  'login': 'login',
  'logout': 'logout',
  'builder': 'builder',
  'kazaplan': 'kazaplan',
  '3Dplan': '3Dplan',
  // 'story': 'story',
  // 'gallery': 'gallery',
  // 'ideas': 'ideas',
  // 'kazabook': 'kazabook',
  'kaza': 'kaza',
  'edit': 'edit',
  'view': 'view',
  'new': 'new',
  'kazaplan_media': 'kazaplan_media',
  '360view': '360view',
  '3d-home-design-software': 'planta-casa-3d',
  'bathroom': 'casa-de-banho',
  // 'electrical-plan': 'electrical-plan',
  'profile': 'profile',
  'details': 'details',
  'kazafriends': 'kazafriends',
  'infos': 'infos',
  'settings': 'settings',
  'kazas': 'kazas',
  'notifications': 'notifications',
  // 'coaching': 'coaching',
  // 'projects': 'projects',
  // 'customers-projects': 'customers-projects',
  // 'wallet': 'wallet',
  // 'gift-cards': 'gift-cards',
  'all': 'all',
  'unread': 'unread',
  'messages': 'messages',
  // 'inspiration': 'inspiration',
  'kitchen': 'cozinha',
  // 'dressing': 'dressing',
  // 'outdoor-design': 'outdoor-design',
  // 'terrace': 'terrace',
  // 'garden': 'garden',
  // 'photos': 'photos',
  'id': 'id',
  '3d-plan': '3d-plan',
  'connexion_wanaplan': 'connexion_wanaplan',
  // 'pro': 'pro',
  // 'decoration': 'decoration',
  // 'architect': 'architect',
  // 'interior-designer': 'interior-designer',
  // 'interior-decorator': 'interior-decorator',
  // 'influencer': 'influencer',
  // 'other': 'other',
  // 'become-partner': 'become-partner',
  // 'contest': 'contest',
  // 'rules': 'rules',
  // 'quotatis': 'quotatis',
  // 'comweb': 'comweb',
  'step1': 'step1',
  'step2': 'step2',
  'home': 'home',
  'activity': 'activity',
  // 'service': 'service',
  // 'construction-estimate': 'construction-estimate',
  'auth': 'auth',
  'authentification': 'authentification',
  'signup': 'signup',
  // 'picture': 'picture',
  // 'before-after': 'before-after',
  // 'question': 'question',
  // 'event': 'event',
  // 'survey': 'survey',
  // 'favorites': 'favorites',
  // 'decoration-consultant': 'decoration-consultant',
  // 'medias': 'media',
  // 'activation-box': 'activation-box',
  // 'decoration-coaching-box': 'decoration-coaching-box',
  // 'deco-offers': 'deco-offers',
  // 'box': 'box',
  // 'memories': 'memories',
  // 'email-verification': 'email-verification',
  // 'confirmation': 'confirmation',
  // 'children-room': 'children-room',
  // 'chat': 'chat',
  // 'exchange-secure-payment': 'exchange-secure-payment',
  // 'interior-architect': 'interior-architect',
  // 'find-a-professional': 'find-a-professional',
  // 'design-by-a-professional': 'design-by-a-professional',
  // 'home-garden-deals': 'home-garden-deals',
}
