import { NgModule } from '@angular/core';
import { KzCardComponent } from './kz-card/kz-card.component';
import { KzAnimationsModule } from '../../animations/kz-animations.module';
import { CommonModule } from '@angular/common';
import { KzCardHeaderComponent } from './kz-card-header/kz-card-header.component';
import { KzCardBodyComponent } from './kz-card-body/kz-card-body.component';

@NgModule({
  imports: [
    CommonModule,
    KzAnimationsModule,
  ],
  declarations: [
    KzCardComponent,
    KzCardHeaderComponent,
    KzCardBodyComponent,
  ],
  exports: [
    KzCardComponent,
    KzCardHeaderComponent,
    KzCardBodyComponent,
  ],
})
export class KzCardModule {

}
