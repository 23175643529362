import { NgModule } from '@angular/core';
import { KzButtonModule } from './button/kz-button.module';
import { KzCheckBoxModule } from './checkbox/kz-checkbox.module';
import { KzFieldModule } from './field/kz-field.module';
import { KzRadioModule } from './radio/kz-radio.module';
import { KzTextBoxModule } from './textbox/kz-textbox.module';
import { KzLabelModule } from './label/kz-label.module';
import { KzSelectModule } from './select/kz-select.module';
import { KzErrorModule } from './error/kz-error.module';
import { KzFieldInfoModule } from './info/kz-field-info.module';
import { KzCheckboxToggleModule } from './checkbox-toggle/kz-checkbox-toggle.module';
import { KzInputRangeModule } from './range/kz-input-range.module';

const FORM_SUB_MODULES = [
  KzButtonModule,
  KzCheckBoxModule,
  KzCheckboxToggleModule,
  KzErrorModule,
  KzFieldModule,
  KzFieldInfoModule,
  KzLabelModule,
  KzRadioModule,
  KzSelectModule,
  KzTextBoxModule,
  KzInputRangeModule,
];

@NgModule({
  imports: [
    ...FORM_SUB_MODULES,
  ],
  exports: [
    ...FORM_SUB_MODULES,
  ],
})
export class KzFormModule {

}
