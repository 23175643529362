import { NgModule } from '@angular/core';
import { KzTextboxComponent } from './kz-textbox.component';

@NgModule({
  imports: [
  ],
  declarations: [
    KzTextboxComponent,
  ],
  exports: [
    KzTextboxComponent,
  ],
})
export class KzTextBoxModule {

}
