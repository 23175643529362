import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  TemplateRef,
  Type,
  ViewRef,
} from '@angular/core';

export class ContentRef {
  readonly viewRef?: ViewRef;

  readonly nodes: any[];

  readonly componentRef?: ComponentRef<any>;

  constructor(
    _nodes: any[] = [],
    _viewRef?: ViewRef,
    _componentRef?: ComponentRef<any>,
  ) {
    this.nodes = _nodes;
    this.viewRef = _viewRef;
    this.componentRef = _componentRef;
  }
}

export const createContentRef = (
  content: string | TemplateRef<any> | Type<any>,
  context: any,
  applicationRef: ApplicationRef,
  document: Document,
  componentFactoryResolver: ComponentFactoryResolver,
  injector: Injector,
): ContentRef => {
  if (!content) {
    return new ContentRef();
  }

  if (content instanceof TemplateRef) {
    return createContentRefFromTemplateRef(content, context, applicationRef);
  }

  if (typeof content === 'string') {
    return createContentRefFromString(content, document);
  }

  return createContentRefFromComponent(
    content,
    componentFactoryResolver.resolveComponentFactory(content),
    injector,
    applicationRef,
  );
};

export const createContentRefFromTemplateRef =(
  content: TemplateRef<any>,
  context: any,
  applicationRef: ApplicationRef,
): ContentRef => {
  const viewRef = content.createEmbeddedView(context);
  applicationRef.attachView(viewRef);

  return new ContentRef(
    [viewRef.rootNodes],
    viewRef,
  );
};

export const createContentRefFromString = (
  content: string,
  document: Document,
): ContentRef => {
  return new ContentRef([[document.createTextNode(`${ content }`)]]);
};

export const createContentRefFromComponent = <T>(
  component: Type<T>,
  componentFactory: ComponentFactory<T>,
  injector: Injector,
  applicationRef: ApplicationRef,
): ContentRef => {
  const componentRef = componentFactory.create(injector);
  applicationRef.attachView(componentRef.hostView);

  return new ContentRef(
    [[componentRef.location.nativeElement]],
    componentRef.hostView,
    componentRef,
  );
};
