import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class UserAgentService {

  private ua: string;
  constructor(@Inject(PLATFORM_ID) platformId: any) {
    this.ua = isPlatformBrowser(platformId) ? window.navigator.userAgent : '';
  }

  public get userAgent(): string {
    return this.ua;
  }

  public userAgentIsMobile(): boolean {
    return this.isMobile();
  }

  public isAndroid(): boolean {
    return !!this.ua.match(/Android/i);
  }

  public isBlackBerry(): boolean  {
    return !!this.ua.match(/BlackBerry/i);
  }

  public isiOS(): boolean  {
    return !!this.ua.match(/iPhone|iPad|iPod/i);
  }

  public isOpera(): boolean {
    return !!this.ua.match(/Opera Mini/i);
  }

  public isWindows(): boolean {
    return !!this.ua.match(/IEMobile/i) || !!this.ua.match(/WPDesktop/i);
  }

  public isMobile(): boolean {
    return this.isAndroid() || this.isBlackBerry() || this.isiOS() || this.isOpera() || this.isWindows() ;
  }
}
