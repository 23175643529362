import { NgModule } from '@angular/core';
import { KzButtonComponent } from './kz-button/kz-button.component';
import { KzAnimationsModule } from '../../../animations/kz-animations.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    KzAnimationsModule,
  ],
  declarations: [
    KzButtonComponent,
  ],
  exports: [
    KzButtonComponent,
  ],
})
export class KzButtonModule {

}
