import { Directive, Output, EventEmitter, Renderer2, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { ProfileLinkPipe } from '../pipes/link/profile-link.pipe';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UserStoreService } from '../../shared/services/state-management/user-store.service';
import { User, UserRole } from '@adeo/ngx-kozikaza-api';
import { AuthService } from '../../modules/auth/auth.service';
import { ModalService } from '../modals/modals.service';
import { KzModalService } from '../../kz-ui/ui/modal/kz-modal.service';
import { catchError, tap } from 'rxjs/operators';

/*
* Use like this :
* - simple way (get access value) :
*         (appAccessControl)="isAllowed=$event"
* - click (prevent click and open login popin) :
*         (click.appAccessControl)="isAllowed=$event && toggleLike()"
*         - Options :
*           - [accessControlRedirect] = redirect path after login
* */
@Directive({
  selector: '[appAccessControl]',
  standalone: true,
})
export class AccessControlDirective implements OnInit, OnDestroy {

  @Input() appAccessControlException: UserRole[] = [];

  @Output('appAccessControl')
  protected accessControl = new EventEmitter<boolean>();

  protected isAllowed = false;
  protected userSubscription: Subscription;

  protected isNotAllowedByHisRole = false;

  protected userLogged: User;

  constructor(
    protected userStoreService: UserStoreService,
    protected toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    // Check user rights
    this.checkUser();
  }

  ngOnDestroy() {
    if (this.userSubscription && !this.userSubscription.closed) {
      this.userSubscription.unsubscribe();
    }
  }

  protected checkUser() {
    this.userSubscription = this.userStoreService.userStore.pipe(
      catchError((error) => {
        this.isAllowed = false;
        this.toastrService.warning($localize`:@@toast.error_while_retrieving_current_user:An error occurred while loading the current user`);
        this.accessControl.emit(this.isAllowed);
        return throwError(error);
      }),
      tap((user) => {
        this.isAllowed = !!user;
        this.userLogged = user;
        if (this.isAllowed && this.appAccessControlException.length) {
          user.roles.forEach((role) => {
            if ((this.appAccessControlException.map((except) => UserRole[except] as string)).includes(role)) {
              this.isAllowed = false;
              this.isNotAllowedByHisRole = true;
            }
          });
        }
        this.accessControl.emit(this.isAllowed);
      }),
    ).subscribe();
  }
}

@Directive({
  // tslint:disable-next-line
  selector: '[click.appAccessControl]',
  standalone: true,
  providers: [
    ModalService,
    KzModalService,
  ]
})
export class ClickAccessControlDirective extends AccessControlDirective implements OnInit, OnDestroy {

  @Input() accessControlRedirect: string = null;
  @Input() popinValidationStep = false;

  @Output('click.appAccessControl') clickAccessControl = new EventEmitter<boolean>();
  unsubscribe;

  protected currentRoute = '';

  protected subscriptionDialog: Subscription;

  constructor(
    protected userStoreService: UserStoreService,
    protected toastrService: ToastrService,
    protected renderer: Renderer2,
    protected element: ElementRef,
    protected route: ActivatedRoute,
    protected router: Router,
    private _modalService: ModalService,
    private profilePipe: ProfileLinkPipe,
    private localizeRouterService: LocalizeRouterService,
    private authService: AuthService,
  ) {
    super(userStoreService, toastrService);
  }

  ngOnInit() {
    // Get current route for redirection
    // Todo : test code and replace _routerState
    this.currentRoute = (this.accessControlRedirect) ? this.accessControlRedirect : this.route.snapshot[`_routerState`].url;

    // Check user rights
    this.checkUser();

    // Listen to click
    this.listenClickEvent(this.popinValidationStep);
  }

  listenClickEvent(popinVStep: boolean = false) {
    this.unsubscribe = this.renderer.listen(this.element.nativeElement, 'click', event => {
      this.clickAccessControl.emit(this.isAllowed);
      if (!this.isAllowed) {
        event.preventDefault();
        event.stopPropagation();
        if (this.isNotAllowedByHisRole) {
          const profileLink = this.profilePipe.transform(this.userLogged['@id']).join('/');
          this.toastrService.error($localize`:@@toast.restricted_access:Your account is in <strong> restricted access </strong>. For more information, go to your <a href="${profileLink}:profileLink:"> profile </a>`,
            '', { enableHtml : true, tapToDismiss : false });

        } else {
          //  open popin
          if (popinVStep) {
            const lineA = $localize`:@@popin.yesNoDialog.content.notLoggedAlert:You need to be logged in to continue this action.`;
            const lineB = $localize`:@@popin.yesNoDialog.content.notLoggedAlert.b:Do you want to log in now?`;
            this.subscriptionDialog = this._modalService.openYesNoDialogOnClosed({
              title: $localize`:@@popin.yesNoDialog.title.notLoggedAlert:Login required`,
              content: `${lineA}<br>${lineB}`
            }).pipe(
              tap((result) =>  this.authService.redirectToLoginStep({ state: { no_scroll: true }})),
            ).subscribe();
          } else {
            this.authService.redirectToLoginStep({ queryParams : { bannedAction: true }});
          }
        }
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
