import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { convertIRItoID, User } from '@adeo/ngx-kozikaza-api';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UserStoreService } from '../../shared/services/state-management/user-store.service';
import { CommunityService } from '../../shared/services/community.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthPopinService {
  popinClosed: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private readonly localizeRouterService: LocalizeRouterService,
    private userStoreService: UserStoreService,
    private readonly communityService: CommunityService,
    private route: ActivatedRoute,
  ) {
  }

  get activatedRoute(): ActivatedRouteSnapshot {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }

  close(pushEvent = false): void {
    if (pushEvent) {
      this.popinClosed.next(true);
    }
    this.router.navigate(['', {outlets: {auth: null}}], {relativeTo: this.route.parent}).catch(() => {});
  }

  closePopinAndRedirect(user?: User, isCommunity?: boolean): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > closePopinAndRedirect > ', 'color:pink', {...user}, isCommunity);
    }
    if (!user) {
      user = this.userStoreService.user;
    }
    if (!isCommunity) {
      isCommunity = this.communityService.currentUserIsCommunity(user, this.userStoreService.userActiveKaza);
    }

    if (environment.env === 'e2e') {
      console.log('%c > closePopinAndRedirect > B', 'color:pink', {...user}, isCommunity, this.activatedRoute.data.redirectOnLogin);
      console.log('%c > closePopinAndRedirect > Kaza > test', 'color:pink', {...this.userStoreService.userActiveKaza},
          this.userStoreService.userActiveKaza ? this.userStoreService.userActiveKaza['test'] : 'no test');
    }
    if (this.activatedRoute.data.redirectOnLogin) {
      const redirectPath = (isCommunity === true) ?
        this.localizeRouterService.translateRoute([this.activatedRoute.data.redirectOnLogin]) as string[] :
        this.localizeRouterService.translateRoute(['kaza', convertIRItoID(user.activeKaza['@id']), '3Dplan']) as string[];
      if (environment.env === 'e2e') {
        console.log('%c > closePopinAndRedirect > REDIRECT', 'color:pink', redirectPath);
      }
      this.router.navigate(['', {outlets: {auth: null}}], {relativeTo: this.route.parent}).then(
          () => {
            if(!this.route.snapshot.data.callBackUrlRedirected) {
              this.router.navigate([...redirectPath, {outlets: {auth: null}}], {relativeTo: this.route.parent});
            }
            return true;
          }
      ).catch(() => {});
    } else {
      if (environment.env === 'e2e') {
        console.log('%c > closePopinAndRedirect > CLOSE', 'color:pink');
      }
      this.close();
      return true;
    }
  }
}
