import { Pipe, PipeTransform } from '@angular/core';
import { Professional, subRProperty, User } from '@adeo/ngx-kozikaza-api';

@Pipe({
  name: 'getUserOrProProperty'
})
export class GetUserOrProPropertyPipe implements PipeTransform {

  transform(entity: User | Professional, ...args: Array<'name' | 'username' | 'avatar' | 'avatarFile'>): string {
    if (!entity || !args[0]) {
      return null;
    }
    switch (entity['@type']) {
      case 'Professional':
        const pro = entity as Professional;
        if (args[0] === 'name' || args[0] === 'username') {
          return pro.brand;
        } else if (args[0] === 'avatar' || args[0] === 'avatarFile') {
          return subRProperty(pro, ['avatar', 'contentUrl'], '/assets/images/register/profile_kzkz.svg');
        }
        break;
      case 'User':
        const user = entity as User;
        if (args[0] === 'username') {
          return user.username;
        } else if (args[0] === 'name') {
          return (user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}`.trim() : user.username;
        } else if (args[0] === 'avatar') {
          return subRProperty(user, ['avatar', 'thumbs', 'small'], '/assets/images/register/profile_kzkz.svg');
        } else if (args[0] === 'avatarFile') {
          return subRProperty(user, ['avatar', 'filePath'], '/assets/images/register/profile_kzkz.svg');
        }
        break;
    }

    return null;
  }

}
