import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import cacheBusting from "../../../../i18n-cache-busting.json";
import { TranslateLoader } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class HttpLoaderService implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    const path = `/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`;
    return this.http.get(path);
  }
}

export const translateModuleLoader = { provide: TranslateLoader, useClass: HttpLoaderService };
