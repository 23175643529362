import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { RadioContext } from './radio-options';

@Component({
  selector: '[kzRadio]',
  exportAs: 'kzRadio',
  host: {
    '[class]': '"kz-radio" + (classes ? " " + classes : "")',
  },
  template: '<ng-content></ng-content>',
  styleUrls: ['./kz-radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzRadioComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _radioContext: RadioContext = 'default';

  @Input()
  set radioContext(type: RadioContext) {
    this._radioContext = type;
  }

  get radioContext(): RadioContext {
    return this._radioContext;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.radioContext && this.radioContext !== 'default') {
      this._classes += ` kz-radio--${ this.radioContext }`;
    }
  }
}
