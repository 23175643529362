import { Inject, Injectable, LOCALE_ID, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformServer, LocationStrategy } from '@angular/common';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HreflangStrategy } from '../../models/hreflang-strategy';
import { LocalizedRouterService } from '../localized-router.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SEO_CONST } from '../../../utils/const/seo';
import { CookieService } from 'ngx-cookie';
import { RoutesService } from '../routes/routes.service';
import { ImageResizeService, PresetLogo } from '../../components/img-resize/image-resize.service';
import { getTranslation } from '../../../utils/const/translation';
import { routePtTranslations } from "../../../../assets/i18n/route-pt";
import { of } from "rxjs/internal/observable/of";
import { take, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private renderer: Renderer2;

  private $currentMetas: BehaviorSubject<Metas> = new BehaviorSubject<Metas>(null);

  private ptRoutes: { [key: string]: string} = routePtTranslations;

  constructor(
    private title: Title,
    private meta: Meta,
    private rendererFactory: RendererFactory2,
    private locationStrategy: LocationStrategy,
    private router: Router,
    private routesService: RoutesService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly localizedRouterService: LocalizedRouterService,
    private readonly cookieService: CookieService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  boot() {
    this.routesService.eventNavigationEnd.subscribe(() => {
      if (environment.ENABLE_I18N) {
        this.handleHreflangStrategy();
      }
      if (this.routesService.routeHasAnOutlet()) {
        this.initFollow('noindex, nofollow');
      }
    });
  }

  private getCurrentHreflangStrategy(): HreflangStrategy {
    let tmpRoute = this.route.snapshot;
    while (tmpRoute) {
      if (tmpRoute.data.hasOwnProperty('hreflangStrategy')) {
        return tmpRoute.data.hreflangStrategy;
      }

      tmpRoute = tmpRoute.firstChild;
    }



    return HreflangStrategy.None;
  }

  private handleHreflangStrategy() {
    switch (this.getCurrentHreflangStrategy()) {
      case HreflangStrategy.Auto:
        this.addTag({
          rel: 'alternate',
          href: environment.REDIRECT_URL + this.getUrl(),
          hreflang: this.localizeRouterService.parser.currentLang+ this.addLanguageCode(this.localizeRouterService.parser.currentLang),
        }, true);
        const urlTree = this.router.parseUrl(this.router.url);
        const urlWithoutParamsArray = (urlTree.root.children.primary) ?
          urlTree.root.children.primary.segments.map(it => it.path) : [];
        environment.ALLOWED_LANGUAGES
          .filter((language) => language !== this.localizeRouterService.parser.currentLang)
          .map((language) => {
            this.localizedRouterService.translatePath(urlWithoutParamsArray.join('/'), language).subscribe((path) => {
              this.addTag({
                rel: 'alternate',
                href: environment.REDIRECT_URL + (language === 'fr' ? '' : `/${language}`) + `/${path}` ,
                hreflang: language,
              }, true);
            });
          });

        // alternate for portugal
        if (!!environment.REDIRECT_URL_PT) {
          const englishPath$: Observable<string> = (this.locale === 'en') ? of(this.getUrl()) : this.localizedRouterService.translatePath(this.getUrl(), 'en');
          englishPath$.pipe(
            take(1),
            tap((englishPath) => {
              const sptUrl = this.removeLanguages(englishPath, environment.ALLOWED_LANGUAGES);
              const ptUrlWithoutParams = sptUrl.split('/').filter((url) => url.length > 0).map((url) => this.ptRoutes[url] ? this.ptRoutes[url] : 'undefined').join('/');
              // create alternate for portugal only if portuguese route exists
              if (!ptUrlWithoutParams.includes('undefined')) {
                this.addTag({
                  rel: 'alternate',
                  href: environment.REDIRECT_URL_PT + `/${ptUrlWithoutParams}`,
                  hreflang: 'pt-PT',
                }, true);
              }
            }),
          ).subscribe();
        }
        break;
      case HreflangStrategy.None:
        break;
      default:
        break;
    }
  }
  private removeLanguages(input: string, languages: string[]): string {
    const substringsToRemove = languages.map(lang => `/${lang}/`);
    return substringsToRemove.reduce((acc, substr) => acc.replace(substr, ''), input);
}
  private addLanguageCode(lang:string):string {
   if(lang !== 'fr' && lang !=='en' && lang !=='es'){
    return  '-'+lang.toLocaleUpperCase()
   }
   return '';
  }
  init(parameters: any, prefetchApi: boolean = true, canonical: boolean = true): void {

    if (parameters.title) {
      this.initTitle(parameters.title);
    }
    // if (parameters.description) {
    this.initDescription((parameters.description) ? parameters.description : '');
    // }
    if (parameters.image) {
      this.initImg(parameters.image);
    }
    if (canonical) {
      const language = this.localizeRouterService.parser.currentLang;
      const path = parameters.customCanonicalPath ?
        environment.REDIRECT_URL + (language === 'fr' ? '/' : `/${language}/`) +
        (this.localizeRouterService.translateRoute(parameters.customCanonicalPath) as string[]).join('/') :
        environment.REDIRECT_URL + this.getUrl();

      this.addTag({
        rel: 'canonical',
        href: path
      }, false, null, true);
    }
    if (prefetchApi) {
      this.addTag({
        rel: 'preconnect',
        href: environment.API_SOCKET_ORIGIN,
        crossorigin: '',
      }, true, 'link');
      this.addTag({
        rel: 'preconnect',
        href: 'https://cdn.cookielaw.org',
        crossorigin: '',
      }, true, 'link');
      this.addTag({
        rel: 'preconnect',
        href: environment.URL_RESIZE,
        crossorigin: '',
      }, true, 'link');
    }
  }

  public getUrl(): string {
    const url = this.locationStrategy.path();
    const urlArray = url.split('?');

    return urlArray[0];
  }

  setMetas(metas: Metas): void {
    const urlTree = this.router.parseUrl(this.router.url);
    const urlWithoutParams = (urlTree.root.children.primary) ?
      urlTree.root.children.primary.segments.map(it => it.path).join('/') : '';
    const titleTranslated = (metas.title) ? metas.title : (metas.titleKey) ?
      getTranslation(metas.titleKey) : SEO_CONST.META_DEFAULT_TITLE;
    const descriptionTranslated = (metas.description) ? metas.description :
      (metas.descriptionKey) ? getTranslation(metas.descriptionKey) : '';
    const img = (metas.image) ? metas.image
      : `${ImageResizeService.resizeMediaPreset({imgPath: '/assets/images/header/logo.png', presets: new PresetLogo()})}`;
    this.init({
      title: titleTranslated,
      description: descriptionTranslated,
      image: img,
      // customCanonicalPath: metas.customCanonicalPath,
      customCanonicalPath: undefined,
      isHome: metas.isHome
    });
    const ogMeta = {
      type: 'website',
      url: `${ environment.REDIRECT_URL }/${ urlWithoutParams }`,
      title: titleTranslated,
      description: descriptionTranslated,
      site_name: `${ environment.REDIRECT_URL }`,
      locale: this.locale,
      page_id: `${ environment.FACEBOOK_PAGE_ID }`,
      'fb:pages': `${ environment.FACEBOOK_PAGE_ID }`
    };
    this.initOgMeta(ogMeta);
    this.$currentMetas.next(ogMeta);
  }

  get currentMetas(): Observable<Metas> {
    return this.$currentMetas.asObservable();
  }

  initOgMeta(metas: object): void {
    Object.keys(metas).forEach((key) => {
      this.updateTag(
        key,
        metas[key],
      );
    });
  }

  initTitle(title: string): void {
    this.title.setTitle(title);
    this.updateTag(
      'og:title',
      title,
    );
  }

  initImg(imgPath: string): void {
    this.updateTag(
      'image',
      imgPath,
    );
    this.updateTag(
      'og:image',
      imgPath,
    );
  }

  initFollow(params: string): void {

    if (this.routesService.routeHasAnOutlet() || (environment.env !== 'prod' && !this.cookieService.get('kozikaza_enable_seo'))) {
      params = 'noindex, nofollow';
    }

    this.updateTag(
      'robots',
      params,
    );
  }

  initDescription(description: string): void {
    this.updateTag(
      'description',
      description,
    );
    this.updateTag(
      'og:description',
      description,
    );
  }

  updateLang(lang: string): void {
    this.renderer.setAttribute(this.document.querySelector('html'), 'lang', lang);
  }

  updateTag(name, content) {
    if (isPlatformServer(this.platformId)) {
      const metaEl = this.document.querySelectorAll('meta[property="' + name + '"], meta[name="' + name + '"]');
      if (metaEl.length > 0) {
        this.renderer.setAttribute(metaEl[0], 'content', content);
      } else {
        this.meta.addTag({name, content});
      }
    } else {
      this.meta.updateTag({name, content});
    }
  }

  addTag(tag: LinkDefinition, onlySSR: boolean = false, beforeElementTag: string = 'title', replaceIfExists: boolean = false) {
    if (!onlySSR || (onlySSR && isPlatformServer(this.platformId))) {
      const link = this.renderer.createElement('link');
      const head = this.document.head;
      const elTag = this.document.getElementsByTagName(beforeElementTag);
      const positionEl = elTag[0]
        ? (beforeElementTag === 'title' ? elTag[0].nextElementSibling.nextElementSibling : elTag[0])
        : null;
      let existingTag: any = null;

      if (replaceIfExists) {
        for (const prop in tag) {
          if (prop === 'rel' || prop === 'charset' || prop === 'href') {
            existingTag = this.document.querySelectorAll(`[${prop}="${tag[prop]}"]`);

            if (prop === 'rel' && tag[prop] === 'canonical') {
              break;
            }
          }
        }
      }

      Object.keys(tag).forEach((prop: string) => {
        return this.renderer.setAttribute(link, prop, tag[prop]);
      });

      // replace tag
      if (existingTag && existingTag[0]) {
        this.renderer.insertBefore(head, link, existingTag[0]);
        this.renderer.removeChild(head, existingTag[0]);

      } else if (positionEl) {
        // insert before
        this.renderer.insertBefore(head, link, positionEl);
      } else {
        // append to head
        this.renderer.appendChild(head, link);
      }
    }
  }

  preloadFont(fontName: string) {
    this.addTag({rel: 'preload', as: 'font', type: 'font/woff2', href: `/assets/fonts/${fontName}/font.woff2`, crossorigin: ''}, false, 'title', true);
  }
}

export interface Metas {
  title?: string;
  description?: string;
  titleKey?: string;
  descriptionKey?: string;
  image?: string;
  customCanonicalPath?: string;
  isHome?: boolean;
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
