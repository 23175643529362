import { animate, AnimationTriggerMetadata, group, query, state, style, transition, trigger } from '@angular/animations';


/*export const layerAnimation: AnimationTriggerMetadata =
  trigger('layerAnimation', [
    state('', style({transform: 'translateY(-100%)'})),
    transition(':enter', [
      group([
        query(':self', [
          style({opacity: 0, transform: 'translateY(-100%)'}),
          animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateY(0)' }))
        ]),
        query('.child-animate', [
          style({opacity: 0, transform: 'translateY(-100%)'}),
          animate('{{timeChild}} {{delayChild}} ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
        ])
      ])
    ], {params: {time: '300ms', delay: '0ms', timeChild: '150ms', delayChild: '300ms'}}),
    transition(':leave', [
      group([
        query(':self', [
          animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateY(-100%)' }))
        ])
      ])
    ], {params: {timeLeave: '300ms', delayLeave : '0ms'}})
  ]);*/
/*
* Animate a layer with 'Slide from Top effect' applied to host element, and a 'Slide from Top effect' applied to .child-animate element
* */
export const layerAnimation: AnimationTriggerMetadata =
    trigger('layerAnimation', [
        state('closed', style({transform: 'translateY(-100%)'})),
        transition('closed => open', [
            group([
                query(':self', [
                    style({opacity: 0, transform: 'translateY(-100%)'}),
                    animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateY(0)' }))
                ]),
                query('.child-animate', [
                    style({opacity: 0, transform: 'translateY(-100%)'}),
                    animate('{{timeChild}} {{delayChild}} ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
                ])
            ])
        ], {params: {time: '300ms', delay: '0ms', timeChild: '150ms', delayChild: '300ms'}}),
        transition('closed => open', [
            group([
                query(':self', [
                    animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateY(-100%)' }))
                ])
            ])
        ], {params: {timeLeave: '300ms', delayLeave : '0ms'}})
    ]);
/*
* Animate a layer with Slide from Top effect applied to host element
* */
export const layerAnimationB: AnimationTriggerMetadata =
    trigger('layerAnimationB', [
        state(':enter', style({transform: 'translateY(-100%)'})),
        transition(':enter', [
            group([
                query(':self', [
                    style({opacity: 0, transform: 'translateY(-100%)'}),
                    animate('{{time}} {{delay}} ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
                ])
            ])
        ], {params: {time: '200ms', delay: '0ms'}}),
        transition(':leave', [
            group([
                query(':self', [
                    animate('{{timeLeave}} {{delayLeave}} ease-in-out', style({ opacity: 0, transform: 'translateY(-100%)' }))
                ])
            ])
        ], {params: {timeLeave: '100ms', delayLeave : '0ms'}})
    ]);

/*
* Animate a layer with Slide from Top effect applied to .child-animate element
* */
export const layerAnimationC: AnimationTriggerMetadata =
    trigger('layerAnimationC', [
        state(':enter', style({transform: 'translateY(-100%)'})),
        transition(':enter', [
            group([
                query('.child-animate', [
                    style({opacity: 0, transform: 'translateY(-100%)'}),
                    animate('{{timeChild}} {{delayChild}}', style({ opacity: 1, transform: 'translateY(0)' }))
                ], { optional: true })
            ])
        ], {params: {timeChild: '300ms', delayChild: '0ms'}}),
        transition(':leave', [
            group([
                query('.child-animate', [
                  animate('{{timeLeaveChild}} {{delayLeaveChild}}', style({ opacity: 0, transform: 'translateY(-100%)'  }))
                ], { optional: true })
            ])
        ], {params: {timeLeaveChild: '300ms', delayLeaveChild: '0ms'}})
    ]);
export const layerAnimationD: AnimationTriggerMetadata =
  trigger('layerAnimationD', [
    state('closed', style({transform: 'translateY(-100%)'})),
    transition('closed => open', [
      group([
        query(':self', [
          style({opacity: 0, transform: 'translateY(-100%)'}),
          animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateY(0)' }))
        ]),
      ])
    ], {params: {time: '300ms', delay: '0ms', timeChild: '150ms', delayChild: '300ms'}}),
    transition('closed => open', [
      group([
        query(':self', [
          animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateY(-100%)' }))
        ])
      ])
    ], {params: {timeLeave: '300ms', delayLeave : '0ms'}})
  ]);

/*
* Animate a layer with Slide from right effect applied to host element
* */
export const layerSlideFromRightAnimation: AnimationTriggerMetadata =
    trigger('layerSlideFromRightAnimation', [
        state('closed', style({transform: 'translateX(110%)'})),
        transition('closed => open', [
            group([
                query(':self', [
                    // style({opacity: 0, transform: 'translateX(110%)'}),
                    animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateX(0)' }))
                ])
            ])
        ], {params: {time: '200ms', delay: '0ms'}}),
        transition('open => closed', [
            group([
                query(':self', [
                    animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateX(110%)' }))
                ])
            ])
        ], {params: {timeLeave: '200ms', delayLeave : '0ms'}})
    ]);
export const layerSlideFromRightAnimationB: AnimationTriggerMetadata =
    trigger('layerSlideFromRightAnimationB', [
        state(':enter', style({transform: 'translateX(110%)'})),
        transition(':enter', [
            group([
                query(':self', [
                    style({opacity: 0, transform: 'translateX(110%)'}),
                    animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateX(0)' }))
                ])
            ])
        ], {params: {time: '200ms', delay: '0ms'}}),
        transition(':leave', [
            group([
                query(':self', [
                    animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateX(110%)' }))
                ])
            ])
        ], {params: {timeLeave: '200ms', delayLeave : '0ms'}})
    ]);
/*
* Animate a layer with Slide from left effect applied to host element
* */
export const layerSlideFromLeftAnimation: AnimationTriggerMetadata =
    trigger('layerSlideFromLeftAnimation', [
        state('closed', style({transform: 'translateX(-130%)'})),
        transition('closed => open', [
            group([
                query(':self', [
                    animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateX(0)' }))
                ])
            ])
        ], {params: {time: '200ms', delay: '0ms'}}),
        transition('open => closed', [
            group([
                query(':self', [
                    animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateX(-130%)' }))
                ])
            ])
        ], {params: {timeLeave: '200ms', delayLeave : '0ms'}})
    ]);

/*
* Animate a layer with 'Slide from Bottom effect' applied to host element, and a 'Slide from Top effect' applied to .child-animate element
* */
export const layerSlideFromBottomAnimation: AnimationTriggerMetadata =
  trigger('layerSlideFromBottomAnimation', [
    state('closed', style({transform: 'translateY(120%)'})),
    transition('closed => open', [
      group([
        query(':self', [
          style({opacity: 0, transform: 'translateY(120%)'}),
          animate('{{time}} {{delay}}', style({ opacity: 1, transform: 'translateY(0)' }))
        ]),
        query('.child-animate', [
          style({opacity: 0, transform: 'translateY(50%)'}),
          animate('{{timeChild}} {{delayChild}} ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
        ])
      ])
    ], {params: {time: '300ms', delay: '0ms', timeChild: '150ms', delayChild: '300ms'}}),
    transition('open => closed', [
      group([
        query(':self', [
          animate('{{timeLeave}} {{delayLeave}}', style({ opacity: 0, transform: 'translateY(120%)' }))
        ])
      ])
    ], {params: {timeLeave: '300ms', delayLeave : '0ms'}})
  ]);
