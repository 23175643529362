import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  Event,
  NavigationEnd,
  Params,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class RoutesService {

  private routeData$: ReplaySubject<Data> = new ReplaySubject<Data>(1);
  private routeSnapshot$: ReplaySubject<ActivatedRouteSnapshot> = new ReplaySubject<ActivatedRouteSnapshot>(1);
  private events$: ReplaySubject<Event> = new ReplaySubject<Event>(1);
  private eventNavigationEnd$: ReplaySubject<NavigationEnd> = new ReplaySubject<NavigationEnd>(1);
  private callBackUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  setRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): void {
    this.routeSnapshot$.next(activatedRouteSnapshot);
  }
  setRouteDatas(data: Data): void {
    this.routeData$.next(data);
  }
  setEvents(event: Event): void {
    if (event instanceof RoutesRecognized) {
      const queryParamMap = event.state.root.queryParamMap;
      if (queryParamMap.has('callbackurl')) {
        this.callBackUrl$.next(queryParamMap.get('callbackurl'));
      }
    }
    this.events$.next(event);
  }
  setNavigationEnd(event: NavigationEnd): void {
    this.eventNavigationEnd$.next(event);
  }
  setCallBackUrl(url: string): void {
    this.callBackUrl$.next(url);
  }

  get routeSnapshot(): Observable<ActivatedRouteSnapshot> {
    return this.routeSnapshot$.asObservable();
  }
  get routeDatas(): Observable<Data> {
    return this.routeData$.asObservable();
  }
  get events(): Observable<Event> {
    return this.events$.asObservable();
  }
  get eventNavigationEnd(): Observable<NavigationEnd> {
    return this.eventNavigationEnd$.asObservable().pipe(share());
  }
  get callBackUrl(): Observable<string> {
    return this.callBackUrl$.asObservable();
  }

  routeHasAnOutlet(): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    return Object.keys(urlTree.root.children).length > 1;
  }

  routeHasOutlet(outletName: string): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    return !!urlTree.root.children[outletName];
  }

  routeHasOutletValue(outletName: string, outletValue: string): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    const routerHasOutletValue = urlTree.root.children[outletName] ?
      urlTree.root.children[outletName].segments.some((s) => s.path === outletValue) : false;
    return routerHasOutletValue;
  }

  extractTemplateFromRoute(route?: ActivatedRoute): string {
    let child = route ?? this.router.routerState.root.firstChild;
    const components = [];
    while (child) {
      if (child.routeConfig && child.routeConfig.path) {
        components.push(child.routeConfig.path);
      }
      child = child.firstChild;
    }
    return `/${components.join('/')}`;
  }

  extractParamsFromRoute(route?: ActivatedRoute): Params {
    let child = route ?? this.router.routerState.root.firstChild;
    let params = {};
    while (child) {
      if (child.snapshot.params && child.snapshot.params) {
        params = {...params, ...child.snapshot.params};
      }
      child = child.firstChild;
    }
    return Object.keys(params).length === 0 ? undefined : params;
  }

  getParentRoute(route?: ActivatedRouteSnapshot): Array<string> {
    let routeParent = route || this.activatedRoute.snapshot;
    let routeTmp = [];
    while (routeParent.parent) {
      if (routeParent.parent.url && routeParent.parent.url[0] && routeParent.parent.url[0].path) {
        routeTmp = [routeParent.parent.url[0].path, ...routeTmp];
      }
      routeParent = routeParent.parent;
    }
    return routeTmp;
  }
}
