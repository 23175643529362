import { LogLevel } from '@adeo/ngx-kozikaza-api';

export const environment = {
  production: true,
  enableKzUIRoute: false,
  env: 'prod',
  LOG_LEVEL: LogLevel.WARN,
  REDIRECT_URL: 'https://www.kozikaza.com',
  REDIRECT_URL_PT:'https://www.kozikaza.pt',
  CLIENT_ID: '28iihz8gat0kc4wcc40swc80cogoc808wg8s4ksggkowo4c4wc',
  SECRET_KEY: '3bivrfvm3am8sk0wsk88ooc8s8ggwsowsss0800scc8c8swkc0',
  API_BACKEND_ORIGIN: 'https://www.kozikaza.com/api',
  API_SOCKET_ORIGIN: 'https://api.kozikaza.com',
  BLOG_URL: 'https://www.kozikaza.com/blog/',
  API_BLOG_ORIGIN: 'https://www.kozikaza.com/blog/wp-json/wp/v2/',
  API_BLOG_ACCESS_TOKEN: 'a3otYXBpLWNsaWVudDpyRFhxIFRYZzEgbjNqWSBQa3V5IDQ4b2wgbDFEcQ==',
  API_KAZAPLAN: 'https://api.kazaplan.com/v4',
  API_KAZAPLAN_V41: 'https://api-v41.kazaplan.com',
  URL_RESIZE: 'https://img.kozikaza.com',
  URL_BUCKET_ASSETS: 'https://assets.kozikaza.com',
  URL_RESIZE_KEY: 'm',
  URL_RESIZE_BUCKET_KEY: 'kzkz-bucket',
  URL_RESIZE_MEDIA_HOST: 'media.kozikaza.com',
  URL_RESIZE_BUCKET_ASSETS_KEY: 'kzkz-assets-bucket',
  URL_RESIZE_MEDIA_ASSETS_HOST: 'www.kozikaza.com',
  URL_RESIZE_BUCKET_PRO_KEY: 'kzkz-pro-bucket',
  URL_RESIZE_MEDIA_PRO_HOST: 'media-pro.kozikaza.com',
  FACEBOOK_API_ID: '231574993567570',
  FACEBOOK_API_VERSION: 'v2.10',
  FACEBOOK_PAGE_ID: '136934696365648',
  GOOGLE_API_ID: '303614416653-qsallqdfrjrihmg7qh8pk9gsuj44vcnl.apps.googleusercontent.com',
  GOOGLE_TRANSLATE_API_ID: 'AIzaSyBeWydJ7NB5DJWALprtDeEgeEPYU4mWi3s',
  COOKIE_DOMAIN: '.kozikaza.com',
  COOKIE_USER_ID_NAME: 'userId',
  COOKIE_KAZA_ID_NAME: 'kazaId',
  COOKIE_EXPIRED_AT_NAME: 'expired_at',
  REFRESH_TOKEN_LIFE_TIME: 1209600,
  KAZAPLAN_SCRIPT: 'https://static.kazaplan.com/integration/widget/kazaplan.js',
  KAZAPLAN_ENV: 'prod',
  KAZAPLAN_KEY: '595a5d832f256',
  COOKIE_KAZAPLAN_KEY_NAME: 'kazaplan_partner_key',
  KAZAPLAN_KEYS: [
    { countryCode: 'FR', partnerKey: '595a5d832f256', appId: 'Kozikaza'},
    { countryCode: 'ES', partnerKey: '60a66472d44f6', appId: 'KozikazaEspagne'},
    { countryCode: 'PL', partnerKey: '6040a809376e8', appId: 'KozikazaPologne'},
  ],
  KAZAPLAN_ENVIRONMENT: 'production',
  SHOW_FOOTER_LANGUAGE_SWITCH: true,
  ALLOWED_LANGUAGES: ['fr', 'en', 'pl', 'es'],
  ALLOWED_LANGUAGES_LABELS: [
    { lang: 'fr', label: 'Français'},
    { lang: 'en', label: 'English'},
    { lang: 'pl', label: 'Polaco'},
    { lang: 'es', label: 'Español'},
  ],
  ENABLE_I18N: true,
  GTM_ID: 'GTM-T5RQK88',
  REACHFIVE_CLIENT_ID_FIRST_PARTY: 'fdPzWDRz8e8fwD0Z9DDv',
  REACHFIVE_CLIENT_SECRET_FIRST_PARTY: 'vHG6vfT32kAtzgYQAHNkVxJHDJCvrp8RK952PoMF',
  REACHFIVE_DOMAIN: 'kozikaza-prod.reach5.net',
  REACHFIVE_REDIRECT_URI: 'https://www.kozikaza.com',
  REACHFIVE_SCOPE: 'email full_write openid phone plan:read plan:write profile user:wanaplans offline_access media:read',
  SENTRYDSN: 'https://6aa8f24d19014e3384ca55ab3b7159e8@sentry.io/1833699',
  PRO: {
    ARCHITECT_JOB: '/jobs/634a1c32-0c36-417a-a9a2-5a5fd2babd4f',
    DECORATOR_JOB: '/jobs/b1984dc8-ac67-43c9-b59c-5aa022a170e5',
  },
  SHOPIFY_URL: 'https://box.kozikaza.com/',
  STRIPE_PUBLIC_KEY: 'pk_live_51JWjXMLOdweT4EMm1HRgHf4Wa6OZ8WCRHJh1uJUo9zAuDplzpTt6MaXU9O2rpljwIcNge3SxzN025QNAKAV716R900PpPiiehm',
  INSPIRATION: {
    INSPIRATION_PROJECT_BATHROOM_ID: 460979,
    INSPIRATION_PROJECT_KITCHEN_ID: 1209807,
    INSPIRATION_PROJECT_DRESSING_ID: 2090302,
  },
  DATADOG: {
    APPLICATION_ID: '4272d4fd-55d8-4909-87b7-8ed35560e19d',
    CLIENT_TOKEN: 'pubdb6f7dec573088ae0c39c02dec9474a7'
  },
  ACCES_TOKEN:'kozikaza_token_accessToken',
  KZP_ACCES_TOKEN:'kazplan_token_accessToken',

};
