import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Kaza, KazaService, KazaSubResources } from '@adeo/ngx-kozikaza-api';
import { NEVER, Observable, of } from 'rxjs';
import { execFunctionAfterEmit } from '../../misc/app-rxjs.helpers';

@Pipe({
  name: 'getKaza',
})
export class GetKazaPipe implements PipeTransform {

  constructor(
    private readonly kazaService: KazaService,
    private readonly cdr: ChangeDetectorRef,
  ) {

  }

  transform(kaza: string | Kaza, ...subRessources: KazaSubResources): Observable<Kaza> {
    return !kaza
      ? NEVER
      : ((typeof kaza !== 'string' && subRessources.length === 0)
        ? of(kaza) : this.kazaService.getKaza(kaza, subRessources)).pipe(
        execFunctionAfterEmit<Kaza>(() => this.cdr.detectChanges(), 100),
      );
  }

}
