import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-on-destroy',
  template: ''
})
export class OnDestroyComponent implements OnDestroy {

  constructor() { }

  ngOnDestroy() { }

}
