import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContentRef, createContentRef } from '../../utils/content-ref';

export class KzPopup<T> {
  private _componentRef: ComponentRef<T>;

  private _contentRef: ContentRef;

  private _isBeforeClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _isClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private _isOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get closed(): boolean {
    return this._isClosedSubject.getValue();
  }

  get opened(): boolean {
    return this._isOpenedSubject.getValue();
  }

  get componentRef(): ComponentRef<T> {
    return this._componentRef;
  }

  get isBeforeClosedChanges(): Observable<boolean> {
    return this._isBeforeClosedSubject.asObservable();
  }

  get isClosedChanges(): Observable<boolean> {
    return this._isClosedSubject.asObservable();
  }

  get isOpenedChanges(): Observable<boolean> {
    return this._isOpenedSubject.asObservable();
  }

  constructor(
    private _component: Type<T>,
    private _container: Node,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef,
    private _document: Document,
  ) {
  }

  open(content?: any, context?: any) {
    if (!this._componentRef) {
      if (content instanceof ContentRef) {
        this._contentRef = content;
      } else {
        this._contentRef = createContentRef(
          content,
          context,
          this._applicationRef,
          this._document,
          this._componentFactoryResolver,
          this._injector,
        );
      }
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(this._component);
      const popupInjector = Injector.create(
        {
          providers: [
            {
              provide: KzPopup,
              useValue: this,
            },
          ],
          parent: this._injector,
        },
      );
      this._componentRef = componentFactory.create(popupInjector, this._contentRef.nodes);
      this._applicationRef.attachView(this._componentRef.hostView);
      this._container.appendChild(this._componentRef.location.nativeElement);

      this._isClosedSubject.next(false);
      this._isOpenedSubject.next(true);
    }
  }

  close() {
    if (this._componentRef) {
      this._isBeforeClosedSubject.next(true);
      this._container.removeChild(this._componentRef.location.nativeElement);
      this._componentRef.destroy();

      if (this._contentRef && this._contentRef.viewRef) {
        this._contentRef.viewRef.destroy();
      }

      if (this._contentRef && this._contentRef.componentRef) {
        this._contentRef.componentRef.destroy();
      }

      this._componentRef = null;
      this._contentRef = null;

      this._isBeforeClosedSubject.next(false);
      this._isClosedSubject.next(true);
      this._isOpenedSubject.next(false);
    }
  }
}
