import { Pipe, PipeTransform } from '@angular/core';
import { Kaza } from '@adeo/ngx-kozikaza-api';

@Pipe({
  name: 'kazaLink'
})
export class KazaLinkPipe implements PipeTransform {

  transform(kaza: Kaza | string, args?: string): Array<string> {
    if (!kaza) {
      return null;
    }
    kaza = (typeof kaza === 'string') ? kaza : kaza['@id'];
    const kazaPath = kaza.replace('/kazas/', '');
    return (args != null) ? ['/kaza', kazaPath, args] : ['/kaza', kazaPath];
  }
}
