import { NgModule } from '@angular/core';
import { KzSelectComponent } from './kz-select.component';
import { KzIconsModule } from '../../icon/kz-icons.module';

@NgModule({
  imports: [
    KzIconsModule,
  ],
  declarations: [
    KzSelectComponent,
  ],
  exports: [
    KzSelectComponent,
  ],
})
export class KzSelectModule {

}
