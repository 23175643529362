import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KzPopupModule } from '../popup/kz-popup.module';
import { KzModalComponent } from './kz-modal.component';
import { KzModalService } from './kz-modal.service';
import { KzModalHeaderComponent } from './modal-sections/kz-modal-header/kz-modal-header.component';
import { KzModalFooterComponent } from './modal-sections/kz-modal-footer/kz-modal-footer.component';
import { KzModalContentComponent } from './modal-sections/kz-modal-content/kz-modal-content.component';
import { KzModalPopupComponent } from './kz-modal-popup.component';
import { KzIconsModule } from '../icon/kz-icons.module';
import { SELECTION_MODEL_FACTORY } from '@ng-select/ng-select';
import { DefaultSelectionModelFactory } from '../../../utils/patch/selection-model';

@NgModule({
  imports: [
    CommonModule,
    KzPopupModule,
    KzIconsModule,
  ],
  declarations: [
    KzModalComponent,
    KzModalPopupComponent,
    KzModalHeaderComponent,
    KzModalFooterComponent,
    KzModalContentComponent,
  ],
  providers: [
    KzModalService,
    /* 2022 05 06 : Patch for NG-SELECT in Lazy loaded modules (NgSelectModule) */
    { provide: SELECTION_MODEL_FACTORY, useValue: DefaultSelectionModelFactory }
  ],
  exports: [
    KzModalComponent,
    KzModalPopupComponent,
    KzModalHeaderComponent,
    KzModalFooterComponent,
    KzModalContentComponent,
  ],
})
export class KzModalModule {

}
