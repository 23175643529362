import { booleanAttribute, ChangeDetectorRef, Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kz-card',
  host: {
    '[class]': '"kz-card" + (classes ? " " + classes : "")',
  },
  template: `<ng-content></ng-content>`,
  styleUrls: ['./kz-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzCardComponent {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  @Input({transform: booleanAttribute}) fullWidth = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.fullWidth) {
      this._classes += ` kz-card--full-width`;
    }

  }

}
