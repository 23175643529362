  <button type="button" class="kz-header-menu-close" (click)="hideResponsiveMenu()">
    <kz-icons name="cross"></kz-icons>
    <ng-container i18n="@@features.header.resp.verticalMenu.closeCTA">Close</ng-container>
  </button>
  <form *appNgIfIsCommunity="{forceServerSide: true}" (ngSubmit)="onSubmit()" (key.enter)="handleKeyupEnter()" #searchForm="ngForm" class="kz-header-search" novalidate>
    <input type="search" [(ngModel)]="q" name="q" required="required" placeholder="Rechercher" class="kz-header-search-input" #searchInput />
    <button type="submit" class="kz-header-search-button" aria-label="Rechercher"><kz-icons name="search"></kz-icons></button>
  </form>
  <ul class="kz-header-nav" (appAccessControl)="isAllowed=$event">
    <li *appNgIfIsCommunity="{forceServerSide: true}">
      <a [routerLink]="['/kazacafe'] | kzLocalize" appGtmTrack="menu_community_discover_0" style="cursor: pointer;" class="kz-header-nav-link" i18n="@@features.header.nav.community">Community</a>
      <div class="kz-header-submenu kz-header-submenu--community">
        <div class="kz-header-submenu-content">
          <div class="kz-header-submenu-grid" [ngClass]="{'kz-header-submenu-open': open == 1}">
            <div class="kz-header-submenu-grid-item kz-header-submenu-item--head" (click)="showMenu(1)">
              <kz-icons name="community" inline="true"></kz-icons>
              <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.community" >Community</p>
              <kz-icons name="arrowright"></kz-icons>
            </div>
            <div class="kz-header-submenu-grid-item">
              <ul class="kz-header-list">
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/kazacafe'] | kzLocalize" appGtmTrack="menu_community_discover_cta" class="kz-link kz-header-list-special" i18n="@@features.header.nav.community.discover">Discover the community</a></li>
                <li *ngIf="isAllowed" (click)="hideResponsiveMenu()"><a [routerLink]="['/kazacafe', 'follow', 'feed'] | kzLocalize" appGtmTrack="menu_community_follow" class="kz-link" i18n="@@features.header.nav.community.suivis">Following</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/contest'] | kzLocalize" appGtmTrack="menu_community_contest" class="kz-link" i18n="@@features.header.nav.community.game">Contests</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <a [routerLink]="['/3d-home-design-software'] | kzLocalize" appGtmTrack="menu_plan3D_0" style="cursor: pointer;" class="kz-header-nav-link" i18n="@@features.header.nav.tools">3D Plan</a>
      <div class="kz-header-submenu kz-header-submenu--tools" >
        <div class="kz-header-submenu-content">
          <div class="kz-header-submenu-grid" [ngClass]="{'kz-header-submenu-open': open == 2}">
            <div class="kz-header-submenu-grid-item kz-header-submenu-item--head" (click)="showMenu(2)">
              <kz-icons name="plans" inline="true"></kz-icons>
              <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.tools.3dplan">3D Plan</p>
              <kz-icons name="arrowright"></kz-icons>
            </div>
            <div class="kz-header-submenu-grid-item">
              <ul class="kz-header-list">
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/3d-home-design-software'] | kzLocalize" appGtmTrack="menu_plan3D_cta" class="kz-link kz-header-list-special" i18n="@@features.header.nav.tools.create3dMap">Create a 3D plan</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/3d-home-design-software','kitchen'] | kzLocalize" appGtmTrack="menu_plan3D_plan3DKitchen" class="kz-link" i18n="@@features.header.nav.tools.kitchen3dplan">Kitchen 3D plan</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/3d-home-design-software','bathroom'] | kzLocalize" appGtmTrack="menu_plan3D_plan3DBathroom" class="kz-link" i18n="@@features.header.nav.tools.bathroom3dplan">Bathroom 3D plan</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/3d-home-design-software','electrical-plan'] | kzLocalize" appGtmTrack="menu_plan3D_plan3DElec" class="kz-link" i18n="@@features.header.nav.tools.electricalschema">Electrical plan</a></li>
<!--                <li (click)="hideResponsiveMenu()"><a [routerLink]="['/3d-home-design-software','design-by-a-professional'] | kzLocalize" appGtmTrack="menu_plan3D_3DdesignService" i18n="@@features.header.nav.tools.3ddesignservice">3D design service</a></li>-->
                <li (click)="hideResponsiveMenu()" *ngIf="user != null && user.activeKaza != null"><a [routerLink]="(user.activeKaza['@id'] | kazaLink: '3Dplan') | kzLocalize" appGtmTrack="menu_plan3D_myPlans" class="kz-link" [queryParams]="{noredirect: true}" fragment="kaza_menu" i18n="@@features.header.nav.tools.myPlans">My 3D plans</a></li>
                <li (click)="hideResponsiveMenu()"><a href="https://kazaplan.zendesk.com/hc/fr" rel="noreferrer" appGtmTrack="menu_plan3D_helpCenter" class="kz-link" target="_blank" i18n="@@features.header.nav.tools.helpCenter">3D plan help center</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li *appNgIfIsCommunity="{forceServerSide: true}">
      <span class="kz-header-nav-link" i18n="@@features.header.nav.tools.inspiration.title">Inspiration</span>
      <div class="kz-header-submenu kz-header-submenu--inspiration" >
        <div class="kz-header-submenu-content">
          <div class="kz-header-submenu-grid" [ngClass]="{'kz-header-submenu-open': (open == 3)}">
            <div class="kz-header-submenu-grid-item kz-header-submenu-item--head" (click)="showMenu(3)">
              <kz-icons name="idea" inline="true"></kz-icons>
              <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.tools.inspiration.title">Inspiration</p>
              <kz-icons name="arrowright"></kz-icons>
            </div>
            <div class="kz-header-submenu-grid-item">
              <ul class="kz-header-list">
                <li>
                  <span (click)="openInspiSubNav=0" class="kz-link kz-header-list-special" i18n="@@features.header.nav.tools.inspiration.bathroom">Bathroom</span>
                  <ul class="kz-header-sublist" [ngClass]="{'active': (openInspiSubNav == 0)}">
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'bathroom'] | kzLocalize" appGtmTrack="menu_inspi_bathroom_1" i18n="@@features.header.nav.tools.inspiration.bathroom.home">Home Bathroom</a></li>
                    <li (click)="hideResponsiveMenu()"><a [href]="blogUrl + bathroomSlug" appGtmTrack="menu_inspi_bathroom_posts" i18n="@@features.header.nav.tools.inspiration.articles">Posts</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'bathroom', 'photos'] | kzLocalize" appGtmTrack="menu_inspi_bathroom_photos" i18n="@@features.header.nav.tools.inspiration.pictures">Photos</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'bathroom', '3d-plan'] | kzLocalize" appGtmTrack="menu_inspi_bathroom_plans3D" i18n="@@features.header.nav.tools.inspiration.plans">3D Plans</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'bathroom', 'ideas'] | kzLocalize" appGtmTrack="menu_inspi_bathroom_ideas" i18n="@@features.header.nav.tools.inspiration.ideas">HD Ideas</a></li>
                  </ul>
                </li>
                <li>
                  <span (click)="openInspiSubNav=1" class="kz-link kz-header-list-special" i18n="@@features.header.nav.tools.inspiration.kitchen">Kitchen</span>
                  <ul class="kz-header-sublist" [ngClass]="{'active': (openInspiSubNav == 1)}">
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'kitchen'] | kzLocalize" appGtmTrack="menu_inspi_kitchen_1" i18n="@@features.header.nav.tools.inspiration.kitchen.home">Home Kitchen</a></li>
                    <li (click)="hideResponsiveMenu()"><a [href]="blogUrl + kitchenSlug" appGtmTrack="menu_inspi_kitchen_posts" i18n="@@features.header.nav.tools.inspiration.articles">Posts</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'kitchen', 'photos'] | kzLocalize" appGtmTrack="menu_inspi_kitchen_photos" i18n="@@features.header.nav.tools.inspiration.pictures">Photos</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'kitchen', '3d-plan'] | kzLocalize" appGtmTrack="menu_inspi_kitchen_plans3D" i18n="@@features.header.nav.tools.inspiration.plans">3D Plans</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'kitchen', 'ideas'] | kzLocalize" appGtmTrack="menu_inspi_kitchen_ideas" i18n="@@features.header.nav.tools.inspiration.ideas">HD Ideas</a></li>
                  </ul>
                </li>
                <li>
                  <span (click)="openInspiSubNav=2" class="kz-link kz-header-list-special" i18n="@@features.header.nav.tools.inspiration.dressing">Wardrobe and storage</span>
                  <ul class="kz-header-sublist" [ngClass]="{'active': (openInspiSubNav == 2)}">
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'dressing'] | kzLocalize" appGtmTrack="menu_inspi_wardrobe_1" i18n="@@features.header.nav.tools.inspiration.dressing.home">Home Wardrobe and storage</a></li>
                    <li (click)="hideResponsiveMenu()"><a [href]="blogUrl + dressingSlug" appGtmTrack="menu_inspi_wardrobe_posts" i18n="@@features.header.nav.tools.inspiration.articles">Posts</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'dressing', 'photos'] | kzLocalize" appGtmTrack="menu_inspi_wardrobe_photos" i18n="@@features.header.nav.tools.inspiration.pictures">Photos</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'dressing', '3d-plan'] | kzLocalize" appGtmTrack="menu_inspi_wardrobe_plans3D" i18n="@@features.header.nav.tools.inspiration.plans">3D Plans</a></li>
                  </ul>
                </li>
                <li>
                  <span (click)="openInspiSubNav=3" class="kz-link kz-header-list-special" i18n="@@features.header.nav.tools.inspiration.outdoor-design">Outdoor design</span>
                  <ul class="kz-header-sublist" [ngClass]="{'active': (openInspiSubNav == 3)}">
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'outdoor-design'] | kzLocalize" appGtmTrack="menu_inspi_wardrobe_1" i18n="@@features.header.nav.tools.inspiration.outdoor-design.home">Home Outdoor design</a></li>
                    <li (click)="hideResponsiveMenu()"><a [href]="blogUrl + outdoorDesignSlug" appGtmTrack="menu_inspi_wardrobe_posts" i18n="@@features.header.nav.tools.inspiration.articles">Posts</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'outdoor-design', 'photos', 'terrace'] | kzLocalize" appGtmTrack="menu_inspi_outdoor_design_photos_terrace" i18n="@@features.header.nav.tools.inspiration.pictures.terrace">Pictures of terraces</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'outdoor-design', 'photos', 'garden'] | kzLocalize" appGtmTrack="menu_inspi_outdoor_design_photos_garden" i18n="@@features.header.nav.tools.inspiration.pictures.garden">Pictures of gardens</a></li>
                    <li (click)="hideResponsiveMenu()"><a [routerLink]="['/inspiration', 'outdoor-design', '3d-plan'] | kzLocalize" appGtmTrack="menu_inspi_outdoor_design_plans3D" i18n="@@features.header.nav.tools.inspiration.plans">3D Plans</a></li>

                  </ul>
                </li>
                <li *ngIf="{fr: true, default: false} | languagedata" class="kz-link kz-header-list-mag" >
                  <a [href]="blogUrl" appGtmTrack="menu_inspi_leMag" class="kz-header-list--le-mag"><img  loading="lazy" src="/assets/images/navigation/kozikaza-le-mag-gris.svg" width="60" height="25" alt="">
                    <ng-container i18n="@@features.header.nav.tools.inspiration.mag">The Mag</ng-container>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li *appNgIfIsCommunity="{forceServerSide: true}">
      <span class="kz-header-nav-link" i18n="@@features.header.nav.coaching.title">Decoration services</span>
      <div class="kz-header-submenu kz-header-submenu--coaching">
        <div class="kz-header-submenu-content">
          <div class="kz-header-submenu-grid"  [ngClass]="{'kz-header-submenu-open': open == 4}">
            <div class="kz-header-submenu-grid-item kz-header-submenu-item--head" (click)="showMenu(4)">
              <kz-icons name="coaching" inline="true"></kz-icons>
              <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.coaching.sub-title">Decoration services</p>
              <kz-icons name="arrowright"></kz-icons>
            </div>
            <div class="kz-header-submenu-grid-item">
              <ul class="kz-header-list">
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['pro'] | kzLocalize" appGtmTrack="menu_pro_allPros" i18n="@@features.header.nav.allPros.cta">All decoration professionals</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['pro', 'interior-decorator'] | kzLocalize" appGtmTrack="menu_pro_interiorDecorators" i18n="@@features.header.nav.coachingList.cta">Interior decorators</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['pro', 'interior-architect'] | kzLocalize" appGtmTrack="menu_pro_interiorArchitect" i18n="@@features.header.nav.coachingArchitect.cta">Interior architects</a></li>
                <li (click)="hideResponsiveMenu()"><a [routerLink]="['pro', 'deco-offers'] | kzLocalize" appGtmTrack="menu_pro_decoOffers" i18n="@@features.header.nav.coachingServicesList.cta">All services</a></li>
                <li *ngIf="(userStoreService.userStore | async) as u">
                  <ng-container *ngIf="!!u.professionalId" (click)="hideResponsiveMenu()"><a [routerLink]="(u['@id'] | profileLink: 'coaching') | kzLocalize" appGtmTrack="menu_pro_myCoachings" i18n="@@features.header.nav.coachingProjects.cta">My decoration coaching</a></ng-container>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
    <ng-container *ngIf="locale === 'fr'">
      <li *appNgIfIsCommunity="{forceServerSide: true}">
        <span [routerLink]="['/home-garden-deals'] | kzLocalize"  class="kz-header-nav-link" i18n="@@features.header.nav.goodDeals.title">Good deals</span>
        <div [routerLink]="['/home-garden-deals'] | kzLocalize" appGtmTrack="menu_kzp_gooDeal_0" (click)="hideResponsiveMenu()" class="kz-header-submenu kz-header-submenu--coaching">
          <div class="kz-header-submenu-content">
            <div class="kz-header-submenu-grid">
              <div class="kz-header-submenu-grid-item kz-header-submenu-item--head">
                <kz-icons name="loan_granted" inline="true"></kz-icons>
                <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.goodDeals.title">Good deals</p>
                <kz-icons name="arrowright"></kz-icons>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
