import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HydraCollection } from '@adeo/ngx-kozikaza-api';
import { Country } from '@adeo/ngx-kozikaza-api/misc/country';

@Injectable({
  providedIn: 'root'
})
export class CountryStaticService {

  constructor() { }

  public getCountries(): Observable<HydraCollection<Country>> {
    const countries: HydraCollection<Country> = {
      '@id': '/countries/list',
      '@type': 'hydra:Collection',
      'hydra:totalItems': 8,
      'hydra:member': [
        {
          '@id': '/countries/40673edf-5b48-40e9-80ba-8cc10da59f61',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'YT'
        } as Country,
        {
          '@id': '/countries/473159e3-6a2a-48ee-8ea9-3763cafbaaf7',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'GF'
        } as Country,
        {
          '@id': '/countries/53d3baf5-2976-4de8-a25d-9603c768755b',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'GP'
        } as Country,
        {
          '@id': '/countries/79a7338e-dc00-4574-8c76-010b563b116f',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'FR'
        } as Country,
        {
          '@id': '/countries/a8b14b5d-d391-4849-961d-787db39e510a',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'CH'
        } as Country,
        {
          '@id': '/countries/a8ec66c0-23d7-41bd-92ca-b4a681663471',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'RE'
        } as Country,
        {
          '@id': '/countries/c2b3255a-3f56-4792-827b-918bc3ac5af5',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'BE'
        } as Country,
        {
          '@id': '/countries/fa1030f2-4caf-4d67-aeff-936d4d63c5f5',
          '@type': 'Country',
          associatedCountries: [],
          isCommunity: true,
          iso: 'MQ'
        } as Country
      ]
    };
    return of(countries);
  }
}
