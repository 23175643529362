import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit, Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TextboxState } from './textbox-options';

@Component({
  selector: '[kzTextBox]',
  exportAs: 'kzTextBox',
  host: {
    '[class]': '"kz-textbox" + (classes ? " " + classes : "")',
  },
  template: `<ng-content></ng-content>`,
  styleUrls: ['./kz-textbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzTextboxComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _textboxState: TextboxState = 'default';

  get textboxState(): TextboxState {
    return this._textboxState;
  }

  @Input()
  set textboxState(value: TextboxState) {
    this._textboxState = value;
    this.toggleState();
  }

  private _stateSpan: ElementRef;

  constructor(
    public _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private _renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private toggleState() {
    if (this._stateSpan) {
      this._renderer.removeChild(this._elementRef.nativeElement, this._stateSpan);
    }
    if (this.textboxState !== undefined && this.textboxState !== 'default') {
      this._stateSpan = this._renderer.createElement('span');
      this._renderer.addClass(this._stateSpan, `kz-textbox-state`);
      this._renderer.addClass(this._stateSpan, `kz-textbox-state--${ this.textboxState }`);
      this._renderer.appendChild(this._elementRef.nativeElement.parentElement, this._stateSpan);
    }

  }

  private _updateClasses() {
    this._classes = '';

    if (this.textboxState && this.textboxState !== 'default') {
      this._classes += ` kz-textbox-has-state`;
    }
  }
}
