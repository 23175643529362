import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { ToastrService } from '../../../shared/services/utilities/toastr.service';
import { UserStoreService } from '../../../shared/services/state-management/user-store.service';
import {
  filterUndefined,
  HydraCollection,
  Kaza,
  KazaService,
  User,
  UserFollow,
  UserFollowsService,
} from '@adeo/ngx-kozikaza-api';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { KazacafeFollowService } from '../../kazacafe/kazacafe-follow/kazacafe-follow.service';
import { KAZA_CONST } from '../../../utils/const/kaza';
import {
  ErrorHandlerStrategy,
  WebServiceOptions,
  WebServiceSubResourceFetchMode,
} from '@adeo/ngx-kozikaza-api';
import { HttpParams } from '@angular/common/http';
import { ClickAccessControlDirective } from '../../../utils/directives/access-control.directive';
import { KzBravoModule } from '../../../kz-ui/ui/bravo/kz-bravo.module';
import { GtmTrackModule } from '../../../utils/directives/gtm-track/gtm-track.module';

@Component({
  selector: 'app-kaza-follow',
  templateUrl: './kaza-follow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClickAccessControlDirective,
    KzBravoModule,
    GtmTrackModule,
  ]
})
export class KazaFollowComponent implements OnDestroy {
  @Input() nbFollowers = 0;
  @Input() isPro = false;
  public alreadyFollowed: boolean = undefined;

  userIdOfKaza: string;
  userFollowId: string;
  userLoggedIsKazaAuthor = false;
  userLogged: User;
  kazaFollowSubscription: Subscription;
  subscriptionFollowed: Subscription;
  submitted = false;

  @Input() set kaza(kaza: Kaza) {
    this.kaza$.next(kaza);
  }
  get kaza(): Kaza {
    return this.kaza$.getValue();
  }

  private attempt = 0;
  private kaza$: BehaviorSubject<Kaza> = new BehaviorSubject<Kaza>(undefined);
  private olgaKazaId = KAZA_CONST.OLGA_KAZA;

  constructor(
    private cdr: ChangeDetectorRef,
    private kazaService: KazaService,
    private toastr: ToastrService,
    private readonly userStoreService: UserStoreService,
    private readonly kazacafeFollowService: KazacafeFollowService,
    private userFollowService: UserFollowsService,
  ) {
    this.subscriptionFollowed = combineLatest([
      this.kaza$.asObservable().pipe(filterUndefined()),
      this.userStoreService.userStore.pipe(filterUndefined()),
      this.kazacafeFollowService.kazaFollowChange$
    ]).pipe(
      tap(([k, u, f]) => {
        this.submitted = true;
        if (u === null) {
          this.alreadyFollowed = false;
          this.submitted = false;
        }
        this.userLogged = u;
        this.userLoggedIsKazaAuthor = k.authors.some((author) => author['@id'] === ((u) ? u['@id'] : null));
        this.cdr.detectChanges();
      }),
      switchMap(([k, u, f]) =>
        forkJoin([
          this.getNbFollowers(k),
          (!!u && !this.userLoggedIsKazaAuthor)
            ? this.isAlreadyFollowed(u).pipe(
              map((isAlreadyFollowed) => {
                this.userFollowId = (!!isAlreadyFollowed && isAlreadyFollowed['hydra:member']?.length > 0)
                  ? isAlreadyFollowed['hydra:member'][0]['@id'] : null;
                return !!this.userFollowId;
              })
            )
            : of(false)
        ])
      ),
      tap(([nbFollowers, alreadyFollowed]) => {
        this.alreadyFollowed = alreadyFollowed;
        this.submitted = false;
        this.cdr.detectChanges();
      }),
    ).subscribe();
  }

  isAlreadyFollowed(user: User): Observable<HydraCollection<UserFollow>> {
    const webServiceOptions: WebServiceOptions = {
      subResourceFetchMode: WebServiceSubResourceFetchMode.SYNC,
      errorHandlerStrategy: ErrorHandlerStrategy.Ignore,
      refresh: true,
      query: new HttpParams({
        fromObject: {
          follow: this.userIdOfKaza,
          author: (typeof user == 'string') ? user : user['@id'],
          itemsPerPage: 1
        }
      })
    };

    return this.userFollowService.getUserFollows(null, webServiceOptions);
  }

  getNbFollowers(kaza): Observable<HydraCollection<UserFollow>> {
    this.userIdOfKaza = (typeof kaza.authors[0] === 'string') ? kaza.authors[0] : kaza.authors[0]['@id'];

    const webServiceOptions: WebServiceOptions = {
      subResourceFetchMode: WebServiceSubResourceFetchMode.SYNC,
      errorHandlerStrategy: ErrorHandlerStrategy.Ignore,
      refresh: true,
      query: new HttpParams({
        fromObject: {
          follow: this.userIdOfKaza,
          itemsPerPage: 0
        }
      })
    };

    return this.userFollowService.getUserFollows(null, webServiceOptions).pipe(
      tap((results) => {
        this.nbFollowers = results['hydra:totalItems'];
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    if (this.kazaFollowSubscription && !this.kazaFollowSubscription.closed) {
      this.kazaFollowSubscription.unsubscribe();
    }
    if (this.subscriptionFollowed && !this.subscriptionFollowed.closed) {
      this.subscriptionFollowed.unsubscribe();
    }
  }

  toggleFollowKaza(): void {
    if ((this.userLoggedIsKazaAuthor || this.submitted) && (this.attempt === 0)) {
      return;
    }
    this.submitted = true;
    this.cdr.markForCheck();

    this.kazaFollowSubscription = ((this.alreadyFollowed)
      ? this.userFollowService.deleteUserFollow(this.userFollowId)
      : this.userFollowService.postUserFollows(this.userIdOfKaza)).pipe(
      catchError((err) => {
        this.submitted = false;
        // When user logs on, Post is not updated, so this.post.alreadyLiked could be wrong.
        // If error : 1 attempt to toogleLike the other way
        if (this.attempt === 0 && err.status === 400 && err.error['@type'] === 'ConstraintViolationList') {
          this.attempt++;
          this.alreadyFollowed = !this.alreadyFollowed;
          this.toggleFollowKaza();
        } else {
          this.attempt = 0;
          this.toastr.error($localize`:@@toast.error_with_description:An error has occurred \: ${err.error['hydra:description']}:description:`);
        }
        this.cdr.detectChanges();
        return throwError(err);
      }),
      tap((data: UserFollow) => {
        this.userFollowId = (this.alreadyFollowed) ? null: data['@id'];
        this.nbFollowers = (this.alreadyFollowed) ? this.nbFollowers-1 : this.nbFollowers+1;
        this.alreadyFollowed = !this.alreadyFollowed;
        this.updateIfOlga();
        this.kazacafeFollowService.kazaFollowChange = this.alreadyFollowed;
        this.submitted = false;
        this.cdr.detectChanges();
      }),
    ).subscribe();
  }

  private updateIfOlga() {
    if (this.kaza['@id'] === this.olgaKazaId) {
      this.kazacafeFollowService.userLoggedFollowsOlga = this.alreadyFollowed;
    }
  }
}
