import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KzErrorComponent } from './kz-error.component';
import { KzIconsModule } from '../../icon/kz-icons.module';

@NgModule({
  imports: [
    CommonModule,
    KzIconsModule,
  ],
  declarations: [
    KzErrorComponent,
  ],
  exports: [
    KzErrorComponent,
  ],
})
export class KzErrorModule {

}
