import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalType } from '../../modal-options';

@Component({
  selector: 'kz-modal-footer',
  host: {
    '[class]': '"kz-modal-footer" + (classes ? " " + classes : "")',
  },
  styleUrls: ['./kz-modal-footer.component.scss'],
  template: `<ng-content></ng-content>`,
  encapsulation: ViewEncapsulation.None
})
export class KzModalFooterComponent implements OnInit {

  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _align: 'right' | 'center' | 'left' = 'center';

  @Input()
  set align(align: 'right' | 'center' | 'left') {
    this._align = align;
  }

  get align(): 'right' | 'center' | 'left' {
    return this._align;
  }

  private _border = false;

  @Input()
  set border(border: boolean) {
    this._border = border;
  }

  get border(): boolean {
    return this._border;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.align && this.align !== 'center') {
      this._classes += ` kz-modal-footer--${ this.align }`;
    }
    if (this.border) {
      this._classes += ' kz-modal-footer--border';
    }
  }

}
