import { BaseService } from '../base.service';
import { forkJoin, Observable, Subject, switchMap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TransferState } from '@angular/platform-browser';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HydraCollection,
  WebServiceOptions,
} from '@adeo/ngx-kozikaza-api';
import { map, tap } from 'rxjs/operators';
import { postSubResources } from '../../../utils/const/post';
import {
  Post as SDKPost,
  PostService as SDKPostService,
  PostSubResources,
  PostEvent,
  Post,
} from '@adeo/ngx-kozikaza-api/post';
import { DisplayPost } from '../../models/publication';

@Injectable()
export class PostService extends BaseService {
  static endpoint = '/posts';

  public postEventStored: PostEvent = null;
  public countFollow: Subject<number> = new Subject();

  constructor(
    protected http: HttpClient,
    @Inject(TransferState) transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: any,
    private sdkPostService: SDKPostService,
  ) {
    super(http, transferState, platformId);
  }

  public create(post: SDKPost): Observable<DisplayPost> {
    return this.sdkPostService.postPosts(post).pipe(
      switchMap((updatedPost) => this.getPost(updatedPost['@id'],null, {refresh: true})),
    );
  }

  public update(resourceId: string, post: SDKPost): Observable<DisplayPost> {
    return this.sdkPostService.putPost({...post, '@id': resourceId}).pipe(
      switchMap((updatedPost) => this.getPost(updatedPost['@id'])),
    );
  }

  public getPost(postId: string, subRessources?: PostSubResources, webserviceOptions?: WebServiceOptions): Observable<DisplayPost> {
    return this.sdkPostService.getPost(postId, subRessources || postSubResources, webserviceOptions).pipe(
      map((post: Post) => post as DisplayPost),
    );
  }

  public getPostsFlux(postsFluxType: PostsFluxType, args: any): Observable<HydraCollection<DisplayPost>> {
    let query = this.setParams(args);
    query = query.append('timestamp', `${+ new Date()}`);
    const webServiceOptions: WebServiceOptions = {
      refresh: true,
      query
    };
    let query$: Observable<HydraCollection<SDKPost>>;
    switch (postsFluxType) {
      case 'kazacafe':
        query$ = this.sdkPostService.getKazacafeDiscover(null, webServiceOptions);
        break;
      case 'search':
        query$ = this.sdkPostService.searchPosts(null, webServiceOptions);
        break;
      case 'follow':
        query$ = this.sdkPostService.getKazacafeFollow(null, webServiceOptions);
        break;
      case 'posts':
        query$ = this.sdkPostService.getPosts(null, webServiceOptions);
        break;
      default:
        query$ = this.sdkPostService.getPosts(null, webServiceOptions);
        break;
    }
    return query$.pipe(
      switchMap((posts) =>
        forkJoin(posts['hydra:member'].map((post) => this.sdkPostService.getPost(post, postSubResources, {refresh: true}))).pipe(
          map((postsArray) => ({...posts, 'hydra:member': postsArray.map((post) => post as DisplayPost)})),
        )
      ),
    );
  }

  public getPostMediaId(postId: string, mediaId: string): string {
    return this.sdkPostService.getPostMediaExistingId(postId, mediaId);
  }

  private cleanArgs(args: any): any {
    Object.keys(args).forEach(key => !args[key] && delete args[key]);
    return args;
  }

  private setParams( args: any ): HttpParams {
    let privacy;
    let order;
    let countries;
    let languages;
    let favorites;
    let argsTemp  = {
      privacy: null,
      order: null,
      countries: null,
      languages: null,
      favorites: null,
    };
    argsTemp = Object.assign(argsTemp, args);
    if (argsTemp.privacy) {
      privacy = argsTemp.privacy;
    }
    delete argsTemp.privacy;

    if (argsTemp.order) {
      order = argsTemp.order;
    }
    delete argsTemp.order;

    if (argsTemp.countries) {
      countries = argsTemp.countries;
    }
    delete argsTemp.countries;

    if (argsTemp.languages) {
      languages = argsTemp.languages;
    }
    delete argsTemp.languages;

    let params = new HttpParams({ fromObject: this.cleanArgs(argsTemp) });
    if (privacy) {
      privacy.map((p) => params = params.append('privacy[]', p));
    }
    if (countries) {
      countries.map((c) => params = params.append('country[]', c));
    }
    if (languages) {
      languages.map((l) => params = params.append('language[]', l));
    }
    params = (order && order._score) ? params.append('order[_score]', order._score) : params;
    params = (order && order.createdAt) ? params.append('order[createdAt]', order.createdAt) : params;
    return params;
  }
}

export type PostsFluxType = 'posts' | 'kazacafe' | 'search' | 'follow' | 'favorites' ;
