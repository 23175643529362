import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {
  transform(list: any, args?: any): any {
    if (args && args.order === 'ASC') {
      return list.sort((a, b) => (new Date(a[args.key]).getTime() - new Date(b[args.key]).getTime()));
    } else if (args && args.order === 'DESC') {
      return list.sort((a, b) => (new Date(b[args.key]).getTime() - new Date(a[args.key]).getTime()));
    }

    return list;
  }
}
