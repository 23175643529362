<kz-modal-header *ngIf="title" border="true">
  <span class="uppercase" [innerHTML]="title"></span>
</kz-modal-header>
<kz-modal-content *ngIf="content">
  <span *ngIf="contentType != 'template'" [innerHTML]="contentString"></span>
  <ng-container  *ngIf="contentType == 'template'" >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ng-container>
</kz-modal-content>

<kz-modal-footer [align]="'right'">
  <button *ngIf="cancelAction" type="button" kzButton (click)="cancel()" buttonStyle="borderless" [buttonContext]="type">
    <span *ngIf="!cancelActionLabel" i18n="@@popin.yesNoDialog.action.cancel">Cancel</span>
    <span *ngIf="cancelActionLabel">{{cancelActionLabel}}</span>
  </button>
  <button *ngIf="noAction" type="button" kzButton (click)="no()"  [buttonContext]="type">
    <span *ngIf="!noActionLabel" i18n="@@popin.yesNoDialog.action.no">No</span>
    <span *ngIf="!noActionLabel">{{noActionLabel}}</span>
  </button>
  <button *ngIf="yesAction" type="button" kzButton (click)="yes()" buttonStyle="flat" [buttonContext]="type">
    <span  *ngIf="!yesActionLabel" i18n="@@popin.yesNoDialog.action.yes">Yes</span>
    <span  *ngIf="!yesActionLabel">{{yesActionLabel}}</span>
  </button>
</kz-modal-footer>
