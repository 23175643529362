import { Injectable } from '@angular/core';
import {
  AccountType,
  AuthenticationService,
  distinctHydraMember,
  ErrorHandlerStrategy,
  filterNullOrUndefined,
  filterUndefined,
  HydraCollection,
  Kaza,
  KazaService,
  KazaStat,
  Professional,
  ProfessionalService,
  TokenStorage,
  User,
  UserRole,
  UserService,
  UserStat,
  WebServiceOptions,
  WebServiceSubResourceFetchMode,
} from '@adeo/ngx-kozikaza-api';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, of } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../features/oauth/auth.service';
import { activeKazaSubResources } from '../../../utils/const/kaza';
import { currentUserSubResources } from '../../../utils/const/profile';
import { currentUserProfessionalSubResources } from '../../../utils/const/pro';
import { RoutesService } from '../routes/routes.service';
import { WebNotification, WebNotificationService } from '@adeo/ngx-kozikaza-api/notifications';
import { SharedService } from '../utilities/shared.service';
import { BadgeService } from '@adeo/ngx-kozikaza-api/post';

@Injectable({
  providedIn: "root"
})
export class UserStoreService {
  private forceUserStoreUpdate$: BehaviorSubject<string> = new BehaviorSubject<
    string
  >(undefined);
  private forceUserProfessionalStoreUpdate$: BehaviorSubject<
    string
  > = new BehaviorSubject<string>(undefined);
  private userStore$: BehaviorSubject<User> = new BehaviorSubject<User>(
    undefined
  );
  private userStoreActiveKaza$: BehaviorSubject<Kaza> = new BehaviorSubject<
    Kaza
  >(undefined);
  private userStoreKazas$: BehaviorSubject<Array<Kaza>> = new BehaviorSubject<
    Array<Kaza>
  >(undefined);
  private userStorePro$: BehaviorSubject<Professional> = new BehaviorSubject<
    Professional
  >(undefined);
  private userStoreStats$: BehaviorSubject<UserStat> = new BehaviorSubject<
    UserStat
  >(undefined);
  private userStoreKazaStats$: BehaviorSubject<KazaStat> = new BehaviorSubject<
    KazaStat
  >(undefined);
  private userStoreNbKazas$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(undefined);
  private userStoreNbKazamis$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(undefined);
  private userStoreNbNotifications$: BehaviorSubject<
    number
  > = new BehaviorSubject<number>(undefined);
  private userStoreNbMessages$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(undefined);
  private userStoreEmailConfirmed$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(undefined);
  private userStoreBetaTester$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(undefined);

  public previousAuthTagsEnabled$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  public previousAuthTagsStepTwoEnabled$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  public projectIsAlreadyBegun$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  public userBadge$ = new BehaviorSubject<any>({});

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly kazaService: KazaService,
    private readonly professionalService: ProfessionalService,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly router: Router,
    private readonly routesService: RoutesService,
    private readonly sdkWebNotificationService: WebNotificationService,
    private readonly tokenStorage: TokenStorage,
    private readonly activatedRoute: ActivatedRoute,
    private sharedService:SharedService,
    private badgeService:BadgeService
  ) {
    const hydraMember = 'hydra:member';
    this.authenticationService.authenticatedUser.pipe(
      filter((user) => {
        if (!user) {
          this.resetState();
        }
        return !!user;
      }),
      // Set User is beta tester.
      tap((user: User) => this.userBetaTester = !!this.tokenStorage.token?.idTokenPayload?.customFields?.beta_test_kozikaza),
      /*switchMap((user: User) => this.authenticationService.getKzpToken().pipe(
        map(() => user)
      )),*/
      //  tap((user)=> console.log('uuuuuuuuuuuuuuuuuuuuuu',user)),
        switchMap((user: User) => this.checkUserEmailValidated(user)),
        tap((user:any)=> user?.badge && this.getUserBadge(user)),
        switchMap((user: User) => {
          this.user = user;
          const params = new HttpParams({ fromObject: { itemsPerPage: "99" } });
          const webServiceOptions: WebServiceOptions = { query: params };

          return merge(
            this.user.professionalId
              ? this.professionalService
                  .getProfessional(
                    this.user.professionalId,
                    currentUserProfessionalSubResources
                  )
                  .pipe(tap(pro => (this.userProfessional = pro)))
              : EMPTY,
            this.kazaService
              .getKaza(user.activeKaza, activeKazaSubResources)
              .pipe(tap(kaza => (this.userActiveKaza = kaza))),
            this.kazaService
              .getUserKazas(user, null, webServiceOptions)
              .pipe(tap(kazas => (this.userKazas = kazas[hydraMember])))
          );
        })
      )
      .subscribe();

    /*
     * Update userStats on router event OR authenticatedUser change
     * */
    let preventRefresh = false;
    const navigationEndFiltered$ = this.routesService.eventNavigationEnd.pipe(
      switchMap(route =>
        this.routesService.routeDatas.pipe(
          tap(routeDatas => {
            preventRefresh = !!routeDatas?.preventNotifAndStatsRefreshOnRoute;
          }),
          map(datas => route)
        )
      ),
      /* Don't ask for notif updates if preventRefresh (except if first access) */
      filter(route => route.id === 1 || !preventRefresh),
      /* Don't ask for notif updates when opening outlet (except if first access) */
      filter(route => route.id === 1 || !this.routesService.routeHasAnOutlet())
    );
    combineLatest([
      navigationEndFiltered$,
      this.authenticationService.authenticatedUser.pipe(distinctHydraMember())
    ])
      .pipe(
        switchMap(([route, user]) => {
          const webServiceOptions: WebServiceOptions = {
            subResourceFetchMode: WebServiceSubResourceFetchMode.SYNC,
            errorHandlerStrategy: ErrorHandlerStrategy.SetNull,
            refresh: true,
            refreshOnlyFirstLevel: true,
            query: new HttpParams({
              fromObject: {
                page: "1",
                itemsPerPage: "0",
                read: "false"
              }
            })
          };

          return !!user && user["@id"]
            ? combineLatest([
                this.userService.getUserStats(user["@id"]),
                this.sdkWebNotificationService.getWebNotifications(
                  null,
                  webServiceOptions
                )
              ]).pipe(
                filter(([stats, webNotifs]) => !!stats && !!webNotifs),
                tap(data => this.updateAuthenticatedUserStatsState(data))
              )
            : EMPTY;
        })
      )
      .subscribe();

    /*
     * Update kazaStats on router event OR activeKaza change
     * */
    combineLatest([
      navigationEndFiltered$,
      this.userStoreActiveKaza.pipe(distinctHydraMember()),
      this.forceUserStoreUpdate$
        .asObservable()
        .pipe(
          switchMap(forcedUser =>
            forcedUser
              ? this.userService
                  .getUser(forcedUser, currentUserSubResources, {
                    refresh: false
                  })
                  .pipe(tap(u => (u ? (this.user = u) : null)))
              : of(false)
          )
        )
    ])
      .pipe(
        switchMap(([route, kaza, forced]) =>
          !!kaza
            ? this.kazaService
                .getKazaStats(
                  kaza && typeof kaza !== "string" ? kaza["@id"] : kaza
                )
                .pipe(
                  filter(data => !!data),
                  tap(data => (this.userKazaStats = data))
                )
            : EMPTY
        )
      )
      .subscribe();

    this.forceUserProfessionalStoreUpdate$
      .asObservable()
      .pipe(
        switchMap(forcedPro =>
          forcedPro
            ? this.professionalService
                .getProfessional(
                  forcedPro,
                  currentUserProfessionalSubResources,
                  { refresh: false }
                )
                .pipe(tap(p => (p ? this.userStorePro$.next(p) : null)))
            : of(false)
        )
      )
      .subscribe();
  }

  public resetState() {
    this.user = null;
    this.userActiveKaza = null;
    this.userKazas = null;
    this.userProfessional = null;
    this.userStats = null;
    this.userKazaStats = null;
    this.userNbKazas = null;
    this.userNbKazamis = null;
    this.userNbNotifications = null;
    this.userNbMessages = null;
  }

  /*
   * user
   * */
  get user(): User {
    return this.userStore$.getValue();
  }

  set user(user: User) {
    // if one of user's sub-resources is not populated, then call forceUserStoreUpdate$
    const needForceUpdateSubRessources =
      user &&
      currentUserSubResources.some(
        sub =>
          typeof sub === "string" && user[sub] && typeof user[sub] === "string"
      );
    needForceUpdateSubRessources
      ? this.forceUserStoreUpdate$.next(user["@id"])
      : this.userStore$.next(user);
  }

  get userStore(): Observable<User> {
    return this.userStore$.asObservable().pipe(share());
  }

  get userBadgeInfo(){
     return this.userBadge$.getValue();
  }
  updateUserStoreFromUserId(userId: string): void {
    this.forceUserStoreUpdate$.next(userId);
  }

  refreshUserStore(): void {
    const isLogged = this.authService.getUserId();
    // refresh UserStore
    if (isLogged) {
      this.updateUserStoreFromUserId(isLogged);
    }
  }

  checkUserEmailValidated(user: User): Observable<User> {
    if (!user["emailValidatedAt"]) {
      return this.authenticationService.userInfos5().pipe(
        tap(userR5 => (this.userEmailConfirmed = !!userR5.email_verified)),
        switchMap(() => of(user))
      );
    } else {
      this.userEmailConfirmed = true;
    }
    return of(user);
  }

  /*
   * activeKaza
   * */
  get userActiveKaza(): Kaza {
    return this.userStoreActiveKaza$.getValue();
  }

  set userActiveKaza(kaza: Kaza) {
    if (!!kaza && !kaza.country) {
      // temporary patch to prevent 503 for user that do not have kazaActive.country setted.
      kaza.country = "FR";
    }
    if (this.user && kaza) {
      this.user = { ...this.user, ...{ activeKaza: kaza } };
      this.authService.setKazaId(kaza ? kaza["@id"] : null);
    }
    this.userStoreActiveKaza$.next(kaza);
  }

  get userStoreActiveKaza(): Observable<Kaza> {
    return this.userStoreActiveKaza$.asObservable();
  }

  /*
   * isAdmin
   * */
  get isAdmin(): boolean {
    return this.user ? this.user.roles.includes(UserRole.Admin) : false;
  }

  get isAdminStore(): Observable<boolean> {
    return this.userStore$.asObservable().pipe(
      filterUndefined(),
      map(user => !!user && user.roles.includes(UserRole.Admin))
    );
  }

  isUserLoggedIn(user: User | string): boolean {
    return this.user
      ? (typeof this.user === "string" ? this.user : this.user["@id"]) ===
          user["@id"]
      : false;
  }

  /*
   * SCHOOL
   * */
  get isSchool(): boolean {
    return this.user ? this.user.accountType === AccountType.School : false;
  }

  /*
   * PRO
   * */
  get isPro(): boolean {
    return this.user ? !!this.user.professionalId : false;
  }

  get userProfessional(): Professional {
    return this.userStorePro$.getValue();
  }

  set userProfessional(pro: Professional) {
    // if one of userProfessional's sub-resources is not populated, then call forceUserProfessionalStoreUpdate$
    const needForceUpdateSubRessources =
      pro &&
      currentUserProfessionalSubResources.some(
        sub =>
          typeof sub === "string" && pro[sub] && typeof pro[sub] === "string"
      );
    needForceUpdateSubRessources
      ? this.forceUserProfessionalStoreUpdate$.next(pro["@id"])
      : this.userStorePro$.next(pro);
  }

  get userStoreProfessional(): Observable<Professional> {
    return this.userStorePro$.asObservable().pipe(filterUndefined());
  }

  /*
   * isLoggedIn
   * */
  get isLoggedIn(): boolean {
    return !!this.user;
  }

  get isLoggedInStore(): Observable<boolean> {
    return this.userStore.pipe(
      filterUndefined(),
      map(user => !!user)
    );
  }

  /*
   * userKazas
   * */
  get userKazas(): Array<Kaza> {
    return this.userStoreKazas$.getValue();
  }

  set userKazas(kazas: Array<Kaza>) {
    this.userStoreKazas$.next(kazas);
  }

  get userStoreKazas(): Observable<Array<Kaza>> {
    return this.userStoreKazas$.asObservable();
  }

  /*
   * userStats
   * */
  get userStats(): UserStat {
    return this.userStoreStats$.getValue();
  }

  set userStats(stats: UserStat) {
    this.userStoreStats$.next(stats);
  }

  get userStoreStats(): Observable<UserStat> {
    return this.userStoreStats$.asObservable();
  }

  /*
   * kazaStats
   * */
  get userKazaStats(): KazaStat {
    return this.userStoreKazaStats$.getValue();
  }

  set userKazaStats(stats: KazaStat) {
    this.userStoreKazaStats$.next(stats);
  }

  get userStoreKazaStats(): Observable<KazaStat> {
    return this.userStoreKazaStats$.asObservable();
  }

  /*
   * nbKazas
   * */
  get userNbKazas(): number {
    return this.userStoreNbKazas$.getValue();
  }

  set userNbKazas(nbKazas: number) {
    this.userStoreNbKazas$.next(nbKazas);
  }

  get userStoreNbKazas(): Observable<number> {
    return this.userStoreNbKazas$.asObservable();
  }

  /*
   * nbKazamis
   * */
  get userNbKazamis(): number {
    return this.userStoreNbKazamis$.getValue();
  }

  set userNbKazamis(nbKazamis: number) {
    this.userStoreNbKazamis$.next(nbKazamis);
  }

  get userStoreNbKazamis(): Observable<number> {
    return this.userStoreNbKazamis$.asObservable();
  }

  /*
   * nbNotifications
   * */
  get userNbNotifications(): number {
    return this.userStoreNbNotifications$.getValue();
  }

  set userNbNotifications(nbNotifications: number) {
    this.userStoreNbNotifications$.next(nbNotifications);
  }

  get userStoreNbNotifications(): Observable<number> {
    return this.userStoreNbNotifications$.asObservable();
  }

  /*
   * nbMessages
   * */
  get userNbMessages(): number {
    return this.userStoreNbMessages$.getValue();
  }

  set userNbMessages(nbMessages: number) {
    this.userStoreNbMessages$.next(nbMessages);
  }

  get userStoreNbMessages(): Observable<number> {
    return this.userStoreNbMessages$.asObservable();
  }

  /*
   * emailConfirmed
   * */
  get userEmailConfirmed(): boolean {
    return this.userStoreEmailConfirmed$.getValue();
  }

  set userEmailConfirmed(verified: boolean) {
    this.userStoreEmailConfirmed$.next(verified);
  }

  get userStoreEmailConfirmed(): Observable<boolean> {
    return this.userStoreEmailConfirmed$.asObservable();
  }

  /*
   * userBetaTester
   * */
  get userBetaTester(): boolean {
    return this.userStoreBetaTester$.getValue();
  }

  set userBetaTester(isBetaTester: boolean) {
    this.userStoreBetaTester$.next(isBetaTester);
  }

  get userStoreBetaTester(): Observable<boolean> {
    return this.userStoreBetaTester$.asObservable();
  }

  /*
   * misc
   * */
  private updateAuthenticatedUserStatsState(
    data: [UserStat, HydraCollection<WebNotification>]
  ): void {
    if (data[0].pendingFriends != null) {
      //  from userService
      this.userNbKazamis = +data[0].pendingFriends.total;
    }
    if (data[1]["hydra:totalItems"] != null) {
      //  from NotificationsService
      this.userNbNotifications = data[1]["hydra:totalItems"];
    }

    //  from userService
    this.userNbKazas = data[0].kazas != null ? data[0].kazas.total : 2;

    this.userStats = data[0];
  }

  /*
   * Links
   * */
  get activityLink(): Array<any> {
    const link = this.localizeRouterService.translateRoute(
      this.isPro && typeof this.userProfessional.mainJob !== "string"
        ? [
            "/",
            "pro",
            this.userProfessional.mainJob.nameSlug,
            this.userProfessional.pseudo,
            "activity"
          ]
        : [this.userActiveKaza["@id"].replace("kazas/", "kaza/"), "story"]
    ) as Array<any>;
    return link;
  }

  public plan3DLink(kazaId?: string): Array<any> {
    const link = this.localizeRouterService.translateRoute(
      this.isPro && typeof this.userProfessional.mainJob !== "string"
        ? [
            "/",
            "pro",
            this.userProfessional.mainJob.nameSlug,
            this.userProfessional.pseudo,
            "3Dplan"
          ]
        : [
            (kazaId || this.userActiveKaza["@id"]).replace("kazas/", "kaza/"),
            "3Dplan"
          ]
    ) as Array<any>;
    return link;
  }

  get plan3DLink$(): Observable<Array<any>> {
    return this.userStore.pipe(
      filterUndefined(),
      switchMap(() =>
        this.isPro
          ? this.userStoreProfessional.pipe(
              filter(pro => !!pro),
              map(pro =>
                typeof pro.mainJob !== "string"
                  ? ["/", "pro", pro.mainJob.nameSlug, pro.pseudo, "3Dplan"]
                  : null
              )
            )
          : this.userStoreActiveKaza.pipe(
              filterNullOrUndefined(),
              map(activeKaza => [
                ...activeKaza["@id"].replace("kazas/", "kaza/").split("/"),
                "3Dplan"
              ])
            )
      ),
      map(path => this.localizeRouterService.translateRoute(path) as Array<any>)
    );
  }
  /*
   * previous Auth Tag Step One
   * */
  get previousAuthTagsEnabled(): boolean {
    return this.previousAuthTagsEnabled$.getValue();
  }

  set previousAuthTagsEnabled(isEnabled: boolean) {
    this.previousAuthTagsEnabled$.next(isEnabled);
  }

  /*
   * previous Auth Tag Step One
   * */
  get previousAuthTagsStepTwoEnabled(): boolean {
    return this.previousAuthTagsStepTwoEnabled$.getValue();
  }

  set previousAuthTagsStepTwoEnabled(isEnabled: boolean) {
    this.previousAuthTagsStepTwoEnabled$.next(isEnabled);
  }

  /*
   * projectIsAlreadyBegun
   * */
  getprojectIsAlreadyBegun(): Observable<boolean> {
    return this.projectIsAlreadyBegun$.asObservable();
  }

  set projectIsAlreadyBegun(value: boolean) {
    this.projectIsAlreadyBegun$.next(value);
  }
  getUserInfo(): void {
    this.userService
      .me(null, { refresh: true })
      .pipe(
        filter(user => {
          return !!user;
        }),
        tap(user => this.user = user)
      )
      .subscribe();
  }
  public getUserBadge(user):void {
    this.badgeService.getBadge(user?.badge).subscribe({
      next:data=>{
        if(data){
          this.userBadge$.next(data);
        }
      }
    })
  }
}
