import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  distinctHydraMember,
  filterNullOrUndefined,
  filterUndefined,
  Professional,
  User,
  UserRegisterFrom
} from '@adeo/ngx-kozikaza-api';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { AuthPopinService } from './auth-popin.service';
import { UserStoreService } from '../../shared/services/state-management/user-store.service';
import { RoutesService } from '../../shared/services/routes/routes.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { CommunityService, CommunityState } from '../../shared/services/community.service';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'environments/environment';
import { ModalService } from "../../utils/modals/modals.service";
import { Observable } from "rxjs/internal/Observable";
import { EmailCheckComponent } from "./email-check/email-check.component";
import { CookieService } from "ngx-cookie";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private tslogger: Tslogger;

  public step1HasBeenProposed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public step2HasBeenProposed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private callBackUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  private isPrevious = false;
  private isPreviousStepTwo = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authPopinService: AuthPopinService,
    private userStoreService: UserStoreService,
    private routesService: RoutesService,
    private modalService: ModalService,
    private readonly communityService: CommunityService,
    private readonly cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.getPreviousAuthTagsStatus();
    this.getPreviousAuthTagsStepTwoStatus();
    combineLatest([
      this.communityService.isCommunityChanges.pipe(
        distinctUntilChanged((prev, curr) => prev.isCommunity === curr.isCommunity),
      ),
      this.userStoreService.userStore.pipe(
        filterUndefined(),
        distinctObject(),
        tap((user: User) => {
          if (!user) {
            this.step1HasBeenProposed = false;
            this.step2HasBeenProposed = false;
          } else {
            this.step1HasBeenProposed = this.cookieService.hasKey(this.getHasBeenProposedCookieName(user["@id"]));
            this.step2HasBeenProposed = this.cookieService.hasKey(this.getHasBeenProposedCookieName(user["@id"], 2));
          }
        }),
      ),
      this.userStoreService.userStoreEmailConfirmed.pipe(
        filterUndefined(),
        distinctHydraMember(),
      ),
      this.routesService.eventNavigationEnd.pipe(
        debounceTime(200),
      )
    ]).pipe(
      filter(([isCommunity, user, emailConfirmed]) => !!user),
      filter(() => isPlatformBrowser(this.platformId)),
      switchMap(([isCommunity, user, emailConfirmed]) => !!(user as User).professionalId
        ? this.userStoreService.userStoreProfessional.pipe(
          filterNullOrUndefined(),
          map(pro => [isCommunity, user, pro, emailConfirmed])
        )
        : of([isCommunity, user, null, emailConfirmed])
      ),
      filter(([isCommunity, user, pro, emailConfirmed]: [CommunityState, User, Professional, boolean]) => {
        const stop = !this.checkInfosRequired(user, pro, isCommunity.isCommunity, emailConfirmed);
        return stop;
      }),
      switchMap(() => this.routesService.callBackUrl.pipe(filterUndefined(), take(1))),
      tap((url) => {
        if (isPlatformBrowser(this.platformId)) {
          // set callBackUrlRedirected: true to prevent authPopinService redirectOnLogin
          this.route.snapshot.data = {...this.route.snapshot.data, callBackUrlRedirected : true};
          // redirect to callBackUrl
          (url.indexOf(environment.REDIRECT_URL) !== -1 || url.indexOf('/')) !== 0 ? window.location.replace(url) :
            this.router.navigateByUrl(url, {});
          this.routesService.setCallBackUrl(undefined);
        }
      })
    ).subscribe();
  }

  get step1HasBeenProposed(): boolean {
    return this.step1HasBeenProposed$.getValue();
  }
  set step1HasBeenProposed(value: boolean) {
    if (!!value) {
      this.cookieService.put(this.getHasBeenProposedCookieName(this.userStoreService.user["@id"]), `true`, {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24), path: '/'});
    }
    this.step1HasBeenProposed$.next(value);
  }

  get step2HasBeenProposed(): boolean {
    return this.step2HasBeenProposed$.getValue();
  }

  set step2HasBeenProposed(value: boolean) {
    if (!!value) {
      this.cookieService.put(this.getHasBeenProposedCookieName(this.userStoreService.user["@id"], 2), `true`, {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24), path: '/'});
    }
    this.step2HasBeenProposed$.next(value);
  }

  getHasBeenProposedCookieName(userId: string, step: number = 1): string {
    return `stepHasBeenProposed${userId.replace('/users/', '')}-step${step}`;
  }

  redirectToLoginStep(params?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'login']
      }
    }], params).catch(() => {});
  }

  redirectToSignupStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'signup']
      }
    }]).catch(() => {});
  }

  redirectToVerifyCodeStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'verify-code']
      }
    }]).catch(() => {});
  }

  redirectToEmailCheckStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'email-check']
      }
    }]).catch(() => {});
  }

  redirectToProfileStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'profil']
      }
    }]).catch(() => {});
  }

  redirectToKazaStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'kaza']
      }
    }]).catch(() => {});
  }

  redirectToConstructionDateStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'construction-date']
      }
    }]).catch((e) => {console.log(e)});
  }

  redirectToTags(step: number): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'tags', step.toString()]
      }
    }]).catch(() => {});
  }

  redirectToOptionRemind(): void {
    // this.router.navigate([{
    //   outlets: {
    //     auth: ['authentification', 'optin-remind']
    //   }
    // }]).catch(() => {});
  }

  profileIsUncompleted(user: User, pro: Professional): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > profileIsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user) {
      return (user.registerFrom !== UserRegisterFrom.Coaching && ((user.professionalNew === true && !user.professionalId)
        || !user.username || !user.email || !user.firstName || !user.lastName));
    }
    return false;
  }

  kazaIsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > kazaIsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew || user.accountType === 'professional' || user.accountType === 'school') {
      return false;
    }

    if (typeof user.activeKaza !== 'string') {
      return !user.activeKaza.country || (user.activeKaza.country.toLowerCase() === 'fr' && !user.activeKaza.postalCode)
        || user.activeKaza.types.length === 0;
    }

    return false;
  }

  constructionDateIsUncompleted(user: any): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > constructionDateIsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew || user.accountType === 'professional' || user.accountType === 'school') {
      return false;
    }
    if(this.isPrevious ){
      return true
    }
    if (typeof user.activeKaza !== 'string') {
      return  !user.activeKaza?.constructionDatesAlreadyAnswered;
    }

    if (typeof user.activeKaza !== 'string') {
      const hasTypeDiffNoWork =  user.activeKaza.types.some((type) =>
        typeof type !== 'string' && (type.type !== 'no-work'));

      return !user.activeKaza.constructionStartedAt && !user.activeKaza.constructionDatesAlreadyAnswered && hasTypeDiffNoWork;
    }

    return false;
  }

  kazaTagStep1IsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > kazaTagStep1IsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew) {
      return false;
    }
    if(this.isPreviousStepTwo){
      return true
    }
    if (typeof user.activeKaza !== 'string') {
      const hasTypeRenovationOrExtension =  user.activeKaza.types.some((type) =>
        typeof type !== 'string' && (type.type === 'renovation' || type.type === 'extension' || type.type === 'energetic-renovation'))        
      if (hasTypeRenovationOrExtension) {
        const hasStep1Tags =  user.activeKaza.tags.some((tag) =>
          typeof tag !== 'string' && (tag.registrationStep === 1));
        return  !hasStep1Tags;
      }
    }
   
    return false;
  }

  kazaTagStep2IsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > kazaTagStep2IsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew) {
      return false;
    }

    if (typeof user.activeKaza !== 'string') {
      const hasTypeRenovationOrExtension =  user.activeKaza.types.some((type) =>
        typeof type !== 'string' && (type.type === 'arrangement' || type.type === 'garden'
          || type.type === 'renovation' || type.type === 'extension'));

      if (hasTypeRenovationOrExtension) {
        const hasStep2Tags =  user.activeKaza.tags.some((tag) =>
          typeof tag !== 'string' && (tag.registrationStep === 2));

        return  !hasStep2Tags;
      }
    }
    return false;
  }

  get activatedRoute(): ActivatedRouteSnapshot {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }

  checkInfosRequired(user: User, pro: Professional, isCommunity: boolean, emailConfirmed: boolean): boolean {
    if (!emailConfirmed) {
      if (!this.routesService.routeHasOutletValue('auth', 'email-check')) {
        // logger.debug({message: 'profileIsUncompleted. redirecting...'});
        this.redirectToEmailCheckStep();
      }
      }
     else if (this.profileIsUncompleted(user, pro)) {
      if (!this.routesService.routeHasOutletValue('auth', 'profil')) {
        // logger.debug({message: 'profileIsUncompleted. redirecting...'});
        this.redirectToProfileStep();
      }
    } else if (this.kazaIsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'kaza')) {
        // logger.debug({message: 'kazaIsUncompleted. redirecting...'});
        this.redirectToKazaStep();
      }
    } else if (this.constructionDateIsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'construction-date')) {
        // logger.debug({message: 'kazaIsUncompleted. redirecting...'});
        this.redirectToConstructionDateStep();
      }
      
    } else if (this.kazaTagStep1IsUncompleted(user) && this.step1HasBeenProposed === false) {
      if (!this.routesService.routeHasOutletValue('auth', 'tags')) {
        // logger.debug({message: 'kazaIsStep1Uncompleted. redirecting...'});
        this.redirectToTags(1);
      }
    } else if (this.kazaTagStep2IsUncompleted(user) && this.step2HasBeenProposed === false) {
      if (!this.routesService.routeHasOutletValue('auth', 'tags')) {
        // logger.debug({message: 'kazaIsStep2Uncompleted. redirecting...'});
        this.redirectToTags(2);
      }
    } /*else if (this.optinRemindIsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'optin-remind')) {
        this.redirectToOptionRemind();
      } 
    } */else {
      if (this.routesService.routeHasOutlet('auth') &&
        !this.routesService.routeHasOutletValue('auth', 'forgot-password')) {
        return this.authPopinService.closePopinAndRedirect(user, isCommunity);
      }
      return false;
    }
    return true;
  }

  optinRemindIsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > optinRemindIsUncompleted > ', 'color:orange', {optInPartnerEmail: user.optInPartnerEmail, smartPriceModalViewed: user.smartPriceModalViewed});
    }

    return user.optInPartnerEmail === false && user.smartPriceModalViewed === false;
  }

  verifEmail(): Observable<any> {
    // return of(null);
    const modal = this.modalService.open<EmailCheckComponent>(
      EmailCheckComponent,
      {
        size: 'md',
        closeButton: false,
      },
    );
    return modal.afterClosed().pipe();
  }
  private getPreviousAuthTagsStatus(): void {
    this.userStoreService.previousAuthTagsEnabled$.subscribe({
      next: data => {
        this.isPrevious = data;
      }
    });
  }
  private getPreviousAuthTagsStepTwoStatus(): void {
    this.userStoreService.previousAuthTagsStepTwoEnabled$.subscribe({
      next: data => {
        this.isPreviousStepTwo = data;
      }
    });
  }
}


export const distinctObject = (): any =>
  (source: Observable<User>) =>
    source.pipe(
      distinctUntilChanged((prev: User, curr: User) => {
        if ((!prev && !!curr) || (!!prev && !curr)) {
          return false;
        } else if (!!prev && !!curr && (prev !== curr) && typeof prev.activeKaza !== 'string' && typeof curr.activeKaza !== 'string') {
          const hasChanged =
            prev.registerFrom !== curr.registerFrom
            || prev.professionalNew !== curr.professionalNew
            || prev.professionalId !== curr.professionalId
            || prev.username !== curr.username
            || prev.email !== curr.email
            || prev.firstName !== curr.firstName
            || prev.updatedAt !== curr.updatedAt
            || prev.activeKaza.updatedAt !== curr.activeKaza.updatedAt;
          return !hasChanged;
        } else {
          return true;
        }
      }),
    );
