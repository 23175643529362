import { AuthenticationService, Kaza, KazaService, User, UserService as SDKUserService, } from '@adeo/ngx-kozikaza-api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../oauth/auth.service';
import { ToastrService } from '../../utilities/toastr.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { UserStoreService } from '../../state-management/user-store.service';
import { Friend, FriendService, FriendState } from '@adeo/ngx-kozikaza-api/friend';

@Injectable()
export class UserService {
  constructor(
    private authService: AuthService,
    private kazaService: KazaService,
    private toastr: ToastrService,
    private friendService: FriendService,
    private readonly sdkAuthenticationService: AuthenticationService,
    private readonly sdkUserService: SDKUserService,
    private readonly userStoreService: UserStoreService
  ) {
  }

  /* *****************************************************************************
   *        ACTIONS THAT UPDATES USER STORE
   ******************************************************************************/
  public update(resourceId: string | User, user: Partial<User>, updateStore = true): Observable<User> {
    const userToUpdate: Partial<User> = { '@id' : typeof resourceId === 'string' ? resourceId : resourceId['@id'], ...user};
    return this.sdkUserService.putUser(userToUpdate).pipe(
      tap((updatedUser) => (this.userStoreService.isUserLoggedIn(updatedUser) && updateStore) ?
          this.userStoreService.user = {...this.userStoreService.user, ...userToUpdate} : null),
      switchMap((user) => this.userStoreService.checkUserEmailValidated(user)),
    );
  }

  public setActiveKaza(kaza: Kaza | string) {
    return this.userStoreService.userStore.pipe(
      take(1),
      switchMap((currentUser) => {
        const userUpdated: Partial<User> = { '@id' : currentUser['@id'], activeKaza: (typeof kaza === 'string') ? kaza : kaza['@id']};
        return this.sdkUserService.putUser(userUpdated);
      }),
      switchMap(() => this.kazaService.getKaza(kaza)),
      tap(kazaUpdated => {
        if (!this.userStoreService.userActiveKaza || this.userStoreService.userActiveKaza['@id'] !== kaza['@id']) {
          this.userStoreService.userActiveKaza = kazaUpdated;
        }
      })
    );
  }

  public acceptKazami(friendId: string): Observable<any> {
    return this.friendService.acceptFriend(friendId).pipe(
      tap((data: Friend) => {
        if (data.state === FriendState.Accepted) {
          this.userStoreService.userNbKazamis = this.userStoreService.userNbKazamis - 1;
        }
      })
    );
  }

}
