import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CommunityService, CommunityStateOrigin } from '../services/community.service';
import { KazaService as LegacyKazaService } from '../services/features/kaza/kaza.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { debug } from '../../utils/misc/app-rxjs.helpers';
import { UserStoreService } from '../services/state-management/user-store.service';

@Injectable()
export class IsCommunityGuard  {
  constructor(
    private readonly communityService: CommunityService,
    private readonly router: Router,
    private readonly legacyKazaService: LegacyKazaService,
    private readonly localizeRouterService: LocalizeRouterService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // if communityRedirectPath route data is set (eg :['3d-home-design-software', 'electrical-plan'])
    // then redirectionPath = this translated route
    // else redirectionPath = translated home route
    const redirectPath: Array<string> =
      (route.data.communityRedirectPath && route.data.communityRedirectPath.length) ?
        [...route.data.communityRedirectPath] : ['/'];
    return this.communityService.isCommunityChanges
      .pipe(
        map(isCommunity => // if NOT Community && IS Connected : FALSE else TRUE
          (!isCommunity.isCommunity && (isCommunity.communityStateOrigin === CommunityStateOrigin.BY_KAZA_COUNTRY
            || isCommunity.communityStateOrigin === CommunityStateOrigin.BY_SCHOOL)) ?
            this.localizeRouterService.translateRoute(redirectPath) as string[] : null
        ),
        debug<Array<string>>(`IsCommunityGuard`, `Redirecting from /${route.url} to` ),
        map((val) => {
          if (val) {
            this.router.navigate(val);
            return false;
          }
          return true;
        })
      )
      ;
  }
}

@Injectable()
export class IsCommunityKazaGuard  {
  constructor(
    private readonly communityService: CommunityService,
    private readonly router: Router,
    private readonly legacyKazaService: LegacyKazaService,
    private readonly userStoreService: UserStoreService,
    private readonly localizeRouterService: LocalizeRouterService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const redirectPath: Array<string> =
      (route.data.communityKazaRedirectPath && route.data.communityKazaRedirectPath.length
        && route.parent && route.parent.params.kazaId) ?
        ['kaza', route.parent.params.kazaId, ...route.data.communityKazaRedirectPath] : ['/'];
    return this.communityService.isCommunityChanges
      .pipe(
        map((isCommunity) => this.userStoreService.isPro ?
          {isCommunity: true, communityStateOrigin: CommunityStateOrigin.BY_PRO} : isCommunity),
        map(isCommunity => // if NOT Community && IS Connected : FALSE else TRUE
          (!isCommunity.isCommunity && isCommunity.communityStateOrigin === CommunityStateOrigin.BY_KAZA_COUNTRY) ?
            this.localizeRouterService.translateRoute(redirectPath) as string[] : null
        ),
        debug<Array<string>>(`IsCommunityKazaGuard`, `Redirecting from /${route.url} to` ),
        map((val) => {
          if (val) {
            this.router.navigate(val);
            return false;
          }
          return true;
        })
      )
      ;
  }
}
