import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[kzClickOutside]'
})
export class KzClickOutsideDirective {

  @Output()
  public kzClickOutside = new EventEmitter<MouseEvent>();

  @Input()
  public exceptions: Array<ElementRef> = [];

  get elementRef(): ElementRef {
    return this._elementRef;
  }

  constructor(private _elementRef: ElementRef) {
  }

  /*
   * Directive : emit event if mouse is clicked outside native element
   */

  @HostListener('document:touchstart', ['$event', '$event.target'])
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (
        !targetElement
        || targetElement.classList.contains('click-outside-exception')
        || (this.exceptions.some((exception) =>
          (exception !== null
              && exception.nativeElement !== null
              && (
                  targetElement === (exception.nativeElement as HTMLElement)
                  || (exception.nativeElement.contains(targetElement) as boolean)
              )
          )
        )
        )
    ) {
      return;
    }
    const clickedInside = !!(this.elementRef.nativeElement.contains(targetElement) as boolean);
    if (!clickedInside) {
      this.kzClickOutside.emit(event);
    }
  }
}
