import { KzIcon } from '../generated-icons/kz-icon.model';
import { kzIconsIdea } from '../generated-icons/kzIcons-idea.icon';
import { kzIconsPlans } from '../generated-icons/kzIcons-plans.icon';
import { kzIconsCommunity } from '../generated-icons/kzIcons-community.icon';
import { kzIconsCoaching } from '../generated-icons/kzIcons-coaching.icon';

export const navigationIcons: KzIcon[] = [
  kzIconsCommunity,
  kzIconsCoaching,
  kzIconsIdea,
  kzIconsPlans,
];
