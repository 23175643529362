// Use like this : @AutoUnsubscribe(["subscriptionInstanceOne", "subscriptionInstanceTwo"])

export function AutoUnsubscribe(blackList = [] ) {

  return ( constructor ) => {
    const original = constructor.prototype.ngOnDestroy;
    function updatedNgOnDestroy() {
      for (const prop of Object.keys(this)) {
        const property = this[ prop ];
        if ( !blackList.includes(prop) ) {
          if ( property && ( typeof property.unsubscribe === 'function' && !property.closed) ) {
            property.unsubscribe();
          }
        }
      }
      if (original && typeof original === 'function') {
        original.apply(this, arguments);
      }
    }
    constructor.prototype.ngOnDestroy = updatedNgOnDestroy;
  };
}
