import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subscription } from 'rxjs';

const VIEW_DESTROYED_STATE = 128;

/* Custom localize pipe without detectChanges */
@Pipe({
  name: 'kzLocalize',
  standalone: true,
})
export class KzLocalizePipe implements PipeTransform {
  private value: string | any[] = '';
  private lastKey: string | any[];
  private lastLanguage: string;
  private subscription: Subscription;

  /**
   * CTOR
   */
  constructor(private localize: LocalizeRouterService, private _ref: ChangeDetectorRef) {
    this.subscription = this.localize.routerEvents.subscribe(() => {
      this.transform(this.lastKey);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Transform current url to localized one
   */
  transform(query: string | any[]): string | any[] {
    if (!query || query.length === 0 || !this.localize.parser.currentLang) {
      return query;
    }
    if (equals(query, this.lastKey) && equals(this.lastLanguage, this.localize.parser.currentLang)) {
      return this.value;
    }
    this.lastKey = query;
    this.lastLanguage = this.localize.parser.currentLang;

    /** translate key and update values */
    this.value = this.localize.translateRoute(query);
    this.lastKey = query;
    // if view is already destroyed, ignore firing change detection
    const view = (this._ref as any)._view;
    if (view && (view.state & VIEW_DESTROYED_STATE)) {
      return this.value;
    }
    // setTimeout(() => {
    //   this._ref.detectChanges();
    // }, 0)

    return this.value;
  }

}

export const equals = (o1: any, o2: any): boolean => {
  if (o1 === o2) {
    return true;
  }
  if (o1 === null || o2 === null) {
    return false;
  }
  if (o1 !== o1 && o2 !== o2) {
    return true; // NaN === NaN
  }
  const t1 = typeof o1,
    t2 = typeof o2;
  let length: number,
    key: any,
    keySet: any;

  if (t1 === t2 && t1 === 'object') {
    if (Array.isArray(o1)) {
      if (!Array.isArray(o2)) {
        return false;
      }
      if ((length = o1.length) === o2.length) {
        for (key = 0; key < length; key++) {
          if (!equals(o1[key], o2[key])) {
            return false;
          }
        }
        return true;
      }
    } else {
      if (Array.isArray(o2)) {
        return false;
      }
      keySet = Object.create(null);
      for (key in o1) {
        if (o1.hasOwnProperty(key)) {
          if (!equals(o1[key], o2[key])) {
            return false;
          }
          keySet[key] = true;
        }
      }
      for (key in o2) {
        if (o2.hasOwnProperty(key)) {
          if (!(key in keySet) && typeof o2[key] !== 'undefined') {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}
