import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[kzSelect]',
  exportAs: 'kzSelect',
  host: {
    '[class]': '"kz-select" + (classes ? " " + classes : "")',
  },
  template: `<ng-content></ng-content>`,
  styleUrls: ['./kz-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzSelectComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';
  }
}
